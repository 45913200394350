import { connect } from 'react-redux';
import {
  getUsagePlanApiKeysFromStore,
  getUsagePlanApiKeysStatusesFromStore,
} from '../../shared/reducers';

import { getUsagePlanApiKeysRequest } from '../apis/internalAuthApi/actions';
import UsagePlanApiKeysList from '../components/UsagePlanApiKeysList';

const mapStateToProps = (state) => ({
  apiKeys: getUsagePlanApiKeysFromStore(state),
  apiKeysStatuses: getUsagePlanApiKeysStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsagePlanApiKeys: (usagePlanId) => dispatch(
    getUsagePlanApiKeysRequest(usagePlanId),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsagePlanApiKeysList);
