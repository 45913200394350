/**
 * Specific values for the CUSTOMER variant of the app.
 */
const variant = {
  'cluvio-dashboards': {
    'customer-insights/account-summary': {
      dashboard_id: 'xd1p-g084-myro',
      sharing_token: '2f61d5d3-c20d-4b05-bfcf-625c33cd47e0',
    },
    'customer-insights/campaign-summary': {
      dashboard_id: 'v5dy-lkzm-k6z3',
      sharing_token: '9fefba3b-1d32-4afd-b0c1-e6ac29d146fa',
    },
    'customer-insights/campaign-summary/campaign-details': {
      dashboard_id: 'wx7y-5mz7-g6v5',
      sharing_token: 'edc8c6ac-2aca-44e1-b7e2-04f6d4d56828',
    },
    'customer-insights/job-summary': {
      dashboard_id: 'w13y-jo2n-v67r',
      sharing_token: '2cbf806e-32e5-467d-adbb-f7e27f3075b5',
    },
    'customer-insights/job-summary/job-details': {
      dashboard_id: '7wo6-v0ez-xpx4',
      sharing_token: 'c41670b5-febd-4a38-95de-64ebf9cfb2d5',
    },
    'customer-insights/event-summary': {
      dashboard_id: '78dy-018m-epke',
      sharing_token: 'b70fcc86-42cc-498b-ab3c-aaeab712b8b1',
    },
    'customer-insights/event-summary/event-details': {
      dashboard_id: '5j3y-dlvq-k6o9',
      sharing_token: '57ca65e7-8f3a-4ebb-ba04-d8ce5625efe8',
    },
    'customer-insights/applicant-summary': {
      dashboard_id: 'ke7y-8zkv-oy0d',
      sharing_token: '51713605-1c11-416a-98f3-1046ae8b71e8',
    },
    'customer-insights/ad-unit-summary': {
      dashboard_id: 'kr8p-1wr3-w6z2',
      sharing_token: 'f072ee8e-f06c-4cfe-a1e9-f9473b8e56ac',
    },
    'talent-supply-insights/market-breakdown/audience-analysis': {
      dashboard_id: 'njry-2lrv-5pgd',
      sharing_token: '7bdda44e-af4a-4455-8a80-397d8750088e',
    },
    'talent-supply-insights/market-breakdown/market-expansion': {
      dashboard_id: 'vrep-xk51-wy1z',
      sharing_token: 'd2c82e8b-867e-4d9f-afdc-060bc930d363',
    },
    'talent-supply-insights/market-breakdown/demographic-overview': {
      dashboard_id: '9x36-z03w-ky7r',
      sharing_token: '4c69d1c0-5d98-4182-bc18-d83e3918498f',
    },
    'talent-supply-insights/market-breakdown/supply-trends': {
      dashboard_id: '805p-qke8-1pg7',
      sharing_token: '9aada39e-9833-48e4-9744-65c9ffc2283f',
    },
    'talent-supply-insights/market-breakdown/data-explorer': {
      dashboard_id: 'oj2y-7oqr-z6dr',
      sharing_token: 'b659c8cc-7e2e-4ad2-8665-18ba95129a41',
    },
    'talent-supply-insights/industry-breakdown/audience-analysis': {
      dashboard_id: 'qxny-90j7-9y5v',
      sharing_token: 'cf250433-3e55-4cc1-b519-42cb55c2f1f1',
    },
    'talent-supply-insights/industry-breakdown/market-expansion': {
      dashboard_id: '9z4p-k81l-0pel',
      sharing_token: 'b5b4b3d6-e1d2-4ab2-90f4-7bfeac7992d1',
    },
    'talent-supply-insights/industry-breakdown/supply-trends': {
      dashboard_id: 'qk7y-r9ek-8y0v',
      sharing_token: '2de036d0-204a-4b1b-88b9-69ce9a355705',
    },
    'talent-supply-insights/industry-breakdown/data-explorer': {
      dashboard_id: 'ke7y-8gv2-v60d',
      sharing_token: 'aaf0d53a-22ea-4172-a15b-7a664668f6bd',
    },
    'competitive-insights/bidding-intelligence': {
      dashboard_id: 'xdwy-w8e4-xy70',
      sharing_token: '1218c8cc-505b-4305-b54f-5042cf9333b2',
    },
    'competitive-insights/competitive-landscape': {
      dashboard_id: 'm73p-e1jl-dy0q',
      sharing_token: '5827f849-7fa3-4870-a478-151fc47144c2',
    },
  },
};

export default variant;
