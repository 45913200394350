import { List, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as PropTypes from 'prop-types';
import React from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressBar from '../../Common/components/ProgressBar';
import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import withView from '../../Common/hocs/withView';
import AddApiCallsController from '../controllers/AddApiCallsController';

class ApiKeyUsagePlan extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    const year = today.getFullYear();
    const rawMonth = (today.getMonth() + 1).toString();
    const month = rawMonth.padStart(2, '0');
    const rawDay = today.getDate().toString();
    const day = rawDay.padStart(2, '0');
    const start = `${year}-${month}-01`;
    const end = `${year}-${month}-${day}`;

    this.state = {
      windowStart: start,
      windowEnd: end,
      message: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.message !== state.message) {
      props.setSuccessMessage(props.message);
    }
    if (props.message !== undefined) {
      if (props.message.includes('Successfully Updated Request Limit')) {
        props.getApiKeyUsagePlan(props.match.params.planid,
          props.match.params.apikeyid, null, null, state.windowStart,
          state.windowEnd);
        props.clearMessage();
      }
    }
  }

  componentDidMount() {
    const { match, getApiKeyUsagePlan, getPlan } = this.props;
    const { windowStart, windowEnd } = this.state;
    getApiKeyUsagePlan(match.params.planid, match.params.apikeyid, null, null,
      windowStart, windowEnd);
    getPlan(match.params.planid, null, null, match.params.apikeyid);
  }

  render() {
    const today = new Date();
    const monthName = today.toLocaleString('default', { month: 'long' });
    const {
      match,
      usageData,
      getApiKeyUsagePlan,
      monthlyUsageData,
    } = this.props;
    const { windowStart, windowEnd } = this.state;
    const usage = monthlyUsageData === undefined
    || monthlyUsageData.length === 0
      ? 0
      : monthlyUsageData.reduce(
        (prev, next) => prev + next.number_of_requests, 0,
      );
    const limit = monthlyUsageData === undefined || monthlyUsageData.length
    === 0 ? usageData.limit.limit : monthlyUsageData.slice(-1)[0].limit + usage;
    const limitString = `${limit} per ${usageData.limit.period}.`;
    const throttleRateLimit = usageData.throttle.rateLimit === undefined
      ? null
      : `${usageData.throttle.rateLimit} requests per second.`;
    const throttleBurstLimit = usageData.throttle.burstLimit === undefined
      ? null
      : `${usageData.throttle.burstLimit} requests.`;
    return (
      <GeneralPageHolder>
        <Breadcrumbs path={window.location.pathname} />
        <br />
        <h1>{monthName} Usage for {match.params.apikeyid}</h1>
        <List>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    API Key
                  </Typography>
                </>
              }
              secondary={match.params.apikeyid}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Usage Plan ID
                  </Typography>
                </>
              }
              secondary={match.params.planid}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    API Limit
                  </Typography>
                </>
              }
              secondary={limit === undefined ? null : limitString}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Rate Limit
                  </Typography>
                </>
              }
              secondary={throttleRateLimit}
            />
          </ListItem>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary={
                <>
                  <Typography
                    component="span"
                    variant="body2"
                    color="textPrimary"
                  >
                    Burst Limit
                  </Typography>
                </>
              }
              secondary={throttleBurstLimit}
            />
          </ListItem>
        </List>
        <div style={{ textAlign: 'center' }}>
          <h1>Progress</h1>
          <ProgressBar limit={limit} used={usage} month={monthName} />
          <SimpleDialogController
            buttonTitle="Update Limit"
            dialogHeader="Input New Limit"
            closeAction={() => getApiKeyUsagePlan(match.params.planid,
              match.params.apikeyid, null, null, windowStart, windowEnd)}
            component={<AddApiCallsController
              planId={match.params.planid}
              apiKeyId={match.params.apikeyid}
              usage={usage}
              limit={limit}
            />}
            id="updateLimit"
          />
        </div>
      </GeneralPageHolder>
    );
  }
}

ApiKeyUsagePlan.propTypes = {
  match: PropTypes.object.isRequired,
  getApiKeyUsagePlan: PropTypes.func.isRequired,
  getPlan: PropTypes.func.isRequired,
  usageData: PropTypes.object.isRequired,
  monthlyUsageData: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default withView(null)(ApiKeyUsagePlan);
