import { connect } from 'react-redux';

import { getUserFromStore } from '../../shared/reducers';

import {
  setDialogCloseRequest,
  updateUserRequest,
} from '../apis/internalAuthApi/actions';
import UpdateUser from '../components/UpdateUser';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateUser: (username, name, email, customerId) => dispatch(
    updateUserRequest(username, name, email, customerId),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateUser);
