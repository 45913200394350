import * as commonRoutes from '../Common/routes';
import * as insightsRoutes from '../Insights/routes';
import * as userManagementRoutes from '../UserManagement/routes';
import * as popManagementRoutes from '../PopManagement/routes';

const getAllRoutes = () => [].concat(
  commonRoutes.default,
  insightsRoutes.default,
  userManagementRoutes.default,
  popManagementRoutes.default,
);

export default getAllRoutes;
