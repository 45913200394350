import LinearProgress from '@material-ui/core/LinearProgress';
import * as PropTypes from 'prop-types';
import React from 'react';

const ProgressBar = ({ limit, used, month }) => {
  const progress = (limit ? ((used / limit) * 100) : 0).toFixed(2);
  const progressString = `${used} out of ${limit} requests used for the month of ${month} (${progress}%).`;
  return (
    <div>
      {limit !== undefined ?
        <div>
          <p style={{ textAlign: 'center' }}>
            {progressString}
          </p>
          <LinearProgress variant="buffer" value={progress} color="primary" />
        </div>
        :
        null}
    </div>
  );
};

ProgressBar.propTypes = {
  limit: PropTypes.number.isRequired,
  used: PropTypes.number.isRequired,
  month: PropTypes.string.isRequired,
};

export default (ProgressBar);
