import { connect } from 'react-redux';
import {
  getGroupsFromStore,
  getGroupsNextTokenFromStore,
  getGroupsStatusesFromStore,
} from '../../shared/reducers';

import { getGroupsRequest } from '../apis/internalAuthApi/actions';
import GroupsList from '../components/GroupsList';

const mapStateToProps = (state) => ({
  groups: getGroupsFromStore(state),
  nextToken: getGroupsNextTokenFromStore(state),
  GroupsStatuses: getGroupsStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: (limit, nextToken) => dispatch(getGroupsRequest(limit, nextToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupsList);
