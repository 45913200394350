import { Auth } from 'aws-amplify';
import React, { Component } from 'react';
import getAllAccessGroups from '../../../shared/allAccessGroups';

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

function withAuth(WrappedComponent) {
  class WithAuth extends Component {
    constructor(props) {
      super(props);

      // TODO: Look at putting this in the Redux Store.
      this.state = {
        accessGroups: [],
        currentSession: {},
      };
    }

    componentDidMount() {
      Auth.currentSession().then((data) => {
        const { idToken } = data;
        const { payload } = idToken;
        const allAccessGroups = getAllAccessGroups(payload);

        this.setState({
          accessGroups: allAccessGroups,
          currentSession: data,
        });
      }).catch((err) => console.log(err));
    }

    render() {
      const { accessGroups, currentSession } = this.state;

      return (
        <WrappedComponent
          {...this.props} // eslint-disable-line react/jsx-props-no-spreading
          access={accessGroups}
          currentSession={currentSession}
        />
      );
    }
  }

  WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;

  return WithAuth;
}

export default withAuth;
