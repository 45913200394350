/**
 *  Generated from:
 * https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=611cbe
 */
export default {
  light: '#964ef2',
  main: '#611cbe',
  dark: '#25008c',
  contrastText: '#fff',
};
