import environment from '../configs/environment';

export const POP_MANAGEMENT_ADMIN_ACCESS_GROUP = 'popManagement_admin';

const getAccessGroups = (userData) => {
  const { env } = environment;
  const accessGroups = [];

  if ('cognito:groups' in userData) {
    const groups = userData['cognito:groups'];

    if (groups.includes(`pop-${env}-ad-api-full-access`)) {
      accessGroups.push(POP_MANAGEMENT_ADMIN_ACCESS_GROUP);
    }
  }

  return accessGroups;
};

export default getAccessGroups;
