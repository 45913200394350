import { connect } from 'react-redux';
import {
  getUsersFromStore,
  getUsersStatusesFromStore,
} from '../../shared/reducers';

import {
  getGroupUsersRequest,
  removeUserFromGroupRequest,
} from '../apis/internalAuthApi/actions';
import GroupUsersList from '../components/GroupUsersList';

const mapStateToProps = (state) => ({
  users: getUsersFromStore(state),
  usersStatuses: getUsersStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGroupUsers: (groupName) => dispatch(getGroupUsersRequest(groupName)),
  removeUserFromGroup: (username, groupName) => dispatch(
    removeUserFromGroupRequest(username, groupName),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GroupUsersList);
