import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Form from '@rjsf/material-ui';
import Typography from '@material-ui/core/Typography';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';

const useStyles = makeStyles((theme) => ({
  buttonHolder: {
    textAlign: 'right',
  },
  headerName: {
    color: theme.palette.text.primary,
  },
  headerDivider: {
    width: '100%',
  },
}));

const AdUnit = (props) => {
  const classes = useStyles();
  const {
    ad,
    adSnippet,
    adSpec,
    getAdUnit,
    getAdSnippet,
    getAdUnitSpec,
    match,
    message,
    publishAdUnit,
    setSuccessMessage,
    clearMessage,
  } = props;

  const { params } = match;
  const { adId, customerId } = params;

  useEffect(() => {
    getAdUnit(adId, customerId);
    getAdSnippet(adId, btoa(`customer_id:${customerId}`));
  }, [adId, customerId]);

  useEffect(() => {
    if (ad.ad_type) {
      getAdUnitSpec(ad.ad_type);
    }
  }, [ad]);

  useEffect(() => {
    if (message !== undefined) {
      setSuccessMessage(message);
    }
  }, [message]);

  const goToUpdate = () => {
    window.location.pathname = `/pop-management/ad/${customerId}/${adId}/update`;
    clearMessage();
  };

  const publishAd = () => {
    publishAdUnit(adId, ad.general.customer_id);
  };

  return (
    Object.keys(ad).length === 0 || Object.keys(adSpec).length === 0 ?
      <ProgressWheel />
      :
      <GeneralPageHolder>
        <Breadcrumbs path={window.location.pathname} />
        <br />
        <div className={classes.buttonHolder}>
          <Button variant="contained" color="primary" type="text" onClick={publishAd}>Publish</Button>
        </div>
        <Typography
          variant="h5"
          gutterBottom
          className={classes.headerName}
        >
          Ad ID: {adId}
        </Typography>
        <Divider className={classes.headerDivider} />
        <Form schema={adSpec} formData={ad} readonly onSubmit={goToUpdate}>
          <Button variant="contained" color="primary" type="submit">Update</Button>
        </Form>
        <br />
        <Divider className={classes.headerDivider} />
        <br />
        <Typography
          variant="h5"
          gutterBottom
          className={classes.headerName}
        >
          Ad Snippet
        </Typography>
        <p>Make sure to publish your ad before using the following snippet.</p>
        <Divider className={classes.headerDivider} />
        <p>{adSnippet}</p>
      </GeneralPageHolder>
  );
};

AdUnit.propTypes = {
  match: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  getAdUnitSpec: PropTypes.func.isRequired,
  adSnippet: PropTypes.string.isRequired,
  getAdSnippet: PropTypes.func.isRequired,
  adSpec: PropTypes.object.isRequired,
  getAdUnit: PropTypes.func.isRequired,
  publishAdUnit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default withView(null)(AdUnit);
