import { connect } from 'react-redux';
import {
  getApiKeysFromStore,
  getApiKeysNextTokenFromStore,
  getApiKeysPositionFromStore,
} from '../../shared/reducers';

import { getApiKeysRequest } from '../apis/internalAuthApi/actions';

import ApiKeysList from '../components/ApiKeysList';

const mapStateToProps = (state) => ({
  // TODO: Figure out why there are two tokens. Unify naming.
  nextToken: getApiKeysNextTokenFromStore(state),
  apiKeys: getApiKeysFromStore(state),
  apiKeysPosition: getApiKeysPositionFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeys: (apiKeyId, limit, position, nameQuery, includeValue) => dispatch(
    getApiKeysRequest(apiKeyId, limit, position, nameQuery, includeValue),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiKeysList);
