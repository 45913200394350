import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import AppWithAuth from './AppWithAuth';
import './index.css';
import configureStore from './shared/configureStore';
import rootReducers from './shared/reducers';
import rootSagas from './shared/sagas';

const store = configureStore(rootReducers, rootSagas);

ReactDOM.render(
  <Provider store={store}>
    <AppWithAuth />
  </Provider>,
  document.getElementById('root'),
);
