import { connect } from 'react-redux';

import { getUserFromStore } from '../../shared/reducers';

import {
  adminSetUsersPasswordRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import AdminSetPassword from '../components/AdminSetPassword';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  adminSetUsersPassword: (username, password) => dispatch(
    adminSetUsersPasswordRequest(username, password),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSetPassword);
