import { connect } from 'react-redux';
import * as actions from '../../apis/views/actions';

import MessageSnackbar from '../../components/MessageSnackbar';

const mapStateToProps = (state) => ({
  message: state.view.successMessage,
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => {
    dispatch(actions.clearSuccessMessage());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageSnackbar);
