import ViewCompactOutlinedIcon from '@material-ui/icons/ViewCompactOutlined';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import SearchOutlinedIcon from '@material-ui/icons/SearchOutlined';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import PeopleIcon from '@material-ui/icons/People';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import StoreMallDirectoryOutlinedIcon from '@material-ui/icons/StoreMallDirectoryOutlined';
import React from 'react';

import NavOptionsPage from '../Common/components/NavOptionsPage';
import { POP_MANAGEMENT_ADMIN_ACCESS_GROUP } from './accessGroups';
import AdsListController from './controllers/AdsListController';
import CreateAdController from './controllers/CreateAdController';
import AdUnitController from './controllers/AdUnitController';
import UpdateAdController from './controllers/UpdateAdController';

const routes = [
  {
    pageTitle: 'POP Management',
    path: '/pop-management',
    icon: null,
    exact: true,
    main: NavOptionsPage,
    extras: {
      access: [
        POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
      ],
    },
    routes: [
      {
        pageTitle: 'Ads',
        path: '/pop-management/ad',
        icon: <ViewCompactOutlinedIcon />,
        exact: true,
        main: AdsListController,
        extras: {
          access: [
            POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'Ad',
        path: '/pop-management/ad/:customerId/:adId',
        icon: <PeopleIcon />,
        exact: true,
        main: AdUnitController,
        extras: {
          access: [
            POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'Update ad',
        path: '/pop-management/ad/:customerId/:adId/update',
        icon: <ViewCompactOutlinedIcon />,
        exact: true,
        main: UpdateAdController,
        extras: {
          access: [
            POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        pageTitle: 'Create Ad',
        path: '/pop-management/create',
        icon: <AddBoxOutlinedIcon />,
        exact: true,
        main: NavOptionsPage,
        extras: {
          access: [
            POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
          data: [
            'Search Ad',
            'Keywords Ad',
            'Companies Ad',
            'Locations Ad',
            'Industries Ad',
            'Jobs Ad',
            'Positions Ad',
          ],
        },
        routes: [
          {
            pageTitle: 'Search Ad',
            path: '/pop-management/create/searchAdUnit',
            icon: <SearchOutlinedIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="searchAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Keywords Ad',
            path: '/pop-management/create/keywordsAdUnit',
            icon: <VpnKeyOutlinedIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="keywordsAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Companies Ad',
            path: '/pop-management/create/companiesAdUnit',
            icon: <BusinessOutlinedIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="companiesAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Locations Ad',
            path: '/pop-management/create/locationsAdUnit',
            icon: <LocationOnOutlinedIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="locationsAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Industries Ad',
            path: '/pop-management/create/industriesAdUnit',
            icon: <StoreMallDirectoryOutlinedIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="industriesAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Jobs Ad',
            path: '/pop-management/create/jobsAdUnit',
            icon: <WorkOutlineIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="jobsAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Positions Ad',
            path: '/pop-management/create/positionsAdUnit',
            icon: <PersonOutlineIcon />,
            exact: true,
            main: () => (
              <CreateAdController
                adType="positionsAdUnit"
              />
            ),
            extras: {
              access: [
                POP_MANAGEMENT_ADMIN_ACCESS_GROUP,
              ],
            },
          },
        ],

      },
    ],
  },
];

export default routes;
