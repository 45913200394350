/**
 *  Generated from:
 * https://material.io/resources/color/#!/?view.left=1&view.right=1&primary.color=f9586b
 */
export default {
  light: '#ff8b99',
  main: '#f9586b',
  dark: '#c11d40',
  contrastText: '#000',
};
