import { connect } from 'react-redux';

import {
  getUsagePlansFromStore,
  getUsagePlansNextTokenFromStore,
} from '../../shared/reducers';

import { getUsagePlansRequest } from '../apis/internalAuthApi/actions';

import UsagePlansList from '../components/UsagePlansList';

const mapStateToProps = (state) => ({
  usagePlans: getUsagePlansFromStore(state),
  nextToken: getUsagePlansNextTokenFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsagePlans: (planId, limit, position, apiKeyId) => dispatch(
    getUsagePlansRequest(planId, limit, position, apiKeyId),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsagePlansList);
