import { connect } from 'react-redux';

import { getDialogStatusFromStore } from '../../shared/reducers';

import {
  setDialogCloseRequest,
  setDialogOpenRequest,
} from '../../UserManagement/apis/internalAuthApi/actions';

import SimpleDialog from '../components/SimpleDialog';

const mapStateToProps = (state) => ({
  dialogStatus: getDialogStatusFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  setDialogOpen: (id) => dispatch(setDialogOpenRequest(id)),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SimpleDialog);
