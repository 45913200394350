import '@aws-amplify/ui/dist/style.css';
import Amplify from 'aws-amplify';
import React from 'react';

import App from './App';
import CustomAuthenticator from './auth/overrides/CustomAuthenticator';
import CustomForgotPassword from './auth/overrides/CustomForgotPassword';
import CustomRequireNewPassword
  from './auth/overrides/CustomRequireNewPassword';
import CustomSignIn from './auth/overrides/CustomSignIn';
import CustomSignUp from './auth/overrides/CustomSignUp';
import config from './configs/aws-exports';

Amplify.configure(config);

const AppWithAuth = () => (
  <CustomAuthenticator hideDefault>
    <CustomRequireNewPassword override="RequireNewPassword" />
    <CustomForgotPassword override="ForgotPassword" />
    <CustomSignIn override="SignIn" />
    <CustomSignUp override="SignUp" />
    <App />
  </CustomAuthenticator>
);

export default AppWithAuth;
