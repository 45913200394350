/**
 * This HOC gives us access to those action functions. We can use it as many
 * times as we want. The only restriction is we have to use it in the top-level
 * component of the app.
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  clearErrorMessage,
  clearSuccessMessage,
  setErrorMessage,
  setSuccessMessage,
} from '../../apis/views/actions';
import ErrorSnackbarContainer from './ErrorSnackbarContainer';

import SuccessSnackbarContainer from './SuccessSnackbarContainer';

const getDisplayName = (WrappedComponent) => (
  WrappedComponent.displayName || WrappedComponent.name || 'Component'
);

function withView(anchorOrigin) {
  return function withViewWrapper(WrappedComponent) {
    class WithView extends Component {
      // eslint-disable-next-line react/prop-types
      constructor({ props }) {
        super(props);
      }

      render() {
        return (
          <div>
            <WrappedComponent
              {...this.props} // eslint-disable-line
              // react/jsx-props-no-spreading
            />
            <SuccessSnackbarContainer anchorOrigin={anchorOrigin} />
            <ErrorSnackbarContainer anchorOrigin={anchorOrigin} />
          </div>
        );
      }
    }

    const mapStateToProps = () => ({
      //
    });

    const mapDispatchToProps = (dispatch) => ({
      setSuccessMessage: (message) => {
        dispatch(setSuccessMessage(message));
      },
      clearSuccessMessage: () => {
        dispatch(clearSuccessMessage());
      },
      setErrorMessage: (message) => {
        dispatch(setErrorMessage(message));
      },
      clearErrorMessage: () => {
        dispatch(clearErrorMessage());
      },
    });

    WithView.displayName = `WithView(${getDisplayName(WrappedComponent)})`;

    return connect(
      mapStateToProps,
      mapDispatchToProps,
    )(WithView);
  };
}

export default withView;
