import MaterialLink from '@material-ui/core/Link';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { formatDateTime } from '../../Common/utils';
import CommonTable from './common/CommonTable';

const UserApiKeysList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { apiKeys, nextToken, getUserApiKeys, customerId } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (apiKeys.length !== 0) {
      setRows(buildRows(apiKeys));
    } else {
      setRows(buildRows([]));
    }
  }, [apiKeys]);

  useEffect(() => {
    paginationCallback();
  }, [customerId]);

  // eslint-disable-next-line no-unused-vars
  const paginationCallback = (limit, page) => {
    getUserApiKeys(customerId);
  };

  const tableHeaders = [
    'ID',
    'Name',
    'Description',
    'Created',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        id,
        name,
        description,
        created_date: createdDate,
      } = rawData[key];
      newObject[id] = [
        <MaterialLink
          component={Link}
          to={`/user-management/api-keys/${id}`}
          color="primary"
        >
          {id}
        </MaterialLink>,
        name,
        description,
        formatDateTime(createdDate),
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

UserApiKeysList.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getUserApiKeys: PropTypes.func.isRequired,
  customerId: PropTypes.string.isRequired,
};

UserApiKeysList.defaultProps = {
  nextToken: '',
};

export default UserApiKeysList;
