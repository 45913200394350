import Cookies from 'js-cookie';

const paletteType = Cookies.get('paletteType');

const initialState = {
  paletteType: paletteType || 'light',
};

const settings = (state = initialState, action) => {
  if (action.type === 'UPDATE_PALETTE_TYPE') {
    Cookies.set('paletteType', action.paletteType);
    return {
      ...state,
      paletteType: action.paletteType,
    };
  }

  return state;
};

export default settings;

export const getPaletteType = (state) => (state.paletteType);
