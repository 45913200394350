import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const CreateApiKey = ({
  keyName,
  description,
  createApiKey,
  setDialogClose,
}) => {
  const handleCreate = () => {
    createApiKey(keyName, description, 'True', {});
    setDialogClose();
  };

  return <YesNoButtons yesAction={handleCreate} noAction={setDialogClose} />;
};

CreateApiKey.propTypes = {
  keyName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  createApiKey: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default CreateApiKey;
