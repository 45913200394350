import { connect } from 'react-redux';
import {
  getGroupsFromStore,
  getGroupsNextTokenFromStore,
  getMessageFromStore,
  getUserFromStore,
  getUserGroupsFromStore,
} from '../../shared/reducers';

import {
  addUserToGroupRequest,
  getGroupsRequest,
  getUsersGroupsRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import AddGroupToUser from '../components/AddGroupToUser';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
  groups: getGroupsFromStore(state),
  message: getMessageFromStore(state),
  userGroups: getUserGroupsFromStore(state),
  nextToken: getGroupsNextTokenFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGroups: (limit, nextToken) => dispatch(getGroupsRequest(limit, nextToken)),
  getUserGroups: (username) => dispatch(getUsersGroupsRequest(username)),
  addUserToGroup: (username, groupName) => dispatch(
    addUserToGroupRequest(username, groupName),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddGroupToUser);
