import MaterialLink from '@material-ui/core/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import RemovePlanFromKeyController
  from '../controllers/RemovePlanFromApiKeyController';
import CommonTable from './common/CommonTable';

const ApiKeyUsagePlansList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { usagePlans, nextToken, getApiKeyUsagePlans, apiKeyId } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (usagePlans.length !== 0) {
      setRows(buildRows(usagePlans));
    } else {
      setRows(buildRows([]));
    }
  }, [usagePlans]);

  // eslint-disable-next-line no-unused-vars
  const paginationCallback = (limit, page) => {
    getApiKeyUsagePlans(apiKeyId);
  };

  const tableHeaders = [
    'ID',
    'Name',
    'Description',
    'Default Request Limit',
    'Default Rate Limit',
    'Default Burst Limit',
    'Remove?',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        id,
        name,
        description,
        quota,
        throttle,
      } = rawData[key];
      newObject[id] = [
        <MaterialLink
          component={Link}
          to={`/user-management/api-keys/${apiKeyId}/usage-plans/${id}`}
          color="primary"
        >
          {id}
        </MaterialLink>,
        name,
        description,
        `${quota.limit} per ${quota.period}`,
        throttle.rateLimit,
        throttle.burstLimit,
        <SimpleDialogController
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle="Remove"
          dialogHeader="Are you sure?"
          component={
            <RemovePlanFromKeyController
              apiKeyId={apiKeyId}
              planId={id}
            />
          }
          id="removePlan"
        />,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

ApiKeyUsagePlansList.propTypes = {
  usagePlans: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getApiKeyUsagePlans: PropTypes.func.isRequired,
  apiKeyId: PropTypes.string.isRequired,
};

ApiKeyUsagePlansList.defaultProps = {
  nextToken: '',
};

export default ApiKeyUsagePlansList;
