import * as actionTypes from './actionTypes';

const statuses = {
  fetching: false,
  error: '',
  lastUpdated: 0,
};

const initialState = {
  users: {
    data: [],
    nextToken: '',
    statuses,
  },
  user: {
    data: {},
    statuses,
  },
  groups: {
    data: [],
    nextToken: '',
    statuses,
  },
  userGroups: {
    data: [],
    nextToken: '',
    statuses,
  },
  group: {
    data: {},
    statuses,
  },
  userPool: {
    data: {},
    statuses,
  },
  message: {
    data: '',
    statuses,
  },
  apiKeys: {
    data: [],
    position: '',
    statuses,
  },
  apiKey: {
    data: {},
    statuses,
  },
  newKeyID: {
    data: {},
    statuses,
  },
  userApiKeys: {
    data: [],
    statuses,
  },
  usagePlans: {
    data: [],
    statuses,
  },
  usagePlan: {
    data: {},
    statuses,
  },
  keyUsagePlans: {
    data: [],
    statuses,
  },
  planKeys: {
    data: [],
    statuses,
  },
  usageData: {
    limit: {},
    throttle: {},
    monthlyUsage: '',
    statuses,
  },
  dialogStatus: {
    id: '',
    open: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_MESSAGE_REQUEST:
      return {
        ...state,
        message: {
          ...state.message,
          data: '',
        },
      };
    case actionTypes.SET_DIALOG_OPEN_REQUEST:
      return {
        ...state,
        dialogStatus: {
          ...state.dialogStatus,
          id: action.payload.id,
          open: true,
        },
      };

    case actionTypes.SET_DIALOG_CLOSE_REQUEST:
      return {
        ...state,
        dialogStatus: {
          ...state.dialogStatus,
          id: null,
          open: false,
        },
      };

    case actionTypes.GET_USAGE_PLAN_API_KEYS_REQUEST:
      return {
        ...state,
        planKeys: {
          ...state.planKeys,
          statuses: {
            ...state.planKeys.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_USAGE_PLAN_API_KEYS_FAILURE:
      return {
        ...state,
        planKeys: {
          ...state.planKeys,
          statuses: {
            ...state.planKeys.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_USAGE_PLAN_API_KEYS_SUCCESS:
      return {
        ...state,
        planKeys: {
          ...state.planKeys,
          data: action.payload.keys,
          statuses: {
            ...state.planKeys.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLAN_REQUEST:
      return {
        ...state,
        usageData: {
          ...state.usageData,
          statuses: {
            ...state.usageData.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLAN_FAILURE:
      return {
        ...state,
        keyUsagePlans: {
          ...state.usageData,
          statuses: {
            ...state.usageData.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLAN_SUCCESS:
      return {
        ...state,
        usageData: {
          ...state.usageData,
          monthlyUsage: action.payload.usage,
          statuses: {
            ...state.usageData.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_PLAN_REQUEST:
      return {
        ...state,
        usageData: {
          ...state.usageData,
          statuses: {
            ...state.usageData.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_PLAN_FAILURE:
      return {
        ...state,
        usageData: {
          ...state.usageData,
          statuses: {
            ...state.usageData.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_PLAN_SUCCESS:
      return {
        ...state,
        usageData: {
          ...state.usageData,
          limit: action.payload.limit,
          throttle: action.payload.throttle,
          statuses: {
            ...state.usageData.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLANS_REQUEST:
      return {
        ...state,
        keyUsagePlans: {
          ...state.keyUsagePlans,
          statuses: {
            ...state.keyUsagePlans.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLANS_FAILURE:
      return {
        ...state,
        keyUsagePlans: {
          ...state.keyUsagePlans,
          statuses: {
            ...state.keyUsagePlans.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_API_KEY_USAGE_PLANS_SUCCESS:
      return {
        ...state,
        keyUsagePlans: {
          ...state.keyUsagePlans,
          data: action.payload.usagePlans,
          statuses: {
            ...state.keyUsagePlans.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USAGE_PLANS_REQUEST:
      return {
        ...state,
        usagePlans: {
          ...state.usagePlans,
          statuses: {
            ...state.usagePlans.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_USAGE_PLANS_FAILURE:
      return {
        ...state,
        usagePlans: {
          ...state.usagePlans,
          statuses: {
            ...state.usagePlans.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_USAGE_PLANS_SUCCESS:
      return {
        ...state,
        usagePlans: {
          ...state.usagePlans,
          data: action.payload.usagePlans,
          position: action.payload.position,
          statuses: {
            ...state.usagePlans.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USAGE_PLAN_REQUEST:
      return {
        ...state,
        usagePlan: {
          ...state.usagePlan,
          statuses: {
            ...state.usagePlan.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_USAGE_PLAN_FAILURE:
      return {
        ...state,
        usagePlan: {
          ...state.usagePlan,
          statuses: {
            ...state.usagePlan.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_USAGE_PLAN_SUCCESS:
      return {
        ...state,
        usagePlan: {
          ...state.usagePlan,
          data: action.payload.plan,
          statuses: {
            ...state.usagePlan.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.CREATE_API_KEY_REQUEST:
      return {
        ...state,
        newKeyID: {
          ...state.newKeyID,
          statuses: {
            ...state.newKeyID.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.CREATE_API_KEY_FAILURE:
      return {
        ...state,
        newKeyID: {
          ...state.newKeyID,
          statuses: {
            ...state.newKeyID.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.CREATE_API_KEY_SUCCESS:
      return {
        ...state,
        newKeyID: {
          ...state.newKeyID,
          data: action.payload.newKey,
          statuses: {
            ...state.newKeyID.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };

    case actionTypes.GET_API_KEY_REQUEST:
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          statuses: {
            ...state.apiKey.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_API_KEY_FAILURE:
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          statuses: {
            ...state.apiKey.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_API_KEY_SUCCESS:
      return {
        ...state,
        apiKey: {
          ...state.apiKey,
          data: action.payload.key,
          nextToken: action.payload.nextToken,
          statuses: {
            ...state.apiKey.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_API_KEYS_REQUEST:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          statuses: {
            ...state.apiKeys.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_API_KEYS_FAILURE:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          statuses: {
            ...state.apiKeys.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_API_KEYS_SUCCESS:
      return {
        ...state,
        apiKeys: {
          ...state.apiKeys,
          data: action.payload.keys,
          position: action.payload.position,
          statuses: {
            ...state.apiKeys.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USER_API_KEYS_REQUEST:
      return {
        ...state,
        userApiKeys: {
          ...state.userApiKeys,
          statuses: {
            ...state.userApiKeys.statuses,
            fetching: true,
          },
        },
      };

    case actionTypes.GET_USER_API_KEYS_FAILURE:
      return {
        ...state,
        userApiKeys: {
          ...state.userApiKeys,
          statuses: {
            ...state.userApiKeys.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };

    case actionTypes.GET_USER_API_KEYS_SUCCESS:
      return {
        ...state,
        userApiKeys: {
          ...state.userApiKeys,
          data: action.payload.keys,
          statuses: {
            ...state.userApiKeys.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USER_REQUEST:
    case actionTypes.CREATE_USER_REQUEST:
      return {
        ...state,
        user: {
          ...state.user,
          statuses: {
            ...state.user.statuses, fetching: true,
          },
        },
      };
    case actionTypes.CREATE_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          statuses: {
            ...state.user.statuses, fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
          statuses: {
            ...state.user.statuses, fetching: false,
          },
        },
      };
    case actionTypes.CREATE_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          statuses: {
            ...state.user.statuses, fetching: true,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
          statuses: {
            ...state.user.statuses, fetching: false,
          },
        },
      };
    case actionTypes.GET_USER_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          statuses: {
            ...state.user.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          data: action.payload.user,
          statuses: {
            ...state.user.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USERS_REQUEST:
    case actionTypes.GET_GROUP_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          statuses: {
            ...state.users.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.GET_USERS_FAILURE:
    case actionTypes.GET_GROUP_USERS_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          statuses: {
            ...state.users.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_USERS_SUCCESS:
    case actionTypes.GET_GROUP_USERS_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.users,
          nextToken: action.payload.nextToken,
          statuses: {
            ...state.users.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.UPDATE_USER_REQUEST:
    case actionTypes.RESET_USERS_PASSWORD_REQUEST:
    case actionTypes.ADMIN_SET_USERS_PASSWORD_REQUEST:
    case actionTypes.ENABLE_USER_REQUEST:
    case actionTypes.DISABLE_USER_REQUEST:
    case actionTypes.ADD_USER_TO_GROUP_REQUEST:
    case actionTypes.REMOVE_USER_FROM_GROUP_REQUEST:
      return {
        ...state,
        message: {
          ...state.message,
          statuses: { ...state.message.statuses, fetching: true },
        },
      };
    case actionTypes.UPDATE_USER_FAILURE:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.RESET_USERS_PASSWORD_FAILURE:
    case actionTypes.ADMIN_SET_USERS_PASSWORD_FAILURE:
    case actionTypes.ENABLE_USER_FAILURE:
    case actionTypes.DISABLE_USER_FAILURE:
    case actionTypes.ADD_USER_TO_GROUP_FAILURE:
    case actionTypes.REMOVE_USER_FROM_GROUP_FAILURE:
    case actionTypes.ADD_USAGE_PLAN_TO_API_KEY_FAILURE:
    case actionTypes.REMOVE_USAGE_PLAN_FROM_API_KEY_FAILURE:
    case actionTypes.UPDATE_API_KEY_FAILURE:
      return {
        ...state,
        message: {
          ...state.message,
          statuses: {
            ...state.message.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.UPDATE_USER_SUCCESS:
    case actionTypes.RESET_USERS_PASSWORD_SUCCESS:
    case actionTypes.ADMIN_SET_USERS_PASSWORD_SUCCESS:
    case actionTypes.ENABLE_USER_SUCCESS:
    case actionTypes.DISABLE_USER_SUCCESS:
    case actionTypes.ADD_USER_TO_GROUP_SUCCESS:
    case actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS:
    case actionTypes.ADD_USAGE_PLAN_TO_API_KEY_SUCCESS:
    case actionTypes.REMOVE_USAGE_PLAN_FROM_API_KEY_SUCCESS:
    case actionTypes.UPDATE_API_KEY_SUCCESS:
    case actionTypes.ADD_REQUESTS_SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload.message,
          statuses: {
            ...state.message.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USERS_GROUPS_REQUEST:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          statuses: {
            ...state.userGroups.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.GET_GROUPS_REQUEST:
      return {
        ...state,
        groups: {
          ...state.groups,
          statuses: {
            ...state.groups.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.GET_USERS_GROUPS_FAILURE:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          statuses: {
            ...state.userGroups.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_GROUPS_FAILURE:
      return {
        ...state,
        groups: {
          ...state.groups,
          statuses: {
            ...state.groups.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_USERS_GROUPS_SUCCESS:
      return {
        ...state,
        userGroups: {
          ...state.userGroups,
          data: action.payload.userGroups,
          statuses: {
            ...state.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };
    case actionTypes.GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          data: action.payload.groups,
          nextToken: action.payload.nextToken,
          statuses: {
            ...state.groups.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_GROUP_REQUEST:
      return {
        ...state,
        group: {
          ...state.group,
          statuses: {
            ...state.group.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.GET_GROUP_FAILURE:
      return {
        ...state,
        group: {
          ...state.group,
          statuses: {
            ...state.group.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          data: action.payload.group,
          statuses: {
            ...state.group.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    case actionTypes.GET_USER_POOL_DATA_REQUEST:
      return {
        ...state,
        userPool: {
          ...state.userPool,
          statuses: {
            ...state.userPool.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.GET_USER_POOL_DATA_FAILURE:
      return {
        ...state,
        userPool: {
          ...state.userPool,
          statuses: {
            ...state.userPool.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_USER_POOL_DATA_SUCCESS:
      return {
        ...state,
        userPool: {
          ...state.userPool,
          data: action.payload.data,
          statuses: {
            ...state.userPool.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;

export const getGroupFromStore = (state) => state.group.data;
export const getGroupStatusesFromStore = (state) => state.group.statuses;

export const getGroupsFromStore = (state) => state.groups.data;
export const getGroupsNextTokenFromStore = (state) => state.groups.nextToken;
export const getGroupsStatusesFromStore = (state) => state.groups.statuses;

export const getUserGroupsFromStore = (state) => state.userGroups.data;
export const getUserGroupsNextTokenFromStore = (state) => state.userGroups.nextToken;
export const getUserGroupsStatusesFromStore = (state) => state.userGroups.statuses;

export const getUserFromStore = (state) => state.user.data;
export const getUserStatusesFromStore = (state) => state.user.statuses;

export const getUsersFromStore = (state) => state.users.data;
export const getUsersNextTokenFromStore = (state) => state.users.nextToken;
export const getUsersStatusesFromStore = (state) => state.users.statuses;

export const getUserPoolFromStore = (state) => state.userPool.data;
export const getUserPoolStatusesFromStore = (state) => state.userPool.statuses;

export const getMessageFromStore = (state) => state.message.data;
export const getMessageStatusesFromStore = (state) => state.message.statuses;

export const getApiKeysFromStore = (state) => state.apiKeys.data;
export const getApiKeysPositionFromStore = (state) => state.apiKeys.position;
export const getApiKeysStatusesFromStore = (state) => state.apiKeys.statuses;
export const getApiKeysNextTokenFromStore = (state) => state.apiKeys.nextToken;

export const getApiKeyFromStore = (state) => state.apiKey.data;
export const getApiKeyStatusFromStore = (state) => state.apiKey.statuses;

export const getNewApiKeyFromStore = (state) => state.newKeyID.data;
export const getNewApiKeyStatusFromStore = (state) => state.newKeyID.statuses;

export const getUserApiKeysFromStore = (state) => state.userApiKeys.data;
export const getUserApiKeysStatusesFromStore = (state) => state.userApiKeys.statuses;

export const getUsagePlansFromStore = (state) => state.usagePlans.data;
export const getUsagePlansStatusFromStore = (state) => state.usagePlans.statuses;
export const getUsagePlansNextTokenFromStore = (state) => state.usagePlans.position;

export const getApiKeyUsagePlansFromStore = (state) => state.keyUsagePlans.data;
export const getApiKeyUsagePlansStatusesFromStore = (state) => state.keyUsagePlans.statuses;

export const getUsageDataFromStore = (state) => state.usageData;
export const getUsageDataStatusFromStore = (state) => state.usageData.statuses;

export const getMonthlyUsageDataFromStore = (state) => state.usageData.monthlyUsage;

export const getUsagePlanFromStore = (state) => state.usagePlan.data;
export const getUsagePlanStatusFromStore = (state) => state.usagePlan.statuses;

export const getUsagePlanApiKeysFromStore = (state) => state.planKeys.data;
export const getUsagePlanApiKeysStatusesFromStore = (state) => state.planKeys.statuses;

export const getDialogStatusFromStore = (state) => state.dialogStatus;
