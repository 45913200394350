import { Typography, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import BusinessIcon from '@material-ui/icons/Business';
import PhoneIcon from '@material-ui/icons/Phone';
import * as PropTypes from 'prop-types';
import React from 'react';

import environment from '../../configs/environment';
import withAuth from '../hocs/withAuth';
import GeneralPageHolder from './GeneralPageHolder';

const styles = (theme) => ({
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  devOutput: {
    textAlign: 'left',
    color: theme.palette.text.primary,
  },
});

const ProfilePage = ({ currentSession, access, classes }) => {
  const { env } = environment;
  let content = null;
  let devOutput = null;

  if ('idToken' in currentSession) {
    const { idToken } = currentSession;
    const { payload } = idToken;
    const {
      'cognito:username': username,
      name,
      'talroo:customer:name': companyName,
      email,
      phone_number: phoneNumber,
    } = payload;

    content = (
      <div>
        <Typography variant="h4" component="h4" color="textPrimary">
          Hello {name}
        </Typography>
        <Divider />
        <br />
        <Card className={classes.card}>
          <CardContent>
            <List component="nav" aria-label="secondary mailbox folders">
              <ListItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                <ListItemText primary={username} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <AlternateEmailIcon />
                </ListItemIcon>
                <ListItemText primary={email} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary={phoneNumber} />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <ListItemText primary={companyName} />
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
    );
  }

  if (env === 'dev') {
    const accessGroups = JSON.stringify(access, undefined, 4);
    const tokenData = JSON.stringify(currentSession, undefined, 4);
    devOutput = (
      <div>
        <Typography variant="h5" color="textPrimary">Access Groups</Typography>
        <pre className={classes.devOutput}>
          {accessGroups}
        </pre>
        <Typography variant="h5" color="textPrimary">Auth Tokens</Typography>
        <pre className={classes.devOutput}>
          {tokenData}
        </pre>
      </div>
    );
  }

  return (
    <GeneralPageHolder>
      {content}
      {devOutput}
    </GeneralPageHolder>
  );
};

ProfilePage.propTypes = {
  classes: PropTypes.object.isRequired,
  access: PropTypes.array.isRequired,
  currentSession: PropTypes.object.isRequired,
};

export default withAuth(withStyles(styles)(ProfilePage));
