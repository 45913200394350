import { call, put, takeLatest } from 'redux-saga/effects';
import { getSharingSecretFailure, getSharingSecretSuccess } from './actions';

import { GET_SHARING_SECRET_REQUEST } from './actionTypes';

import api from './api';

function* insightsApiSaga() {
  yield takeLatest(GET_SHARING_SECRET_REQUEST, getSharingSecretRequest);
}

function* getSharingSecretRequest(action) {
  try {
    const response = yield call(api.getSharingSecret,
      action.payload.sharingToken);
    yield put(getSharingSecretSuccess(action.payload.sharingToken, response));
  } catch (error) {
    yield put(getSharingSecretFailure(error));
  }
}

export default insightsApiSaga;
