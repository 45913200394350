import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const RemoveUserFromGroup = ({
  username,
  groupName,
  removeUserFromGroup,
  setDialogClose,
}) => {
  const handleRemove = () => {
    removeUserFromGroup(username, groupName);
    setDialogClose();
  };

  return <YesNoButtons yesAction={handleRemove} noAction={setDialogClose} />;
};

RemoveUserFromGroup.propTypes = {
  username: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  removeUserFromGroup: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default RemoveUserFromGroup;
