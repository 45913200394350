import MaterialLink from '@material-ui/core/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import RemoveUserFromGroupController
  from '../controllers/RemoveUserFromGroupController';
import CommonTable from './common/CommonTable';

const GroupUsersList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { users, nextToken, getGroupUsers, groupName } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (users.length !== 0) {
      setRows(buildRows(users));
    } else {
      setRows(buildRows([]));
    }
  }, [users]);

  // eslint-disable-next-line no-unused-vars
  const paginationCallback = (limit, page) => {
    getGroupUsers(groupName);
  };

  const tableHeaders = [
    'Username',
    'Name',
    'Remove From Group?',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const { username, name } = rawData[key];
      newObject[name] = [
        <MaterialLink
          component={Link}
          to={`/user-management/users/${username}`}
          color="primary"
        >
          {username}
        </MaterialLink>,
        name,
        <SimpleDialogController
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle="Remove"
          dialogHeader="Are you sure?"
          component={<RemoveUserFromGroupController
            username={username}
            groupName={groupName}
          />}
          id={`${username}-removeGroup`}
        />,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

GroupUsersList.propTypes = {
  users: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getGroupUsers: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
};

GroupUsersList.defaultProps = {
  nextToken: '',
};

export default GroupUsersList;
