import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../../Common/components/Breadcrumbs';

import RedirectRoute from '../../Common/components/RedirectRoute';

const styles = (theme) => ({
  cluvioDashboardIframe: {
    height: 'calc(100vh - 65px - 28px)',
    width: '100%',
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    minHeight: 'calc(100vh - 65px - 28px)',
  },
  boxDarkMode: {
    backgroundColor: '#343434',
  },
  breadcrumbs: {
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
});

class CluvioDashboard extends Component {
  constructor(props) {
    super(props);
    const { location, queryParametersToObject, rawFiltersToObject } = props;
    const { search, pathname } = location;
    const filters = (search !== '')
      ? queryParametersToObject(search.substring(1))
      : {};
    this.state = {
      sharingSecret: '',
      dashboardName: pathname,
      nextDashboard: '',
      currentDashboardTimerange: 'timerange' in filters && filters.timerange
      !== '' ? filters.timerange : 'this_month',
      currentDashboardFilters: JSON.stringify(rawFiltersToObject(filters)),
      nextDashboardFilters: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.sharingSecret !== state.sharingSecret) {
      return {
        ...state,
        sharingSecret: props.sharingSecret,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    window.addEventListener('message', this.handleFrameTasks);
    const { getSharingSecret, dashboardDetails, sharingSecret } = this.props;
    if (sharingSecret === '') {
      const { sharing_token: sharingToken } = dashboardDetails;
      getSharingSecret(sharingToken);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleFrameTasks);
  }

  handleFrameTasks = (e) => {
    const {
      cluvioDomain,
      filterArrayToObject,
      filterArrayToObjectFromUpdate,
      getDashboardDataByDashboardId,
    } = this.props;

    if (e.origin === cluvioDomain) {
      const eventData = JSON.parse(e.data);

      // Check to see if the right event was triggered from the Cluvio iframe.
      if (eventData.event === 'drillDown') {
        const { dashboardId, filters } = eventData.params;
        const dashboardPath = getDashboardDataByDashboardId(dashboardId);
        this.setState({
          nextDashboard: dashboardPath,
          nextDashboardFilters: filterArrayToObject(filters),
        });
      } else if (eventData.event === 'parametersChanged') {
        const { history } = this.props;
        const { dashboardName } = this.state;
        const { filters, timerange } = eventData.params.parameters;
        const newFilters = filterArrayToObjectFromUpdate(filters);
        newFilters.timerange = timerange;
        this.setState({
          nextDashboardFilters: newFilters,
        });
        history.push(
          `${dashboardName}?${Object.keys(newFilters).map((key) =>
            `${key}=${newFilters[key]}`).join('&')}`,
        );
      } else {
        // Catch events that we do not handle here.
        console.log(eventData.event);
      }
    }
  };

  render() {
    let component = null;
    const {
      classes,
      dashboardDetails,
      cluvioDomain,
      getCluvioIframeDashboard,
    } = this.props;
    const {
      sharingSecret,
      dashboardName,
      nextDashboard,
      currentDashboardFilters,
      nextDashboardFilters,
      currentDashboardTimerange,
    } = this.state;

    if (nextDashboard !== '' && dashboardName !== nextDashboard) {
      component = (
        <RedirectRoute
          pathname={`/insights/${nextDashboard}`}
          query={nextDashboardFilters}
        />
      );
    } else {
      let componentContent = null;
      const initialPaletteType = Cookies.get('paletteType');
      const darkModeEnabled = initialPaletteType && initialPaletteType
        === 'dark';

      if (sharingSecret === 'error') {
        componentContent = (
          <Typography variant="body1" color="textPrimary">
            Please contact your CSA to get access to this dashboard.
          </Typography>
        );
      } else if (sharingSecret !== '') {
        const dashboardUrl = getCluvioIframeDashboard(
          cluvioDomain,
          dashboardDetails.dashboard_id,
          dashboardDetails.sharing_token,
          sharingSecret,
          currentDashboardFilters,
          currentDashboardTimerange,
          darkModeEnabled,
        );
        componentContent = (
          <iframe
            id="cluvio-iframe"
            title={dashboardName}
            className={classes.cluvioDashboardIframe}
            height="100%"
            src={dashboardUrl}
            frameBorder="0"
          >
          </iframe>
        );
      }

      component = (
        <header
          className={`${classes.box} ${darkModeEnabled
            ? classes.boxDarkMode
            : ''}`}
        >
          <Breadcrumbs
            path={dashboardName}
            classes={{ breadcrumbs: classes.breadcrumbs }}
          />
          {componentContent}
        </header>
      );
    }
    return component;
  }
}

CluvioDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  sharingSecret: PropTypes.string.isRequired,
  cluvioDomain: PropTypes.string.isRequired,
  dashboardDetails: PropTypes.object.isRequired,
  getSharingSecret: PropTypes.func.isRequired,
  getCluvioIframeDashboard: PropTypes.func.isRequired,
  getDashboardDataByDashboardId: PropTypes.func.isRequired,
  filterArrayToObject: PropTypes.func.isRequired,
  filterArrayToObjectFromUpdate: PropTypes.func.isRequired,
  queryParametersToObject: PropTypes.func.isRequired,
  rawFiltersToObject: PropTypes.func.isRequired,
};

export default withRouter(withStyles(styles)(CluvioDashboard));
