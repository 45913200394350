import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import React from 'react';

import ProfilePage from './components/ProfilePage';
import RedirectRoute from './components/RedirectRoute';

const routes = [
  {
    pageTitle: 'Home',
    path: '/',
    icon: null,
    exact: true,
    main: () => <RedirectRoute pathname="/insights" />,
  },
  {
    pageTitle: 'Profile',
    path: '/profile',
    icon: <AccountCircleIcon />,
    exact: true,
    main: () => <ProfilePage />,
  },
];

export default routes;
