import Button from '@material-ui/core/Button';
import MaterialLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  searchButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    height: 56,
    boxShadow: 'none',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  searchHolder: {
    marginBottom: theme.spacing(1),
  },
  searchTextField: {
    borderRadius: 0,
    '& label.Mui-focused': {
      borderRadius: 0,
    },
    '& .MuiInput-underline:after': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
});

class AdsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: '',
      submitSearch: false,
    };
  }

  componentDidMount() {
    this.props.clearAd();
  }

  componentDidUpdate(prevProps) {
    if (this.props.message !== prevProps.message) {
      if (this.props.message.includes('Successfully')) {
        this.props.setSuccessMessage(this.props.message);
      } else if (this.props.message.includes('Failed')) {
        this.props.setErrorMessage(this.props.message);
      }
    }
  }

  handleSearch = (search) => {
    if (search !== '') {
      this.props.getAds(search);
      this.setState({ submitSearch: true });
      this.props.clearMessage();
    }
  };

  handleChange = (e) => {
    this.props.clearMessage();
    this.setState({ submitSearch: false });

    const { target } = e;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      classes,
      ads,
      message,
    } = this.props;

    return (
      <GeneralPageHolder>

        <Breadcrumbs path={window.location.pathname} />
        <br />
        <div className={classes.searchHolder}>
          <TextField
            className={classes.searchTextField}
            name="search"
            label="Customer ID"
            variant="outlined"
            onChange={this.handleChange}
            autoFocus
            color="secondary"
          />
          <Button
            color="primary"
            variant="contained"
            startIcon={<SearchIcon />}
            className={classes.searchButton}
            onClick={() => this.handleSearch(this.state.search)}
          >
            Search
          </Button>
        </div>
        <br />
        {Object.keys(ads).length === 0 && message === '' && this.state.submitSearch === true ?
          <ProgressWheel />
          :
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>Ad Name</TableCell>
                <TableCell className={classes.head}>Customer Id</TableCell>
                <TableCell className={classes.head}>Ad Type</TableCell>
                <TableCell className={classes.head}>Ad Id</TableCell>
                <TableCell className={classes.head}>Enabled</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {ads.map((ad) => {
                const {
                  ad_type: adType,
                  general,
                } = ad;
                return (
                  <TableRow key={general.ad_id}>
                    <TableCell component="th" scope="row">
                      <MaterialLink
                        component={Link}
                        to={`/pop-management/ad/${general.customer_id}/${general.ad_id}`}
                        color="primary"
                      >
                        {general.ad_name}
                      </MaterialLink>
                    </TableCell>
                    <TableCell>{general.customer_id}</TableCell>
                    <TableCell>{adType}</TableCell>
                    <TableCell>{general.ad_id}</TableCell>
                    <TableCell>{general.enabled ? 'Yes' : 'No'}</TableCell>

                  </TableRow>
                );
              })}
            </TableBody>
          </Table>}
      </GeneralPageHolder>
    );
  }
}

AdsList.propTypes = {
  classes: PropTypes.object.isRequired,
  ads: PropTypes.array.isRequired,
  clearAd: PropTypes.func.isRequired,
  getAds: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default withView(null)(withStyles(styles)(AdsList));
