import { Auth } from 'aws-amplify';
import axios from 'axios';

import environment from '../../../configs/environment';

const API_DOMAIN = environment.insights_api_domain;

const api = {
  getSharingSecret: (sharingToken) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.post(
        `${API_DOMAIN}/v2/cluvio/dashboard/${sharingToken}/sharing_secret`,
        {
          exp: 1440,
          fixed_parameters: {},
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),
};

export default api;
