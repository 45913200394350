import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import withView from '../../Common/hocs/withView';
import { formatDateTime, toTitleCase } from '../../Common/utils';
import AddUsagePlanToApiKeyController
  from '../controllers/AddUsagePlanToApiKeyController';
import ApiKeyEnablerController from '../controllers/ApiKeyEnablerController';
import ApiKeyUsagePlansListController
  from '../controllers/ApiKeyUsagePlansListController';
import Placeholder from './common/Placeholder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  buttonHolder: {
    textAlign: 'center',
  },
}));

const ApiKey = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const {
    message: newMessage,
    messageStatus,
    match,
    apiKey,
    apiKeyStatus,
    setSuccessMessage,
    setErrorMessage,
    getApiKeyUsagePlans,
    getApiKey,
  } = props;
  const { params } = match;
  const { apikeyid: apiKeyId } = params;

  useEffect(() => {
    // TODO: Messages with the same content cause a weird issue here.
    if (newMessage !== message) {
      setSuccessMessage(newMessage);
      setMessage(newMessage);
    }

    if (newMessage.includes('Successfully deleted the Usage Plan')
      || newMessage.includes('Successfully Added Usage Plan')
    ) {
      getApiKeyUsagePlans(apiKeyId);
      setMessage('');
      window.location.href = '#plans';
    } else if (newMessage.includes('Successfully Updated API Key')) {
      getApiKey(apiKeyId);
      setMessage('');
      window.location.href = '#main';
    }
  }, [newMessage]);

  useEffect(() => {
    if (messageStatus.error) {
      setErrorMessage(messageStatus.error);
    }
  }, [newMessage]);

  useEffect(() => {
    getApiKey(apiKeyId);
  }, [match]);

  return (
    <GeneralPageHolder>
      <div id="main" />
      <Breadcrumbs path={window.location.pathname} />
      <br />
      {Object.keys(apiKey).length === 0
        ? <ProgressWheel />
        : (
          <>
            <div className={classes.buttonHolder}>
              <SimpleDialogController
                buttonIcon={<AddIcon />}
                buttonTitle="Add Usage Plan"
                disabled={apiKeyStatus.fetching}
                dialogHeader="Choose Usage Plan"
                component={<AddUsagePlanToApiKeyController
                  apiKeyId={apiKeyId}
                />}
                id="addPlan"
              />
              <SimpleDialogController
                buttonIcon={apiKey.enabled !== true
                  ? <CheckCircleOutlineIcon />
                  : <HighlightOffIcon />}
                buttonTitle={apiKey.enabled !== true
                  ? 'Enable Key'
                  : 'Disable Key'}
                disabled={apiKeyStatus.fetching}
                dialogHeader="Are you sure?"
                component={<ApiKeyEnablerController apiKey={apiKey} />}
                closeAction={() => getApiKey(apiKeyId)}
                id="enableKey"
              />
            </div>
            <Paper className={classes.root}>
              <List>
                {Object.keys(apiKey).map((key) => {
                  const listKey = `apiKey${key}`;
                  let value = apiKey[key];

                  if (typeof value === 'boolean') {
                    value = value ? 'Yes' : 'No';
                  }

                  if (key === 'tags') {
                    return null;
                  }

                  if (key === 'last_updated_date' || key === 'created_date') {
                    value = formatDateTime(value);
                  }

                  return (
                    <div key={`${listKey}div`}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {toTitleCase(key)}
                            </Typography>
                          }
                          secondary={apiKeyStatus.fetching
                            ? <Placeholder />
                            : value}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </div>
                  );
                })}
              </List>
            </Paper>
            <div id="plans" />
            <br />
            <Typography variant="h4" color="textPrimary" gutterBottom>
              Usage Plans
            </Typography>
            <ApiKeyUsagePlansListController apiKeyId={apiKeyId} />
          </>
        )}
    </GeneralPageHolder>
  );
};

ApiKey.propTypes = {
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  messageStatus: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getApiKey: PropTypes.func.isRequired,
  apiKey: PropTypes.object.isRequired,
  apiKeyStatus: PropTypes.object.isRequired,
  getApiKeyUsagePlans: PropTypes.func.isRequired,
};

export default withView(null)(ApiKey);
