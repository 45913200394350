import * as actionTypes from './actionTypes';

const statuses = {
  fetching: false,
  error: '',
  lastUpdated: 0,
};

const initialState = {
  ad: {
    data: {},
    statuses,
  },
  ads: {
    data: [],
    statuses,
  },
  adSpec: {
    data: {},
    statuses,
  },
  adSnippet: {
    data: '',
    statuses,
  },
  message: {
    data: '',
    statuses,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.POP_CLEAR_MESSAGE_REQUEST:
      return {
        ...state,
        message: {
          ...state.message,
          data: '',
        },
      };
    case actionTypes.POP_CLEAR_AD_REQUEST:
      return {
        ...state,
        ad: {
          ...state.ad,
          data: {},
        },
      };
    case actionTypes.POP_CREATE_AD_UNIT_REQUEST:
      return {
        ...state,
        ad: {
          ...state.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_CREATE_AD_UNIT_SUCCESS:
      return {
        ...state,
        ad: {
          ...state.ad,
          data: action.payload.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_CREATE_AD_UNIT_FAILURE:
      return {
        ...state,
        ad: {
          ...state.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_UPDATE_AD_UNIT_REQUEST:
      return {
        ...state,
        ad: {
          ...state.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_UPDATE_AD_UNIT_SUCCESS:
      return {
        ...state,
        ad: {
          ...state.ad,
          data: action.payload.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_UPDATE_AD_UNIT_FAILURE:
      return {
        ...state,
        ad: {
          ...state.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_GET_ADS_REQUEST:
      return {
        ...state,
        ads: {
          ...state.ads,
          statuses: {
            ...state.ads.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_GET_ADS_SUCCESS:
      return {
        ...state,
        ads: {
          ...state.ads,
          data: action.payload.ads,
          statuses: {
            ...state.ads.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_GET_ADS_FAILURE:
      return {
        ...state,
        ads: {
          ...state.ads,
          data: [],
          statuses: {
            ...state.ads.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_GET_AD_UNIT_SPEC_REQUEST:
      return {
        ...state,
        adSpec: {
          ...state.adSpec,
          data: {},
          statuses: {
            ...state.adSpec.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_GET_AD_UNIT_SPEC_SUCCESS:
      return {
        ...state,
        adSpec: {
          ...state.adSpec,
          data: action.payload.spec,
          statuses: {
            ...state.adSpec.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_GET_AD_UNIT_SPEC_FAILURE:
      return {
        ...state,
        adSpec: {
          ...state.adSpec,
          statuses: {
            ...state.adSpec.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_GET_AD_UNIT_REQUEST:
      return {
        ...state,
        ad: {
          ...state.ad,
          data: {},
          statuses: {
            ...state.ad.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_GET_AD_UNIT_SUCCESS:
      return {
        ...state,
        ad: {
          ...state.ad,
          data: action.payload.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_GET_AD_UNIT_FAILURE:
      return {
        ...state,
        ad: {
          ...state.ad,
          statuses: {
            ...state.ad.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_GET_AD_SNIPPET_REQUEST:
      return {
        ...state,
        adSnippet: {
          ...state.adSnippet,
          data: '',
          statuses: {
            ...state.adSnippet.statuses,
            fetching: true,
          },
        },
      };
    case actionTypes.POP_GET_AD_SNIPPET_SUCCESS:
      return {
        ...state,
        adSnippet: {
          ...state.adSnippet,
          data: action.payload.adSnippet,
          statuses: {
            ...state.adSnippet.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_GET_AD_SNIPPET_FAILURE:
      return {
        ...state,
        adSnippet: {
          ...state.adSnippet,
          statuses: {
            ...state.adSnippet.statuses,
            fetching: false,
          },
        },
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    case actionTypes.POP_PUBLISH_AD_UNIT_SUCCESS:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload.message,
        },
      };
    case actionTypes.POP_PUBLISH_AD_UNIT_FAILURE:
      return {
        ...state,
        message: {
          ...state.message,
          data: action.payload.error,
        },
      };
    default:
      return state;
  }
};

export default reducer;

export const getAdFromStore = (state) => state.ad.data;
export const getAdsFromStore = (state) => state.ads.data;
export const getAdSnippetFromStore = (state) => state.adSnippet.data;
export const getAdSpecFromStore = (state) => state.adSpec.data;
export const getPopMessageFromStore = (state) => state.message.data;
