import EqualizerIcon from '@material-ui/icons/Equalizer';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import React from 'react';

import NavOptionsPage from '../Common/components/NavOptionsPage';
import { USER_MANAGEMENT_ADMIN_ACCESS_GROUP } from './accessGroups';
import AddUserController from './controllers/AddUserController';
import ApiKeyController from './controllers/ApiKeyController';
import ApiKeysListController from './controllers/ApiKeysListController';
import KeyUsagePlanController from './controllers/ApiKeyUsagePlanController';
import GroupController from './controllers/GroupController';
import GroupsListController from './controllers/GroupsListController';
import UpdateUserController from './controllers/UpdateUserController';
import UsagePlanController from './controllers/UsagePlanController';
import UsagePlansListController from './controllers/UsagePlansListController';
import UserController from './controllers/UserController';
import UsersListController from './controllers/UsersListController';

const routes = [
  {
    pageTitle: 'User Management',
    path: '/user-management',
    icon: null,
    exact: true,
    main: NavOptionsPage,
    extras: {
      access: [
        USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
      ],
    },
    routes: [
      {
        pageTitle: 'Users',
        path: '/user-management/users',
        icon: <PeopleIcon />,
        exact: true,
        main: UsersListController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'User',
        path: '/user-management/users/:username',
        icon: <PeopleIcon />,
        exact: true,
        main: UserController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        pageTitle: 'Add User',
        path: '/user-management/add-user',
        icon: <PersonAddIcon />,
        exact: true,
        main: AddUserController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        pageTitle: 'Groups',
        path: '/user-management/groups',
        icon: <GroupWorkIcon />,
        exact: true,
        main: GroupsListController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'Group',
        path: '/user-management/groups/:group',
        icon: <GroupWorkIcon />,
        exact: true,
        main: GroupController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        pageTitle: 'API Keys',
        path: '/user-management/api-keys',
        icon: <VpnKeyIcon />,
        exact: true,
        main: ApiKeysListController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        pageTitle: 'Usage Plans',
        path: '/user-management/usage-plans',
        icon: <EqualizerIcon />,
        exact: true,
        main: UsagePlansListController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'API Key Usage Plan',
        path: '/user-management/api-keys/:apikeyid/usage-plans/:planid',
        icon: <VpnKeyIcon />,
        exact: true,
        main: KeyUsagePlanController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'API Key Usage Plan',
        path: '/user-management/api-keys/:apikeyid/usage-plans',
        icon: <VpnKeyIcon />,
        exact: true,
        main: ApiKeyController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'Usage Plan',
        path: '/user-management/usage-plans/:planid',
        icon: <EqualizerIcon />,
        exact: true,
        main: UsagePlanController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'API Key',
        path: '/user-management/api-keys/:apikeyid',
        icon: <VpnKeyIcon />,
        exact: true,
        main: ApiKeyController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
      {
        type: 'hidden',
        pageTitle: 'Update User',
        path: '/user-management/users/:username/update',
        icon: <PeopleIcon />,
        exact: true,
        main: UpdateUserController,
        extras: {
          access: [
            USER_MANAGEMENT_ADMIN_ACCESS_GROUP,
          ],
        },
      },
    ],
  },
];

export default routes;
