import * as actionTypes from './actionTypes';

export const clearMessageRequest = () => ({
  type: actionTypes.CLEAR_MESSAGE_REQUEST,
  payload: {},
});

export const setDialogOpenRequest = (id) => ({
  type: actionTypes.SET_DIALOG_OPEN_REQUEST,
  payload: {
    id,
  },
});

export const setDialogCloseRequest = () => ({
  type: actionTypes.SET_DIALOG_CLOSE_REQUEST,
  payload: {},
});

export const getUsagePlanApiKeysRequest = (
  planId,
  apiKeyId,
  limit,
  position,
  nameQuery,
) => ({
  type: actionTypes.GET_USAGE_PLAN_API_KEYS_REQUEST,
  payload: {
    planId,
    apiKeyId,
    limit,
    position,
    nameQuery,
  },
});

export const getUsagePlanApiKeysSuccess = (keys) => ({
  type: actionTypes.GET_USAGE_PLAN_API_KEYS_SUCCESS,
  payload: {
    keys,
  },
});

export const getUsagePlanApiKeysFailure = (error) => ({
  type: actionTypes.GET_USAGE_PLAN_API_KEYS_FAILURE,
  payload: {
    error,
  },
});

export const addRequestsRequest = (planId, apiKeyId, remaining) => ({
  type: actionTypes.ADD_REQUESTS_REQUEST,
  payload: {
    planId,
    apiKeyId,
    remaining,
  },
});

export const addRequestsSuccess = (message) => ({
  type: actionTypes.ADD_REQUESTS_SUCCESS,
  payload: {
    message,
  },
});

export const addRequestsFailure = (error) => ({
  type: actionTypes.ADD_REQUESTS_FAILURE,
  payload: {
    error,
  },
});

export const getPlanRequest = (planId, limit, position, apiKeyId) => ({
  type: actionTypes.GET_PLAN_REQUEST,
  payload: {
    planId,
    limit,
    position,
    apiKeyId,
  },
});

export const getPlanSuccess = (limit, throttle) => ({
  type: actionTypes.GET_PLAN_SUCCESS,
  payload: {
    limit,
    throttle,
  },
});

export const getPlanFailure = (error) => ({
  type: actionTypes.GET_PLAN_FAILURE,
  payload: {
    error,
  },
});

export const getApiKeyUsagePlanRequest = (
  planId,
  apiKeyId,
  limit,
  position,
  startDate,
  endDate,
) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLAN_REQUEST,
  payload: {
    planId,
    apiKeyId,
    limit,
    position,
    startDate,
    endDate,
  },
});

export const getApiKeyUsagePlanSuccess = (usage) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLAN_SUCCESS,
  payload: {
    usage,
  },
});

export const getApiKeyUsagePlanFailure = (error) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLAN_FAILURE,
  payload: {
    error,
  },
});

export const getNumberOfKeysRequest = (
  apiKeyId,
  limit,
  position,
  nameQuery,
  includeValue,
) => ({
  type: actionTypes.GET_NUMBER_OF_KEYS_REQUEST,
  payload: {
    apiKeyId,
    limit,
    position,
    nameQuery,
    includeValue,
  },
});

export const removeUsagePlanFromApiKeyRequest = (apiKeyId, planId) => ({
  type: actionTypes.REMOVE_USAGE_PLAN_FROM_API_KEY_REQUEST,
  payload: {
    apiKeyId,
    planId,
  },
});

export const removeUsagePlanFromApiKeySuccess = (message) => ({
  type: actionTypes.REMOVE_USAGE_PLAN_FROM_API_KEY_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const removeUsagePlanFromApiKeyFailure = (error) => ({
  type: actionTypes.REMOVE_USAGE_PLAN_FROM_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const updateApiKeyRequest = (apiKeyId, name, description, enabled) => ({
  type: actionTypes.UPDATE_API_KEY_REQUEST,
  payload: {
    apiKeyId,
    name,
    description,
    enabled,
  },
});

export const updateApiKeySuccess = (message) => ({
  type: actionTypes.UPDATE_API_KEY_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const updateApiKeyFailure = (error) => ({
  type: actionTypes.UPDATE_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const addUsagePlanToApiKeyRequest = (planId, apiKeyId) => ({
  type: actionTypes.ADD_USAGE_PLAN_TO_API_KEY_REQUEST,
  payload: {
    planId,
    apiKeyId,
  },
});

export const addUsagePlanToApiKeySuccess = (message) => ({
  type: actionTypes.ADD_USAGE_PLAN_TO_API_KEY_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const addUsagePlanToApiKeyFailure = (error) => ({
  type: actionTypes.ADD_USAGE_PLAN_TO_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const getApiKeyUsagePlansRequest = (apiKeyId) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLANS_REQUEST,
  payload: {
    apiKeyId,
  },
});

export const getApiKeyUsagePlansSuccess = (usagePlans) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLANS_SUCCESS,
  payload: {
    usagePlans,
    receivedAt: Date.now(),
  },
});

export const getApiKeyUsagePlansFailure = (error) => ({
  type: actionTypes.GET_API_KEY_USAGE_PLANS_FAILURE,
  payload: {
    error,
  },
});

export const getUsagePlanRequest = (planId, limit, position, apiKeyId) => ({
  type: actionTypes.GET_USAGE_PLAN_REQUEST,
  payload: {
    planId,
    limit,
    position,
    apiKeyId,
  },
});

export const getUsagePlanSuccess = (plan) => ({
  type: actionTypes.GET_USAGE_PLAN_SUCCESS,
  payload: {
    plan,
    receivedAt: Date.now(),
  },
});

export const getUsagePlanFailure = (error) => ({
  type: actionTypes.GET_USAGE_PLAN_FAILURE,
  payload: {
    error,
  },
});

export const getUsagePlansRequest = (planId, limit, position, apiKeyId) => ({
  type: actionTypes.GET_USAGE_PLANS_REQUEST,
  payload: {
    planId,
    limit,
    position,
    apiKeyId,
  },
});

export const getUsagePlansSuccess = (usagePlans, position) => ({
  type: actionTypes.GET_USAGE_PLANS_SUCCESS,
  payload: {
    usagePlans,
    position,
    receivedAt: Date.now(),
  },
});

export const getUsagePlansFailure = (error) => ({
  type: actionTypes.GET_USAGE_PLANS_FAILURE,
  payload: {
    error,
  },
});

export const createApiKeyRequest = (keyName, description, enabled, tags) => ({
  type: actionTypes.CREATE_API_KEY_REQUEST,
  payload: {
    keyName,
    description,
    enabled,
    tags,
  },
});

export const createApiKeySuccess = (newKey, message) => ({
  type: actionTypes.CREATE_API_KEY_SUCCESS,
  payload: {
    newKey,
    message,
    receivedAt: Date.now(),
  },
});

export const createApiKeyFailure = (error) => ({
  type: actionTypes.CREATE_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const getApiKeysRequest = (
  apiKeyId,
  limit,
  position,
  nameQuery,
  includeValue,
) => ({
  type: actionTypes.GET_API_KEYS_REQUEST,
  payload: {
    apiKeyId,
    limit,
    position,
    nameQuery,
    includeValue,
  },
});

export const getApiKeysSuccess = (keys, position) => ({
  type: actionTypes.GET_API_KEYS_SUCCESS,
  payload: {
    keys,
    position,
    receivedAt: Date.now(),
  },
});

export const getApiKeysFailure = (error) => ({
  type: actionTypes.GET_API_KEYS_FAILURE,
  payload: {
    error,
  },
});

export const getUserApiKeysRequest = (nameQuery) => ({
  type: actionTypes.GET_USER_API_KEYS_REQUEST,
  payload: {
    nameQuery,
  },
});

export const getUserApiKeysSuccess = (keys) => ({
  type: actionTypes.GET_USER_API_KEYS_SUCCESS,
  payload: {
    keys,
    receivedAt: Date.now(),
  },
});

export const getUserApiKeysFailure = (error) => ({
  type: actionTypes.GET_USER_API_KEYS_FAILURE,
  payload: {
    error,
  },
});

export const getApiKeyRequest = (apiKeyId) => ({
  type: actionTypes.GET_API_KEY_REQUEST,
  payload: {
    apiKeyId,
  },
});

export const getApiKeySuccess = (key) => ({
  type: actionTypes.GET_API_KEY_SUCCESS,
  payload: {
    key,
    receivedAt: Date.now(),
  },
});

export const getApiKeyFailure = (error) => ({
  type: actionTypes.GET_API_KEY_FAILURE,
  payload: {
    error,
  },
});

export const getUserRequest = (username) => ({
  type: actionTypes.GET_USER_REQUEST,
  payload: {
    username,
  },
});

export const getUserSuccess = (user) => ({
  type: actionTypes.GET_USER_SUCCESS,
  payload: {
    user,
    receivedAt: Date.now(),
  },
});

export const getUserFailure = (error) => ({
  type: actionTypes.GET_USER_FAILURE,
  payload: {
    error,
  },
});

export const getUsersRequest = (
  limit,
  filterAttribute,
  filterType,
  filterValue,
  nextToken,
) => ({
  type: actionTypes.GET_USERS_REQUEST,
  payload: {
    limit,
    filterAttribute,
    filterType,
    filterValue,
    nextToken,
  },
});

export const getUsersSuccess = (users, nextToken) => ({
  type: actionTypes.GET_USERS_SUCCESS,
  payload: {
    users,
    nextToken,
    receivedAt: Date.now(),
  },
});

export const getUsersFailure = (error) => ({
  type: actionTypes.GET_USERS_FAILURE,
  payload: {
    error,
  },
});

export const createUserRequest = (
  username,
  name,
  email,
  customerId,
  phoneNumber,
  temporaryPassword,
) => ({
  type: actionTypes.CREATE_USER_REQUEST,
  payload: {
    username,
    name,
    email,
    customerId,
    phoneNumber,
    temporaryPassword,
  },
});

export const createUserSuccess = (user, message) => ({
  type: actionTypes.CREATE_USER_SUCCESS,
  payload: {
    user,
    message,
    receivedAt: Date.now(),
  },
});

export const createUserFailure = (error) => ({
  type: actionTypes.CREATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const updateUserRequest = (username, name, email, customerId) => ({
  type: actionTypes.UPDATE_USER_REQUEST,
  payload: {
    username,
    name,
    email,
    customerId,
  },
});

export const updateUserSuccess = (message) => ({
  type: actionTypes.UPDATE_USER_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const updateUserFailure = (error) => ({
  type: actionTypes.UPDATE_USER_FAILURE,
  payload: {
    error,
  },
});

export const deleteUserRequest = (username) => ({
  type: actionTypes.DELETE_USER_REQUEST,
  payload: {
    username,
  },
});

export const deleteUserSuccess = (message) => ({
  type: actionTypes.DELETE_USER_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const deleteUserFailure = (error) => ({
  type: actionTypes.DELETE_USER_FAILURE,
  payload: {
    error,
  },
});

export const enableUserRequest = (username) => ({
  type: actionTypes.ENABLE_USER_REQUEST,
  payload: {
    username,
  },
});

export const enableUserSuccess = (message) => ({
  type: actionTypes.ENABLE_USER_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const enableUserFailure = (error) => ({
  type: actionTypes.ENABLE_USER_FAILURE,
  payload: {
    error,
  },
});

export const disableUserRequest = (username) => ({
  type: actionTypes.DISABLE_USER_REQUEST,
  payload: {
    username,
  },
});

export const disableUserSuccess = (message) => ({
  type: actionTypes.DISABLE_USER_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const disableUserFailure = (error) => ({
  type: actionTypes.DISABLE_USER_FAILURE,
  payload: {
    error,
  },
});

export const getUsersGroupsRequest = (username) => ({
  type: actionTypes.GET_USERS_GROUPS_REQUEST,
  payload: {
    username,
  },
});

export const getUsersGroupsSuccess = (userGroups, nextToken) => ({
  type: actionTypes.GET_USERS_GROUPS_SUCCESS,
  payload: {
    userGroups,
    nextToken,
    receivedAt: Date.now(),
  },
});

export const getUsersGroupsFailure = (error) => ({
  type: actionTypes.GET_USERS_GROUPS_FAILURE,
  payload: {
    error,
  },
});

export const addUserToGroupRequest = (username, groupName) => ({
  type: actionTypes.ADD_USER_TO_GROUP_REQUEST,
  payload: {
    username,
    groupName,
  },
});

export const addUserToGroupSuccess = (message) => ({
  type: actionTypes.ADD_USER_TO_GROUP_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const addUserToGroupFailure = (error) => ({
  type: actionTypes.ADD_USER_TO_GROUP_FAILURE,
  payload: {
    error,
  },
});

export const removeUserFromGroupRequest = (username, groupName) => ({
  type: actionTypes.REMOVE_USER_FROM_GROUP_REQUEST,
  payload: {
    username,
    groupName,
  },
});

export const removeUserFromGroupSuccess = (message) => ({
  type: actionTypes.REMOVE_USER_FROM_GROUP_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const removeUserFromGroupFailure = (error) => ({
  type: actionTypes.REMOVE_USER_FROM_GROUP_FAILURE,
  payload: {
    error,
  },
});

export const getGroupsRequest = (limit, nextToken) => ({
  type: actionTypes.GET_GROUPS_REQUEST,
  payload: {
    limit,
    nextToken,
  },
});

export const getGroupsSuccess = (groups, nextToken) => ({
  type: actionTypes.GET_GROUPS_SUCCESS,
  payload: {
    groups,
    nextToken,
    receivedAt: Date.now(),
  },
});

export const getGroupsFailure = (error) => ({
  type: actionTypes.GET_GROUPS_FAILURE,
  payload: {
    error,
  },
});

export const getGroupRequest = (groupName) => ({
  type: actionTypes.GET_GROUP_REQUEST,
  payload: {
    groupName,
  },
});

export const getGroupSuccess = (group) => ({
  type: actionTypes.GET_GROUP_SUCCESS,
  payload: {
    group,
    receivedAt: Date.now(),
  },
});

export const getGroupFailure = (error) => ({
  type: actionTypes.GET_GROUP_FAILURE,
  payload: {
    error,
  },
});

export const getGroupUsersRequest = (groupName) => ({
  type: actionTypes.GET_GROUP_USERS_REQUEST,
  payload: {
    groupName,
  },
});

export const getGroupUsersSuccess = (users, nextToken) => ({
  type: actionTypes.GET_GROUP_USERS_SUCCESS,
  payload: {
    users,
    nextToken,
    receivedAt: Date.now(),
  },
});

export const getGroupUsersFailure = (error) => ({
  type: actionTypes.GET_GROUP_USERS_FAILURE,
  payload: {
    error,
  },
});

export const resetUsersPasswordRequest = (username) => ({
  type: actionTypes.RESET_USERS_PASSWORD_REQUEST,
  payload: {
    username,
  },
});

export const resetUsersPasswordSuccess = (message) => ({
  type: actionTypes.RESET_USERS_PASSWORD_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const resetUsersPasswordFailure = (error) => ({
  type: actionTypes.RESET_USERS_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const adminSetUsersPasswordRequest = (username, password) => ({
  type: actionTypes.ADMIN_SET_USERS_PASSWORD_REQUEST,
  payload: {
    username,
    password,
  },
});

export const adminSetUsersPasswordSuccess = (message) => ({
  type: actionTypes.ADMIN_SET_USERS_PASSWORD_SUCCESS,
  payload: {
    message,
    receivedAt: Date.now(),
  },
});

export const adminSetUsersPasswordFailure = (error) => ({
  type: actionTypes.ADMIN_SET_USERS_PASSWORD_FAILURE,
  payload: {
    error,
  },
});

export const getUserPoolDataRequest = (keyName) => ({
  type: actionTypes.GET_USER_POOL_DATA_REQUEST,
  payload: {
    keyName,
  },
});

export const getUserPoolDataSuccess = (data) => ({
  type: actionTypes.GET_USER_POOL_DATA_SUCCESS,
  payload: {
    data,
    receivedAt: Date.now(),
  },
});

export const getUserPoolDataFailure = (error) => ({
  type: actionTypes.GET_USER_POOL_DATA_FAILURE,
  payload: {
    error,
  },
});
