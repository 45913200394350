import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';
import { formatDateTime, toTitleCase } from '../../Common/utils';
import environment from '../../configs/environment';
import GroupUsersListController from '../controllers/GroupUsersListController';
import Placeholder from './common/Placeholder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
}));

const Group = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const {
    group,
    groupStatus,
    getGroup,
    match,
    message: newMessage,
    setSuccessMessage,
    getGroupUsers,
  } = props;
  const { params } = match;
  const { group: groupName } = params;

  useEffect(() => {
    // TODO: Messages with the same content cause a weird issue here.
    if (newMessage !== message) {
      setSuccessMessage(newMessage);
      setMessage(newMessage);
    }

    if (newMessage) {
      if (newMessage.includes('Successfully removed the user')) {
        getGroupUsers(groupName);
        window.location.href = '#users';
      }
    }
  }, [newMessage]);

  useEffect(() => {
    getGroup(groupName);
  }, [groupName]);

  return (
    <GeneralPageHolder>
      <div id="main" />
      <Breadcrumbs path={window.location.pathname} />
      <br />
      {group.length === 0
        ? <ProgressWheel />
        : (
          <>
            <Paper className={classes.root}>
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          component="span"
                          variant="body2"
                          className={classes.inline}
                          color="textPrimary"
                        >
                          Alias
                        </Typography>
                      </>
                    }
                    secondary={environment.group_map[group.name]}
                  />
                </ListItem>
                {Object.keys(group).map((key) => {
                  const listKey = `group${key}`;
                  let value = group[key];

                  if (typeof value === 'boolean') {
                    value = value ? 'Yes' : 'No';
                  }

                  if (key === 'last_modified_date' || key === 'creation_date') {
                    value = formatDateTime(value);
                  }

                  return (
                    <div key={`${listKey}div`}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <>
                              <Typography
                                component="span"
                                variant="body2"
                                className={classes.inline}
                                color="textPrimary"
                              >
                                {toTitleCase(key)}
                              </Typography>
                            </>
                          }
                          secondary={groupStatus.fetching
                            ? <Placeholder />
                            : value}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </div>);
                })}
              </List>
            </Paper>
            <br />
            <div id="users" />
            <Typography variant="h4" color="textPrimary" gutterBottom>
              Users
            </Typography>
            <GroupUsersListController groupName={groupName} />
          </>
        )}
    </GeneralPageHolder>
  );
};

Group.propTypes = {
  match: PropTypes.object.isRequired,
  getGroup: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  groupStatus: PropTypes.object.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  getGroupUsers: PropTypes.func.isRequired,
};

export default withView(null)(Group);
