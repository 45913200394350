import { withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';
import React from 'react';

const styles = () => ({
  close: {
    width: 25,
    height: 25,
  },
});

const MessageSnackbar = ({
  classes,
  variant,
  message,
  onClose,
  autoHideDuration,
}) => (
  <Snackbar
    variant={variant}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    open={message !== undefined && message.length > 0}
    autoHideDuration={autoHideDuration}
    onClose={onClose}
    ContentProps={{
      'aria-describedby': 'message-id',
    }}
    message={<span id="message-id">{message}</span>}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        className={classes.close}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

MessageSnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['success', 'error', 'warning', 'info', '']),
  message: PropTypes.string.isRequired,
  autoHideDuration: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

MessageSnackbar.defaultProps = {
  variant: 'success',
  autoHideDuration: 3000,
};

export default withStyles(styles)(MessageSnackbar);
