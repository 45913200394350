import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  placeholder: {
    display: 'block',
    margin: 0,
    borderRadius: 5,
    height: 20,
    width: 250,
    backgroundColor: theme.palette.grey[200],
  },
}));

const Placeholder = () => {
  const classes = useStyles();
  return <span className={classes.placeholder} />;
};

export default Placeholder;
