import { connect } from 'react-redux';

import {
  setDialogCloseRequest,
  updateApiKeyRequest,
} from '../apis/internalAuthApi/actions';

import ApiKeyEnabler from '../components/ApiKeyEnabler';

const mapDispatchToProps = (dispatch) => ({
  updateApiKeyRequest: (apiKeyId, name, description, enabled) => dispatch(
    updateApiKeyRequest(apiKeyId, name, description, enabled),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  null,
  mapDispatchToProps,
)(ApiKeyEnabler);
