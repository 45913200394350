import { Typography } from '@material-ui/core';
import MaterialBreadcrumbs from '@material-ui/core/Breadcrumbs';
import MaterialLink from '@material-ui/core/Link';
import { withStyles } from '@material-ui/core/styles';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = () => ({
  link: {
    display: 'flex',
  },
  breadcrumbs: {},
});

const getBreadcrumbs = (url) => {
  const pathPieces = url.split('/');

  const pathNames = pathPieces.map((pathName) =>
    pathName.toLowerCase().split('-').map((s) =>
      s.charAt(0).toUpperCase() + s.substring(1)).join(' '));

  return {
    paths: pathPieces,
    names: pathNames,
  };
};

const Breadcrumbs = ({ classes, path }) => {
  const breadcrumbData = getBreadcrumbs(path);

  const breadcrumbs = breadcrumbData.names.map((name, key) => {
    if (name !== '' && breadcrumbData.names.length !== (key + 1)) {
      return (
        <MaterialLink
          color="inherit"
          key={`breadcrumb-${name}`}
          to={breadcrumbData.paths.slice(0, key + 1).join('/')}
          className={classes.link}
          component={Link}
        >
          <Typography variant="caption" display="block">
            {name}
          </Typography>
        </MaterialLink>
      );
    }

    return null;
  });

  return (
    <MaterialBreadcrumbs
      aria-label="breadcrumb"
      separator={<NavigateNextIcon fontSize="small" />}
      className={classes.breadcrumbs}
    >
      {breadcrumbs}
    </MaterialBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
};

export default withStyles(styles)(Breadcrumbs);
