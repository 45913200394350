import * as actionTypes from './actionTypes';

const statuses = {
  fetching: false,
  error: '',
  lastUpdated: 0,
};

const initialState = {
  sharingSecrets: {
    data: {},
    statuses,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SHARING_SECRET_REQUEST:
      return {
        ...state,
        sharingSecrets: {
          ...state.sharingSecrets,
          statuses: {
            ...state.sharingSecrets.statuses, fetching: true,
          },
        },
      };
    case actionTypes.GET_SHARING_SECRET_FAILURE:
      return {
        ...state,
        sharingSecrets: {
          ...state.sharingSecrets,
          statuses: {
            ...state.sharingSecrets.statuses,
            error: action.payload.error,
            fetching: false,
          },
        },
      };
    case actionTypes.GET_SHARING_SECRET_SUCCESS:
      return {
        ...state,
        sharingSecrets: {
          ...state.sharingSecrets,
          data: {
            [action.payload.sharingToken]: action.payload.sharingSecret,
          },
          statuses: {
            ...state.sharingSecrets.statuses,
            fetching: false,
            lastUpdated: action.payload.receivedAt,
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;

export const getSharingSecretFromStore = (state, sharingToken) =>
  sharingToken in state.sharingSecrets.data
    ? state.sharingSecrets.data[sharingToken]
    : '';
export const getSharingSecretStatusesFromStore = (state) =>
  state.sharingSecrets.statuses;
