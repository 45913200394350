import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { SignIn } from 'aws-amplify-react';
import React from 'react';

import AuthHolder from './AuthHolder';

const styles = (theme) => ({
  card: {
    width: 275,
  },
  textField: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  authButton: {
    fontWeight: 'bold',
  },
});

/**
 * @see https://aws-amplify.github.io/media/ui_library
 */
class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
  }

  onSubmit = (event) => {
    event.preventDefault();
  };

  showComponent() {
    const { classes } = this.props;

    return (
      <AuthHolder>
        <Card className={classes.card}>
          <CardHeader title="Login" className={classes.cardHeader} />
          <form onSubmit={this.onSubmit}>
            <CardContent>
              <TextField
                className={classes.textField}
                id="username"
                key="username"
                name="username"
                label="Username"
                onChange={this.handleInputChange}
                type="text"
                placeholder="Username"
              />
              <TextField
                className={classes.textField}
                id="password"
                key="password"
                name="password"
                label="Password"
                onChange={this.handleInputChange}
                type="password"
                placeholder="******************"
              />
            </CardContent>
            <CardActions>
              <Button
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.authButton}
                onClick={() => super.signIn()}
              >
                Submit
              </Button>
            </CardActions>
          </form>
          <CardActions>
            <Typography
              variant="caption"
              align="center"
              display="block"
              gutterBottom
            >
              Forgot your password?{' '}
              <Button
                fullWidth
                size="small"
                color="secondary"
                className={classes.margin}
                onClick={() => super.changeState('forgotPassword')}
              >
                Reset Password
              </Button>
            </Typography>
          </CardActions>
          {this.state.loading ? <LinearProgress color="primary" /> : null}
        </Card>
      </AuthHolder>
    );
  }
}

export default withStyles(styles)(CustomSignIn);
