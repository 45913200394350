import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CommonTable from './common/CommonTable';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
  },
}));

const AddUsagePlanToApiKey = ({
  addUsagePlanToApiKey,
  nextToken,
  usagePlans,
  getUsagePlans,
  apiKeyUsagePlans,
  apiKeyId,
  setDialogClose,
}) => {
  const classes = useStyles();
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState({});
  const allPlans = usagePlans.map((x) => x.name);
  const includedPlans = apiKeyUsagePlans.map((x) => x.name);
  const diff = allPlans.filter((x) => !includedPlans.includes(x));
  const difference = usagePlans.filter(
    (usagePlan) => diff.includes(usagePlan.name),
  );

  const handleAdd = (usagePlanId) => {
    addUsagePlanToApiKey(usagePlanId, apiKeyId);
    setDialogClose();
  };

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (usagePlans.length !== 0) {
      setRows(buildRows(difference));
    } else {
      setRows(buildRows([]));
    }
  }, [usagePlans]);

  const paginationCallback = (limit, page) => {
    getUsagePlans(null, limit, paginationTokens[page], null);
  };

  const tableHeaders = [
    'Name',
    'Description',
    'Plan Default Request Limit',
    'Default Rate Limit',
    'Default Burst Limit',
    'Action',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        id,
        name,
        description,
        quota,
        throttle,
      } = rawData[key];
      newObject[name] = [
        name,
        description,
        quota.limit ? `${quota.limit} per ${quota.period}` : '',
        throttle.rateLimit,
        throttle.burstLimit,
        <Button
          startIcon={<AddIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleAdd(id)}
        >
          Add
        </Button>,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

AddUsagePlanToApiKey.propTypes = {
  nextToken: PropTypes.string,
  getUsagePlans: PropTypes.func.isRequired,
  usagePlans: PropTypes.array.isRequired,
  apiKeyUsagePlans: PropTypes.array.isRequired,
  addUsagePlanToApiKey: PropTypes.func.isRequired,
  apiKeyId: PropTypes.string.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

AddUsagePlanToApiKey.defaultProps = {
  nextToken: '',
};

export default AddUsagePlanToApiKey;
