import { Typography } from '@material-ui/core';
import React from 'react';

const Copyright = () => (
  <Typography variant="caption">
    © {new Date().getFullYear()} Talroo, Inc.
  </Typography>
);

export default Copyright;
