import { Authenticator } from 'aws-amplify-react';

class CustomAuthenticator extends Authenticator {
  handleAuthEvent(state, event, showToast = true) {
    if (event.type === 'error') {
      this.setState({
        error: this.rewriteErrorMessages(event.data),
        showToast,
      });
    }
  }

  rewriteErrorMessages(message) {
    let newMessage = '';
    switch (message) {
      case 'User is disabled.':
        newMessage = 'User is disabled. Please contact your CSA.';
        break;
      case 'User does not exist.':
        newMessage = 'Incorrect username or password\n';
        break;
      default:
        newMessage = message;
    }

    return newMessage;
  }
}

export default CustomAuthenticator;
