import * as actionTypes from './actionTypes';

export function setSuccessMessage(message) {
  return {
    type: actionTypes.SET_SUCCESS_MESSAGE,
    message,
  };
}

export function clearSuccessMessage() {
  return {
    type: actionTypes.CLEAR_SUCCESS_MESSAGE,
  };
}

export function setErrorMessage(error) {
  const { message } = error;
  let newMessage = 'Server Error';
  if (typeof error === 'string') {
    newMessage = error;
  } else if (error && message) {
    newMessage = message;
  }

  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    message: newMessage,
  };
}

export function clearErrorMessage() {
  return {
    type: actionTypes.CLEAR_ERROR_MESSAGE,
  };
}
