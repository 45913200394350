const initialState = {
  sideMenu: {
    isOpen: false,
    panels: {
      panel0: true,
    },
  },
};

const sideMenu = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_SIDE_MENU_STATE':
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          isOpen: action.isOpen,
        },
      };
    case 'UPDATE_SIDE_MENU_PANELS':
      return {
        ...state,
        sideMenu: {
          ...state.sideMenu,
          panels: {
            // TODO: Make this better. -_-
            panel0: true,
            [action.panelName]: action.panelOpen,
          },
        },
      };
    default:
      return state;
  }
};

export default sideMenu;

export const getSideMenuState = (state) => (state.sideMenu.isOpen);
export const getSideMenuPanels = (state) => (state.sideMenu.panels);
