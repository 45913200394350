import { call, put, takeLatest } from 'redux-saga/effects';
import {
  addRequestsFailure,
  addRequestsSuccess,
  addUsagePlanToApiKeyFailure,
  addUsagePlanToApiKeySuccess,
  addUserToGroupFailure,
  addUserToGroupSuccess,
  adminSetUsersPasswordFailure,
  adminSetUsersPasswordSuccess,
  createApiKeyFailure,
  createApiKeySuccess,
  createUserFailure,
  createUserSuccess,
  deleteUserFailure,
  deleteUserSuccess,
  disableUserFailure,
  disableUserSuccess,
  enableUserFailure,
  enableUserSuccess,
  getApiKeyFailure,
  getApiKeysFailure,
  getApiKeysSuccess,
  getApiKeySuccess,
  getApiKeyUsagePlanFailure,
  getApiKeyUsagePlansFailure,
  getApiKeyUsagePlansSuccess,
  getApiKeyUsagePlanSuccess,
  getGroupFailure,
  getGroupsFailure,
  getGroupsSuccess,
  getGroupSuccess,
  getGroupUsersFailure,
  getGroupUsersSuccess,
  getPlanFailure,
  getPlanSuccess,
  getUsagePlanApiKeysFailure,
  getUsagePlanApiKeysSuccess,
  getUsagePlanFailure,
  getUsagePlansFailure,
  getUsagePlansSuccess,
  getUsagePlanSuccess,
  getUserApiKeysFailure,
  getUserApiKeysSuccess,
  getUserFailure,
  getUserPoolDataFailure,
  getUserPoolDataSuccess,
  getUsersFailure,
  getUsersGroupsFailure,
  getUsersGroupsSuccess,
  getUsersSuccess,
  getUserSuccess,
  removeUsagePlanFromApiKeyFailure,
  removeUsagePlanFromApiKeySuccess,
  removeUserFromGroupFailure,
  removeUserFromGroupSuccess,
  resetUsersPasswordFailure,
  resetUsersPasswordSuccess,
  updateApiKeyFailure,
  updateApiKeySuccess,
  updateUserFailure,
  updateUserSuccess,
} from './actions';

import {
  ADD_REQUESTS_REQUEST,
  ADD_USAGE_PLAN_TO_API_KEY_REQUEST,
  ADD_USER_TO_GROUP_REQUEST,
  ADMIN_SET_USERS_PASSWORD_REQUEST,
  CREATE_API_KEY_REQUEST,
  CREATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  DISABLE_USER_REQUEST,
  ENABLE_USER_REQUEST,
  GET_API_KEY_REQUEST,
  GET_API_KEY_USAGE_PLAN_REQUEST,
  GET_API_KEY_USAGE_PLANS_REQUEST,
  GET_API_KEYS_REQUEST,
  GET_GROUP_REQUEST,
  GET_GROUP_USERS_REQUEST,
  GET_GROUPS_REQUEST,
  GET_PLAN_REQUEST,
  GET_USAGE_PLAN_API_KEYS_REQUEST,
  GET_USAGE_PLAN_REQUEST,
  GET_USAGE_PLANS_REQUEST,
  GET_USER_API_KEYS_REQUEST,
  GET_USER_POOL_DATA_REQUEST,
  GET_USER_REQUEST,
  GET_USERS_GROUPS_REQUEST,
  GET_USERS_REQUEST,
  REMOVE_USAGE_PLAN_FROM_API_KEY_REQUEST,
  REMOVE_USER_FROM_GROUP_REQUEST,
  RESET_USERS_PASSWORD_REQUEST,
  UPDATE_API_KEY_REQUEST,
  UPDATE_USER_REQUEST,
} from './actionTypes';

import api from './api';

function* internalAuthApiSaga() {
  yield takeLatest(GET_USER_API_KEYS_REQUEST, getUserApiKeysRequest);
  yield takeLatest(GET_API_KEYS_REQUEST, getApiKeysRequest);
  yield takeLatest(GET_API_KEY_REQUEST, getApiKeyRequest);
  yield takeLatest(CREATE_API_KEY_REQUEST, createApiKeyRequest);
  yield takeLatest(GET_USER_REQUEST, getUserRequest);
  yield takeLatest(GET_USERS_REQUEST, getUsersRequest);
  yield takeLatest(CREATE_USER_REQUEST, createUserRequest);
  yield takeLatest(UPDATE_USER_REQUEST, updateUserRequest);
  yield takeLatest(DELETE_USER_REQUEST, deleteUserRequest);
  yield takeLatest(ENABLE_USER_REQUEST, enableUserRequest);
  yield takeLatest(DISABLE_USER_REQUEST, disableUserRequest);
  yield takeLatest(GET_USERS_GROUPS_REQUEST, getUsersGroupsRequest);
  yield takeLatest(ADD_USER_TO_GROUP_REQUEST, addUserToGroupRequest);
  yield takeLatest(REMOVE_USER_FROM_GROUP_REQUEST,
    removeUserFromGroupRequest);
  yield takeLatest(GET_GROUPS_REQUEST, getGroupsRequest);
  yield takeLatest(GET_GROUP_REQUEST, getGroupRequest);
  yield takeLatest(GET_GROUP_USERS_REQUEST, getGroupUsersRequest);
  yield takeLatest(RESET_USERS_PASSWORD_REQUEST, resetUsersPasswordRequest);
  yield takeLatest(ADMIN_SET_USERS_PASSWORD_REQUEST,
    adminSetUsersPasswordRequest);
  yield takeLatest(GET_USER_POOL_DATA_REQUEST, getUserPoolDataRequest);
  yield takeLatest(GET_USAGE_PLANS_REQUEST, getUsagePlansRequest);
  yield takeLatest(GET_USAGE_PLAN_REQUEST, getUsagePlanRequest);
  yield takeLatest(GET_API_KEY_USAGE_PLANS_REQUEST, getApiKeyUsagePlansRequest);
  yield takeLatest(ADD_USAGE_PLAN_TO_API_KEY_REQUEST, addUsagePlanToKeyRequest);
  yield takeLatest(UPDATE_API_KEY_REQUEST, updateApiKeyRequest);
  yield takeLatest(REMOVE_USAGE_PLAN_FROM_API_KEY_REQUEST,
    removeUsagePlanFromApiKeyRequest);
  yield takeLatest(GET_API_KEY_USAGE_PLAN_REQUEST, getKeyUsagePlanRequest);
  yield takeLatest(GET_PLAN_REQUEST, getPlanRequest);
  yield takeLatest(ADD_REQUESTS_REQUEST, addRequestsRequest);
  yield takeLatest(GET_USAGE_PLAN_API_KEYS_REQUEST, getPlanKeysRequest);
}

function* getPlanKeysRequest(action) {
  try {
    const response = yield call(
      api.getPlanKeys,
      action.payload.planId,
      null,
      action.payload.limit,
      action.payload.position,
      action.payload.nameQuery,
    );
    const keys = response.usage_plan_keys;
    yield put(getUsagePlanApiKeysSuccess(keys));
  } catch (error) {
    yield put(getUsagePlanApiKeysFailure(error));
  }
}

function* addRequestsRequest(action) {
  try {
    yield call(
      api.addRequests,
      action.payload.planId,
      action.payload.apiKeyId,
      action.payload.remaining,
    );
    const message = 'Successfully Updated Request Limit';
    yield put(addRequestsSuccess(message));
  } catch (error) {
    yield put(addRequestsFailure(error));
  }
}

function* getPlanRequest(action) {
  try {
    const response = yield call(
      api.getPlan,
      action.payload.planId,
      action.payload.limit,
      action.payload.position,
      action.payload.apiKeyId,
    );
    const limit = response.usage_plans[0].quota;
    const { throttle } = response.usage_plans[0];
    yield put(getPlanSuccess(limit, throttle));
  } catch (error) {
    yield put(getPlanFailure(error));
  }
}

function* getKeyUsagePlanRequest(action) {
  try {
    const response = yield call(
      api.getKeyUsagePlan,
      action.payload.planId,
      action.payload.apiKeyId,
      action.payload.limit,
      action.payload.position,
      action.payload.startDate,
      action.payload.endDate,
    );
    const dailyUsage = response.usage_plan_usage[0].usage_by_api_key_id[0].usage_by_day;
    yield put(getApiKeyUsagePlanSuccess(dailyUsage));
  } catch (error) {
    yield put(getApiKeyUsagePlanFailure(error));
  }
}

function* removeUsagePlanFromApiKeyRequest(action) {
  try {
    const response = yield call(
      api.removeUsagePlanFromApiKey,
      action.payload.apiKeyId,
      action.payload.planId,
    );
    yield put(removeUsagePlanFromApiKeySuccess(response.message));
  } catch (error) {
    yield put(removeUsagePlanFromApiKeyFailure(error.message));
  }
}

function* updateApiKeyRequest(action) {
  try {
    yield call(
      api.updateApiKey,
      action.payload.apiKeyId,
      action.payload.name,
      action.payload.description,
      action.payload.enabled,
    );
    yield put(updateApiKeySuccess('Successfully Updated API Key!'));
  } catch (error) {
    yield put(updateApiKeyFailure(error));
  }
}

function* addUsagePlanToKeyRequest(action) {
  try {
    yield call(
      api.addUsagePlanToApiKey,
      action.payload.planId,
      action.payload.apiKeyId,
    );
    yield put(addUsagePlanToApiKeySuccess('Successfully Added Usage Plan!'));
  } catch (error) {
    yield put(addUsagePlanToApiKeyFailure(error.message));
  }
}

function* getApiKeyUsagePlansRequest(action) {
  const response = yield call(
    api.getUsagePlans,
    null,
    null,
    null,
    action.payload.apiKeyId,
  );
  try {
    const usagePlans = response.usage_plans;
    yield put(getApiKeyUsagePlansSuccess(usagePlans));
  } catch (error) {
    yield put(getApiKeyUsagePlansFailure(error));
  }
}

function* getUsagePlansRequest(action) {
  const response = yield call(
    api.getUsagePlans,
    action.payload.planId,
    action.payload.limit,
    action.payload.position,
    action.payload.apiKeyId,
  );
  try {
    const { usage_plans: usagePlans, position } = response;
    yield put(getUsagePlansSuccess(
      usagePlans,
      position ? decodeURIComponent(position) : null,
    ));
  } catch (error) {
    yield put(getUsagePlansFailure(error));
  }
}

function* getUsagePlanRequest(action) {
  const response = yield call(
    api.getUsagePlan,
    action.payload.planId,
    action.payload.limit,
    action.payload.position,
    action.payload.apiKeyId,
  );
  try {
    const plan = response.usage_plans[0];
    yield put(getUsagePlanSuccess(plan));
  } catch (error) {
    yield put(getUsagePlanFailure(error));
  }
}

function* createApiKeyRequest(action) {
  const response = yield call(
    api.createApiKey,
    action.payload.keyName,
    action.payload.description,
    action.payload.enabled,
    action.payload.tags,
  );
  try {
    const newKey = response.api_keys[0].id;
    const message = `Successfully created key: ${newKey}`;
    yield put(createApiKeySuccess(newKey, message));
  } catch (error) {
    yield put(createApiKeyFailure(error));
  }
}

function* getApiKeysRequest(action) {
  const response = yield call(
    api.getApiKeys,
    action.payload.apiKeyId,
    action.payload.limit,
    action.payload.position,
    action.payload.nameQuery,
    action.payload.includeValue,
  );
  try {
    const keys = response.api_keys;
    const { position } = response;
    yield put(getApiKeysSuccess(keys, position));
  } catch (error) {
    yield put(getApiKeysFailure(error));
  }
}

function* getApiKeyRequest(action) {
  const response = yield call(api.getApiKey, action.payload.apiKeyId);
  try {
    const apiKey = response.api_keys[0];
    yield put(getApiKeySuccess(apiKey));
  } catch (error) {
    yield put(getApiKeyFailure(error));
  }
}

function* getUserApiKeysRequest(action) {
  const response = yield call(api.getUserApiKeys, action.payload.nameQuery);
  try {
    const keys = response.api_keys;
    yield put(getUserApiKeysSuccess(keys));
  } catch (error) {
    yield put(getUserApiKeysFailure(error));
  }
}

function* getUserRequest(action) {
  try {
    const response = yield call(api.getUser, action.payload.username);
    const user = response.users[0];
    yield put(getUserSuccess(user));
  } catch (error) {
    yield put(getUserFailure(error));
  }
}

function* getUsersRequest(action) {
  try {
    const response = yield call(
      api.getUsers,
      action.payload.limit,
      action.payload.filterAttribute,
      action.payload.filterType,
      action.payload.filterValue,
      action.payload.nextToken,
    );
    yield put(getUsersSuccess(response.users, response.next_token));
  } catch (error) {
    yield put(getUsersFailure('error'));
  }
}

function* updateUserRequest(action) {
  try {
    const response = yield call(
      api.updateUser,
      action.payload.username,
      action.payload.name,
      action.payload.email,
      action.payload.customerId,
    );
    yield put(updateUserSuccess(response.message));
  } catch (error) {
    yield put(updateUserFailure(error.message));
  }
}

function* deleteUserRequest(action) {
  try {
    const response = yield call(api.deleteUser, action.payload.username);
    const user = response.users[0];
    yield put(deleteUserSuccess(user));
  } catch (error) {
    yield put(deleteUserFailure(error));
  }
}

function* createUserRequest(action) {
  try {
    const response = yield call(
      api.createUser,
      action.payload.username,
      action.payload.name,
      action.payload.email,
      action.payload.customerId,
      action.payload.phoneNumber,
      action.payload.temporaryPassword,
    );
    const user = response.users[0].username;
    const message = `Successfully Created ${user}`;
    yield put(createUserSuccess(user, message));
  } catch (error) {
    yield put(createUserFailure(error.message));
  }
}

function* disableUserRequest(action) {
  try {
    const response = yield call(api.disableUser, action.payload.username);
    yield put(disableUserSuccess(response.message));
  } catch (error) {
    yield put(disableUserFailure(error));
  }
}

function* enableUserRequest(action) {
  try {
    const response = yield call(api.enableUser, action.payload.username);
    yield put(enableUserSuccess(response.message));
  } catch (error) {
    yield put(enableUserFailure(error));
  }
}

function* addUserToGroupRequest(action) {
  try {
    const response = yield call(
      api.addUserToGroup,
      action.payload.username,
      action.payload.groupName,
    );
    const { message } = response;
    yield put(addUserToGroupSuccess(message));
  } catch (error) {
    yield put(addUserToGroupFailure(error));
  }
}

function* removeUserFromGroupRequest(action) {
  try {
    const response = yield call(
      api.removeUserFromGroup,
      action.payload.username,
      action.payload.groupName,
    );
    const { message } = response;
    yield put(removeUserFromGroupSuccess(message));
  } catch (error) {
    yield put(removeUserFromGroupFailure(error));
  }
}

function* getUsersGroupsRequest(action) {
  try {
    // NOTE: This is a lazy fix since I'm out of time.
    // We eventually want to respect the limit give in the payload.
    const response = yield call(
      api.getUsersGroups,
      action.payload.username,
      60,
    );
    const { groups } = response;
    yield put(getUsersGroupsSuccess(groups));
  } catch (error) {
    yield put(getUsersGroupsFailure(error));
  }
}

function* getGroupsRequest(action) {
  try {
    const response = yield call(
      api.getGroups,
      action.payload.limit,
      action.payload.nextToken,
    );
    let nextToken = '';
    if ('next_token' in response) {
      nextToken = response.next_token;
    }
    yield put(getGroupsSuccess(response.groups, nextToken));
  } catch (error) {
    yield put(getGroupsFailure(error));
  }
}

function* getGroupRequest(action) {
  try {
    const response = yield call(api.getGroup, action.payload.groupName);
    yield put(getGroupSuccess(response.groups[0]));
  } catch (error) {
    yield put(getGroupFailure(error));
  }
}

function* getGroupUsersRequest(action) {
  try {
    const response = yield call(api.getGroupUsers, action.payload.groupName);
    const { users } = response;
    yield put(getGroupUsersSuccess(users));
  } catch (error) {
    yield put(getGroupUsersFailure(error));
  }
}

function* resetUsersPasswordRequest(action) {
  try {
    yield call(api.resetUsersPassword, action.payload.username);
    const message = 'Successfully Reset Password';
    yield put(resetUsersPasswordSuccess(message));
  } catch (error) {
    yield put(resetUsersPasswordFailure(error));
  }
}

function* adminSetUsersPasswordRequest(action) {
  try {
    yield call(
      api.adminSetUsersPassword,
      action.payload.username,
      action.payload.password,
    );
    const message = `Successfully set password to ${action.payload.password}`;
    yield put(adminSetUsersPasswordSuccess(message));
  } catch (error) {
    yield put(adminSetUsersPasswordFailure(error));
  }
}

function* getUserPoolDataRequest(action) {
  try {
    const response = yield call(api.getUserPoolData, action.payload.keyName);
    yield put(getUserPoolDataSuccess(response.data));
  } catch (error) {
    yield put(getUserPoolDataFailure(error));
  }
}

export default internalAuthApiSaga;
