import { connect } from 'react-redux';

import {
  getDialogStatusFromStore,
  getMessageFromStore,
  getMonthlyUsageDataFromStore,
  getUsageDataFromStore,
} from '../../shared/reducers';

import {
  addRequestsRequest,
  clearMessageRequest,
  getApiKeyUsagePlanRequest,
  getPlanRequest,
} from '../apis/internalAuthApi/actions';

import KeyUsagePlan from '../components/ApiKeyUsagePlan';

const mapStateToProps = (state) => ({
  usageData: getUsageDataFromStore(state),
  monthlyUsageData: getMonthlyUsageDataFromStore(state),
  dialogStatus: getDialogStatusFromStore(state),
  message: getMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyUsagePlan: (
    planId,
    apiKeyId,
    limit,
    position,
    startDate,
    endDate,
  ) => dispatch(
    getApiKeyUsagePlanRequest(
      planId,
      apiKeyId,
      limit,
      position,
      startDate,
      endDate,
    ),
  ),
  getPlan: (planId, limit, position, apiKeyId) => dispatch(
    getPlanRequest(planId, limit, position, apiKeyId),
  ),
  addRequests: (planId, apiKeyId, remaining) => dispatch(
    addRequestsRequest(planId, apiKeyId, remaining),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(KeyUsagePlan);
