import { connect } from 'react-redux';
import {
  getGroupFromStore,
  getGroupStatusesFromStore,
  getMessageFromStore,
  getUsersFromStore,
  getUsersStatusesFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  getGroupRequest,
  getGroupUsersRequest,
  removeUserFromGroupRequest,
} from '../apis/internalAuthApi/actions';
import Group from '../components/Group';

const mapStateToProps = (state) => ({
  group: getGroupFromStore(state),
  users: getUsersFromStore(state),
  message: getMessageFromStore(state),
  groupStatus: getGroupStatusesFromStore(state),
  usersStatus: getUsersStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getGroup: (groupName) => dispatch(getGroupRequest(groupName)),
  getGroupUsers: (groupName) => dispatch(getGroupUsersRequest(groupName)),
  removeUserFromGroup: (username, groupName) => dispatch(
    removeUserFromGroupRequest(username, groupName),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Group);
