import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  dialog: {
    textAlign: 'center',
    minWidth: 350,
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  button: {
    textTransform: 'none',
    margin: theme.spacing(1),
  },
}));

function SimpleDialog({
  dialogHeader,
  buttonIcon,
  buttonTitle,
  disabled = false,
  component,
  dialogStatus,
  setDialogOpen,
  setDialogClose,
  id,
}) {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setDialogOpen(id);
  };

  const handleClose = () => {
    setOpen(false);
    setDialogClose();
  };

  const handleDecline = () => {
    setOpen(false);
    setDialogClose();
  };

  return (
    <>
      <Button
        startIcon={buttonIcon}
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
        className={classes.button}
        disabled={disabled}
      >
        {buttonTitle}
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={dialogStatus.id === id ? dialogStatus.open : false}
        maxWidth="md"
      >
        <DialogTitle
          id={id}
          disableTypography
          className={classes.dialogTitle}
        >
          <Typography variant="h5" color="textPrimary">
            {dialogHeader}
          </Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleDecline}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialog}>
          {component}
        </DialogContent>
      </Dialog>
    </>
  );
}

SimpleDialog.propTypes = {
  dialogHeader: PropTypes.string.isRequired,
  buttonIcon: PropTypes.object,
  buttonTitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  component: PropTypes.object.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
  dialogStatus: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

SimpleDialog.defaultProps = {
  buttonIcon: null,
};

export default SimpleDialog;
