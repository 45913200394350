import { connect } from 'react-redux';

import {
  removeUsagePlanFromApiKeyRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import RemovePlanFromApiKey from '../components/RemovePlanFromApiKey';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  removeUsagePlanFromApiKey: (apiKeyId, planId) => dispatch(
    removeUsagePlanFromApiKeyRequest(apiKeyId, planId),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemovePlanFromApiKey);
