import { connect } from 'react-redux';
import environmentData from '../../configs/environment';
import variantData from '../../configs/variant';
import { getSharingSecretFromStore } from '../../shared/reducers';
import { getSharingSecretRequest } from '../apis/insightsApi/actions';
import CluvioDashboard from '../components/CluvioDashboard';

const mapStateToProps = (state, ownProps) => {
  const { dashboardDetails } = ownProps;
  return {
    cluvioDashboards: variantData['cluvio-dashboards'],
    cluvioDomain: environmentData.cluvio_domain,
    sharingSecret: getSharingSecretFromStore(
      state,
      dashboardDetails.sharing_token,
    ),
  };
};

const getCluvioIframeDashboard = (
  cluvioDomain,
  dashboardId,
  sharingToken,
  sharingSecret,
  filters,
  timerange,
  darkModeEnabled,
) => `${cluvioDomain}/dashboards/${dashboardId}/shared?`
  + `filters=${filters}`
  + `&timerange=${timerange}`
  + `&sharingToken=${sharingToken}`
  + `&sharingSecret=${sharingSecret}`
  + `&darkMode=${darkModeEnabled}`
  + '&enableDrillEvents=true';

const getDashboardDataByDashboardId = (dashboardId) => {
  let dashboardPath = null;
  const cluvioDashboards = variantData['cluvio-dashboards'];

  Object.keys(cluvioDashboards).forEach((dashboard) => {
    if (cluvioDashboards[dashboard].dashboard_id === dashboardId) {
      dashboardPath = dashboard;
    }
  });

  return dashboardPath;
};

const filterArrayToObject = (filters) => filters.reduce((obj, item) => {
  // We exclude `insights_customer_id` here since it gets passed in during the
  // sharing secret generation.
  // We also want to avoid sending through filters that have `null` values.
  const { filterVariable, value } = item;
  return (filterVariable !== 'insights_customer_id' && value !== null)
    ? { ...obj, [filterVariable]: value }
    : obj;
}, {});

const filterArrayToObjectFromUpdate = (filters) => filters.reduce(
  (obj, item) => {
    // We exclude `insights_customer_id` here since it gets passed in during the
    // sharing secret generation.
    // We also want to avoid sending through filters that have `null` values.
    const { filter_name: filterName, selected_values: values } = item;
    return (filterName !== 'insights_customer_id' && values[0] !== null)
      ? { ...obj, [filterName]: values[0] }
      : obj;
  },
  {},
);

const queryParametersToObject = (queryParameters) => JSON.parse(
  `{"${queryParameters.replace(/&/g, '","').replace(/=/g, '":"')}"}`,
  (key, value) => (key === '' ? value : decodeURIComponent(value)),
);

const rawFiltersToObject = (rawFilterObject) => {
  const newObject = {};
  Object.keys(rawFilterObject).forEach((element) => {
    // Exclude `timerange` from this object transformation.
    // It will be added as its own query parameter later.
    // We also want to avoid sending through filters that have `null` values.
    if (element !== 'timerange' && rawFilterObject[element] !== 'null') {
      newObject[element] = [rawFilterObject[element]];
    }
  });
  return newObject;
};

const mapDispatchToProps = (dispatch) => ({
  getCluvioIframeDashboard: (
    cluvioDomain,
    dashboardId,
    sharingToken,
    sharingSecret,
    filters,
    timerange,
    darkModeEnabled,
  ) => getCluvioIframeDashboard(
    cluvioDomain,
    dashboardId,
    sharingToken,
    sharingSecret,
    filters,
    timerange,
    darkModeEnabled,
  ),
  getDashboardDataByDashboardId: (dashboardId) =>
    getDashboardDataByDashboardId(dashboardId),
  filterArrayToObject: (filters) => filterArrayToObject(filters),
  filterArrayToObjectFromUpdate: (filters) =>
    filterArrayToObjectFromUpdate(filters),
  queryParametersToObject: (queryParameters) =>
    queryParametersToObject(queryParameters),
  rawFiltersToObject: (rawFilterObject) => rawFiltersToObject(rawFilterObject),
  getSharingSecret: (sharingToken) =>
    dispatch(getSharingSecretRequest(sharingToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CluvioDashboard);
