import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const ApiKeyEnabler = ({ apiKey, updateApiKeyRequest, setDialogClose }) => {
  const handleEnable = () => {
    updateApiKeyRequest(
      apiKey.id,
      apiKey.name,
      apiKey.description,
      'True',
    );
    setDialogClose();
  };

  const handleDisable = () => {
    updateApiKeyRequest(
      apiKey.id,
      apiKey.name,
      apiKey.description,
      'False',
    );
    setDialogClose();
  };

  return (
    apiKey.enabled === true
      ? <YesNoButtons yesAction={handleDisable} noAction={setDialogClose} />
      : <YesNoButtons yesAction={handleEnable} noAction={setDialogClose} />
  );
};

ApiKeyEnabler.propTypes = {
  apiKey: PropTypes.object.isRequired,
  updateApiKeyRequest: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default ApiKeyEnabler;
