import { connect } from 'react-redux';
import {
  getMessageFromStore,
  getUsagePlanApiKeysFromStore,
  getUsagePlanApiKeysStatusesFromStore,
  getUsagePlanFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  getUsagePlanApiKeysRequest,
  getUsagePlanRequest,
} from '../apis/internalAuthApi/actions';

import UsagePlan from '../components/UsagePlan';

const mapStateToProps = (state) => ({
  usagePlan: getUsagePlanFromStore(state),
  planKeys: getUsagePlanApiKeysFromStore(state),
  planKeyStatus: getUsagePlanApiKeysStatusesFromStore(state),
  message: getMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsagePlan: (planId, limit, position, apiKeyId) => dispatch(
    getUsagePlanRequest(planId, limit, position, apiKeyId),
  ),
  getPlanKeys: (planId, apiKeyId, limit, position, nameQuery) => dispatch(
    getUsagePlanApiKeysRequest(planId, null, limit, position, nameQuery),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsagePlan);
