import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TablePaginationActions from './TablePaginationActions';

const useStyles = makeStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const CommonTable = ({
  columnHeaders,
  rows,
  paginationTokens,
  paginationCallback,
}) => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [count, setCount] = useState(0);
  const [foundMaxCount, setFoundMaxCount] = useState(false);

  useEffect(() => {
    // If `count` is `0` then fire the initial call to get Groups.
    if (count === 0) {
      paginationCallback(rowsPerPage, page);
      setCount(rowsPerPage);
    } else if (!foundMaxCount) {
      const rowLength = Object.keys(rows).length;
      if (paginationTokens.length >= 1 && (paginationTokens[page + 1]
        || !rowLength)) {
        // Estimate the next page count to allow pagination.
        // Ex. (0 + 2) * 10 = 20 items
        const newRowCount = (page + 2) * rowsPerPage;
        // Only increment the row count, do not decrement.
        if (newRowCount > count) {
          setCount(newRowCount);
        }
      } else {
        // If the number of `groups` returned is less than the page limit or
        // there is no pagination token returned update the count to the
        // actual max value.
        let newCount = count;

        // If we are still on page `0` and the length of the `groups` returned
        // is less than the `rowsPerPage` set the count to the group length
        // since we do not have enough entries to meet the initial page length.
        if (page === 0 && rowLength < rowsPerPage) {
          newCount = rowLength;
        } else {
          newCount = ((paginationTokens.length - 1) * rowsPerPage)
            + rowLength;
        }

        setCount(newCount);
        setFoundMaxCount(true);
      }
    }
  }, [rows, paginationTokens]);

  const handleChangePage = (event, newPage) => {
    paginationCallback(rowsPerPage, newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    const newPage = 0;
    paginationCallback(newRowsPerPage, newPage);
    // eslint-disable-next-line radix
    setRowsPerPage(parseInt(newRowsPerPage));
    setPage(newPage);
  };

  return (
    <Table>
      <TableHead>
        <TableRow>
          {columnHeaders.map(
            (columnName) =>
              <TableCell
                key={`${columnName}Th`}
                className={classes.head}
              >
                {columnName}
              </TableCell>,
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.keys(rows).map(
          (id) => (
            <TableRow key={`${id}TR`}>
              {rows[id].map(
                (cell, index) =>
                  // eslint-disable-next-line react/no-array-index-key
                  <TableCell key={`${id}TR${index}TC`}>{cell}</TableCell>,
              )}
            </TableRow>
          ),
        )}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            colSpan={columnHeaders.length}
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
};

CommonTable.propTypes = {
  paginationTokens: PropTypes.array.isRequired,
  columnHeaders: PropTypes.array.isRequired,
  rows: PropTypes.object.isRequired,
  paginationCallback: PropTypes.func.isRequired,
};

export default CommonTable;
