import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import withView from '../../Common/hocs/withView';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  formField: {
    marginBottom: theme.spacing(1),
  },
}));

const AddUser = ({ createUser, message, setSuccessMessage, clearMessage }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    username: '',
    name: '',
    email: '',
    customerId: '',
    phone: '',
    password: '',
    message: '',
  });
  const {
    username,
    name,
    email,
    customerId,
    phone,
    password,
  } = state;

  const handleChange = (event) => {
    const { target } = event;
    const { value, name: targetName } = target;
    setState({ ...state, [targetName]: value });
  };

  // TODO: Check on this.
  useEffect(() => {
    setSuccessMessage(message);
    if (message !== undefined) {
      if (message.includes('Successfully Created')) {
        // TODO: Replace this with a Redirect?
        window.location.replace(`../user-management/users/${username}`);
        clearMessage();
      }
    }
  }, [message]);

  const handleAddUser = () => {
    createUser(username, name, email, customerId, phone, password);
  };

  const formData = [
    {
      name: 'username',
      label: 'Username',
      value: username,
    },
    {
      name: 'name',
      label: 'Full Name',
      value: name,
    },
    {
      name: 'email',
      label: 'Email',
      value: email,
    },
    {
      name: 'customerId',
      label: 'Customer ID',
      value: customerId,
    },
    {
      name: 'phone',
      label: 'Phone Number',
      value: phone,
    },
    {
      name: 'password',
      label: 'Temporary Password',
      value: password,
    },
  ];

  return (
    <GeneralPageHolder>
      <Breadcrumbs path={window.location.pathname} />
      <br />
      <Typography variant="h5">
        Add User
      </Typography>
      <br />
      <Card className={classes.root}>
        <CardContent>
          <form>
            {formData.map((formPiece) => (
              <TextField
                fullWidth
                key={formPiece.name}
                className={classes.formField}
                variant="outlined"
                name={formPiece.name}
                label={formPiece.label}
                onChange={handleChange}
                value={formPiece.value}
              />
            ))}
          </form>
          <Button
            fullWidth
            size="large"
            variant="contained"
            color="primary"
            onClick={handleAddUser}
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </GeneralPageHolder>
  );
};

AddUser.propTypes = {
  createUser: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default withView(null)(AddUser);
