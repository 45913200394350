import getCommonAccessGroups from '../Common/accessGroups';
import getInsightsAccessGroups from '../Insights/accessGroups';
import getUserManagementAccessGroups from '../UserManagement/accessGroups';
import getPopManagementAccessGroups from '../PopManagement/accessGroups';

const getAllAccessGroups = (userData) => {
  const commonAccessGroups = getCommonAccessGroups(userData);
  const insightsAccessGroups = getInsightsAccessGroups(userData);
  const userManagementAccessGroups = getUserManagementAccessGroups(userData);
  const popManagementAccessGroups = getPopManagementAccessGroups(userData);

  return [
    ...commonAccessGroups,
    ...insightsAccessGroups,
    ...userManagementAccessGroups,
    ...popManagementAccessGroups,
  ];
};

export default getAllAccessGroups;
