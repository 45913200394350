export const CLEAR_MESSAGE_REQUEST = 'CLEAR_MESSAGE_REQUEST';

export const SET_DIALOG_OPEN_REQUEST = 'SET_DIALOG_OPEN_REQUEST';
export const SET_DIALOG_CLOSE_REQUEST = 'SET_DIALOG_CLOSE_REQUEST';

export const ADD_REQUESTS_REQUEST = 'ADD_REQUESTS_REQUEST';
export const ADD_REQUESTS_SUCCESS = 'ADD_REQUESTS_SUCCESS';
export const ADD_REQUESTS_FAILURE = 'ADD_REQUESTS_FAILURE';

export const GET_USAGE_PLAN_API_KEYS_REQUEST = 'GET_USAGE_PLAN_API_KEYS_REQUEST';
export const GET_USAGE_PLAN_API_KEYS_SUCCESS = 'GET_USAGE_PLAN_API_KEYS_SUCCESS';
export const GET_USAGE_PLAN_API_KEYS_FAILURE = 'GET_USAGE_PLAN_API_KEYS_FAILURE';

export const GET_PLAN_REQUEST = 'GET_PLAN_REQUEST';
export const GET_PLAN_SUCCESS = 'GET_PLAN_SUCCESS';
export const GET_PLAN_FAILURE = 'GET_PLAN_FAILURE';

export const GET_API_KEY_USAGE_PLAN_REQUEST = 'GET_API_KEY_USAGE_PLAN_REQUEST';
export const GET_API_KEY_USAGE_PLAN_SUCCESS = 'GET_API_KEY_USAGE_PLAN_SUCCESS';
export const GET_API_KEY_USAGE_PLAN_FAILURE = 'GET_API_KEY_USAGE_PLAN_FAILURE';

export const GET_NUMBER_OF_KEYS_REQUEST = 'GET_NUMBER_OF_KEYS_REQUEST';

export const REMOVE_USAGE_PLAN_FROM_API_KEY_REQUEST = 'REMOVE_USAGE_PLAN_FROM_API_KEY_REQUEST';
export const REMOVE_USAGE_PLAN_FROM_API_KEY_SUCCESS = 'REMOVE_USAGE_PLAN_FROM_API_KEY_SUCCESS';
export const REMOVE_USAGE_PLAN_FROM_API_KEY_FAILURE = 'REMOVE_USAGE_PLAN_FROM_API_KEY_FAILURE';

export const UPDATE_API_KEY_REQUEST = 'UPDATE_API_KEY_REQUEST';
export const UPDATE_API_KEY_SUCCESS = 'UPDATE_API_KEY_SUCCESS';
export const UPDATE_API_KEY_FAILURE = 'UPDATE_API_KEY_FAILURE';

export const ADD_USAGE_PLAN_TO_API_KEY_REQUEST = 'ADD_USAGE_PLAN_TO_API_KEY_REQUEST';
export const ADD_USAGE_PLAN_TO_API_KEY_SUCCESS = 'ADD_USAGE_PLAN_TO_API_KEY_SUCCESS';
export const ADD_USAGE_PLAN_TO_API_KEY_FAILURE = 'ADD_USAGE_PLAN_TO_API_KEY_FAILURE';

export const GET_API_KEY_USAGE_PLANS_REQUEST = 'GET_API_KEY_USAGE_PLANS_REQUEST';
export const GET_API_KEY_USAGE_PLANS_SUCCESS = 'GET_API_KEY_USAGE_PLANS_SUCCESS';
export const GET_API_KEY_USAGE_PLANS_FAILURE = 'GET_API_KEY_USAGE_PLANS_FAILURE';

export const GET_USAGE_PLANS_REQUEST = 'GET_USAGE_PLANS_REQUEST';
export const GET_USAGE_PLANS_SUCCESS = 'GET_USAGE_PLANS_SUCCESS';
export const GET_USAGE_PLANS_FAILURE = 'GET_USAGE_PLANS_FAILURE';

export const GET_USAGE_PLAN_REQUEST = 'GET_USAGE_PLAN_REQUEST';
export const GET_USAGE_PLAN_SUCCESS = 'GET_USAGE_PLAN_SUCCESS';
export const GET_USAGE_PLAN_FAILURE = 'GET_USAGE_PLAN_FAILURE';

export const CREATE_API_KEY_REQUEST = 'CREATE_API_KEY_REQUEST';
export const CREATE_API_KEY_SUCCESS = 'CREATE_API_KEY_SUCCESS';
export const CREATE_API_KEY_FAILURE = 'CREATE_API_KEY_FAILURE';

export const GET_API_KEYS_REQUEST = 'GET_API_KEYS_REQUEST';
export const GET_API_KEYS_SUCCESS = 'GET_API_KEYS_SUCCESS';
export const GET_API_KEYS_FAILURE = 'GET_API_KEYS_FAILURE';

export const GET_USER_API_KEYS_REQUEST = 'GET_USER_API_KEYS_REQUEST';
export const GET_USER_API_KEYS_SUCCESS = 'GET_USER_API_KEYS_SUCCESS';
export const GET_USER_API_KEYS_FAILURE = 'GET_USER_API_KEYS_FAILURE';

export const GET_API_KEY_REQUEST = 'GET_API_KEY_REQUEST';
export const GET_API_KEY_SUCCESS = 'GET_API_KEY_SUCCESS';
export const GET_API_KEY_FAILURE = 'GET_API_KEY_FAILURE';

export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const ENABLE_USER_REQUEST = 'ENABLE_USER_REQUEST';
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS';
export const ENABLE_USER_FAILURE = 'ENABLE_USER_FAILURE';

export const DISABLE_USER_REQUEST = 'DISABLE_USER_REQUEST';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILURE = 'DISABLE_USER_FAILURE';

export const GET_USERS_GROUPS_REQUEST = 'GET_USERS_GROUPS_REQUEST';
export const GET_USERS_GROUPS_SUCCESS = 'GET_USERS_GROUPS_SUCCESS';
export const GET_USERS_GROUPS_FAILURE = 'GET_USERS_GROUPS_FAILURE';

export const ADD_USER_TO_GROUP_REQUEST = 'ADD_USER_TO_GROUP_REQUEST';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAILURE = 'ADD_USER_TO_GROUP_FAILURE';

export const REMOVE_USER_FROM_GROUP_REQUEST = 'REMOVE_USER_FROM_GROUP_REQUEST';
export const REMOVE_USER_FROM_GROUP_SUCCESS = 'REMOVE_USER_FROM_GROUP_SUCCESS';
export const REMOVE_USER_FROM_GROUP_FAILURE = 'REMOVE_USER_FROM_GROUP_FAILURE';

export const GET_GROUPS_REQUEST = 'GET_GROUPS_REQUEST';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';

export const GET_GROUP_REQUEST = 'GET_GROUP_REQUEST';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const GET_GROUP_FAILURE = 'GET_GROUP_FAILURE';

export const GET_GROUP_USERS_REQUEST = 'GET_GROUP_USERS_REQUEST';
export const GET_GROUP_USERS_SUCCESS = 'GET_GROUP_USERS_SUCCESS';
export const GET_GROUP_USERS_FAILURE = 'GET_GROUP_USERS_FAILURE';

export const RESET_USERS_PASSWORD_REQUEST = 'RESET_USERS_PASSWORD_REQUEST';
export const RESET_USERS_PASSWORD_SUCCESS = 'RESET_USERS_PASSWORD_SUCCESS';
export const RESET_USERS_PASSWORD_FAILURE = 'RESET_USERS_PASSWORD_FAILURE';

export const ADMIN_SET_USERS_PASSWORD_REQUEST = 'ADMIN_SET_USERS_PASSWORD_REQUEST';
export const ADMIN_SET_USERS_PASSWORD_SUCCESS = 'ADMIN_SET_USERS_PASSWORD_SUCCESS';
export const ADMIN_SET_USERS_PASSWORD_FAILURE = 'ADMIN_SET_USERS_PASSWORD_FAILURE';

export const GET_USER_POOL_DATA_REQUEST = 'GET_USER_POOL_DATA_REQUEST';
export const GET_USER_POOL_DATA_SUCCESS = 'GET_USER_POOL_DATA_SUCCESS';
export const GET_USER_POOL_DATA_FAILURE = 'GET_USER_POOL_DATA_FAILURE';
