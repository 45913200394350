import CircularProgress from '@material-ui/core/CircularProgress';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import withAuth from '../hocs/withAuth';
import RedirectRoute from './RedirectRoute';

const buildLoadingRoute = (pageTitle, path, exact) => (
  <Route
    key={`routes-${pageTitle}`}
    path={path}
    exact={exact}
    component={() => <CircularProgress color="secondary" />}
  />
);

const buildRegularRoute = (pageTitle, path, exact, component) => (
  <Route
    key={`routes-${pageTitle}`}
    path={path}
    exact={exact}
    component={component}
  />
);

const buildRedirectRoute = (pageTitle, path, exact) => (
  <Route
    key={`routes-${pageTitle}`}
    path={path}
    exact={exact}
    component={() => <RedirectRoute pathname="/insights" />}
  />
);

const ProtectedRoute = ({ route, access, currentSession }) => {
  const {
    pageTitle,
    path,
    exact,
    main,
    extras,
  } = route;
  let finalRoute = buildLoadingRoute(pageTitle, path, exact);

  // If we do not have a session started for the user yet,
  // show a loading animation on the Route requested.
  if (Object.entries(currentSession).length === 0
    && currentSession.constructor === Object
  ) {
    finalRoute = buildLoadingRoute(pageTitle, path, exact);
  } else if (extras !== undefined && 'access' in extras) {
    const hasAccessGroups = route.extras.access.filter(
      (x) => access.includes(x),
    );

    if (hasAccessGroups.length > 0 || extras.access.length === 0) {
      finalRoute = buildRegularRoute(pageTitle, path, exact, main);
    } else {
      finalRoute = buildRedirectRoute(pageTitle, path, exact);
    }
  } else {
    finalRoute = buildRegularRoute(pageTitle, path, exact, main);
  }

  return finalRoute;
};

ProtectedRoute.propTypes = {
  route: PropTypes.object.isRequired,
  access: PropTypes.array.isRequired,
  currentSession: PropTypes.object.isRequired,
};

export default withAuth(ProtectedRoute);
