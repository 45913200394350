import { connect } from 'react-redux';

import { getUserFromStore } from '../../shared/reducers';

import {
  resetUsersPasswordRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import ResetPassword from '../components/ResetPassword';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  resetUsersPassword: (username) => dispatch(
    resetUsersPasswordRequest(username),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPassword);
