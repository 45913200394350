import { connect } from 'react-redux';

import {
  getApiKeyUsagePlansFromStore,
  getApiKeyUsagePlansStatusesFromStore,
} from '../../shared/reducers';

import { getApiKeyUsagePlansRequest } from '../apis/internalAuthApi/actions';

import ApiKeyUsagePlansList from '../components/ApiKeyUsagePlansList';

const mapStateToProps = (state) => ({
  usagePlans: getApiKeyUsagePlansFromStore(state),
  usagePlansStatus: getApiKeyUsagePlansStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApiKeyUsagePlans: (apiKeyId) => dispatch(
    getApiKeyUsagePlansRequest(apiKeyId),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiKeyUsagePlansList);
