import MaterialLink from '@material-ui/core/Link';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import CommonTable from './common/CommonTable';

const UsagePlansList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { usagePlans, nextToken, getUsagePlans } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (usagePlans.length !== 0) {
      setRows(buildRows(usagePlans));
    } else {
      setRows(buildRows([]));
    }
  }, [usagePlans]);

  const paginationCallback = (limit, page) => {
    getUsagePlans(null, limit, paginationTokens[page], null);
  };

  const tableHeaders = [
    'ID',
    'Name',
    'Description',
    'Plan Default Request Limit',
    'Default Rate Limit',
    'Default Burst Limit',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        id,
        name,
        description,
        quota,
        throttle,
      } = rawData[key];
      newObject[id] = [
        <MaterialLink
          component={Link}
          to={`/user-management/usage-plans/${id}`}
          color="primary"
        >
          {id}
        </MaterialLink>,
        name,
        description,
        `${quota.limit} per ${quota.period}`,
        throttle.rateLimit,
        throttle.burstLimit,
      ];
    }

    return newObject;
  };

  return (
    <GeneralPageHolder>
      <Breadcrumbs path={window.location.pathname} />
      <br />
      <CommonTable
        columnHeaders={tableHeaders}
        rows={rows}
        paginationTokens={paginationTokens}
        paginationCallback={paginationCallback}
      />
    </GeneralPageHolder>
  );
};

UsagePlansList.propTypes = {
  nextToken: PropTypes.string,
  getUsagePlans: PropTypes.func.isRequired,
  usagePlans: PropTypes.array.isRequired,
};

UsagePlansList.defaultProps = {
  nextToken: '',
};

export default UsagePlansList;
