import { connect } from 'react-redux';
import { getPaletteType } from '../../shared/reducers';

import { updatePaletteType } from '../actions';
import DarkModeToggle from '../components/DarkModeToggle';

const mapStateToProps = (state) => ({
  paletteType: getPaletteType(state),
});

const mapDispatchToProps = (dispatch) => ({
  changePaletteType: (inputData) => dispatch(updatePaletteType(inputData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DarkModeToggle);
