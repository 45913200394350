export const updatePaletteType = (paletteType) => ({
  type: 'UPDATE_PALETTE_TYPE',
  paletteType,
});

export const updateSideMenuState = (isOpen) => ({
  type: 'UPDATE_SIDE_MENU_STATE',
  isOpen,
});

export const updateSideMenuPanels = (panelName, panelOpen) => ({
  type: 'UPDATE_SIDE_MENU_PANELS',
  panelName,
  panelOpen,
});
