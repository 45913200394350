export const POP_CLEAR_MESSAGE_REQUEST = 'POP_CLEAR_MESSAGE_REQUEST';
export const POP_CLEAR_AD_REQUEST = 'POP_CLEAR_AD_REQUEST';

export const POP_CREATE_AD_UNIT_REQUEST = 'POP_CREATE_AD_UNIT_REQUEST';
export const POP_CREATE_AD_UNIT_SUCCESS = 'POP_CREATE_AD_UNIT_SUCCESS';
export const POP_CREATE_AD_UNIT_FAILURE = 'POP_CREATE_AD_UNIT_FAILURE';

export const POP_GET_AD_UNIT_REQUEST = 'POP_GET_AD_UNIT_REQUEST';
export const POP_GET_AD_UNIT_SUCCESS = 'POP_GET_AD_UNIT_SUCCESS';
export const POP_GET_AD_UNIT_FAILURE = 'POP_GET_AD_UNIT_FAILURE';

export const POP_GET_AD_SNIPPET_REQUEST = 'POP_GET_AD_SNIPPET_REQUEST';
export const POP_GET_AD_SNIPPET_SUCCESS = 'POP_GET_AD_SNIPPET_SUCCESS';
export const POP_GET_AD_SNIPPET_FAILURE = 'POP_GET_AD_SNIPPET_FAILURE';

export const POP_GET_ADS_REQUEST = 'POP_GET_ADS_REQUEST';
export const POP_GET_ADS_SUCCESS = 'POP_GET_ADS_SUCCESS';
export const POP_GET_ADS_FAILURE = 'POP_GET_ADS_FAILURE';

export const POP_GET_AD_UNIT_SPEC_REQUEST = 'POP_GET_AD_UNIT_SPEC_REQUEST';
export const POP_GET_AD_UNIT_SPEC_SUCCESS = 'POP_GET_AD_UNIT_SPEC_SUCCESS';
export const POP_GET_AD_UNIT_SPEC_FAILURE = 'POP_GET_AD_UNIT_SPEC_FAILURE';

export const POP_UPDATE_AD_UNIT_REQUEST = 'POP_UPDATE_AD_UNIT_REQUEST';
export const POP_UPDATE_AD_UNIT_SUCCESS = 'POP_UPDATE_AD_UNIT_SUCCESS';
export const POP_UPDATE_AD_UNIT_FAILURE = 'POP_UPDATE_AD_UNIT_FAILURE';

export const POP_PUBLISH_AD_UNIT_REQUEST = 'POP_PUBLISH_AD_UNIT_REQUEST';
export const POP_PUBLISH_AD_UNIT_SUCCESS = 'POP_PUBLISH_AD_UNIT_SUCCESS';
export const POP_PUBLISH_AD_UNIT_FAILURE = 'POP_PUBLISH_AD_UNIT_FAILURE';
