import { connect } from 'react-redux';

import {
  getUsersGroupsRequest,
  removeUserFromGroupRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import RemoveUserFromGroup from '../components/RemoveUserFromGroup';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  removeUserFromGroup: (username, groupName) => dispatch(
    removeUserFromGroupRequest(username, groupName),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
  getUsersGroups: (username) => dispatch(getUsersGroupsRequest(username)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RemoveUserFromGroup);
