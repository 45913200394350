import environment from '../configs/environment';

export const USER_MANAGEMENT_ADMIN_ACCESS_GROUP = 'userManagement_admin';

const getAccessGroups = (userData) => {
  const { env } = environment;
  const accessGroups = [];

  if ('cognito:groups' in userData) {
    const groups = userData['cognito:groups'];

    if (groups.includes(`red-team-core-${env}-internal-auth-api-admin`)) {
      accessGroups.push(USER_MANAGEMENT_ADMIN_ACCESS_GROUP);
    }
  }

  return accessGroups;
};

export default getAccessGroups;
