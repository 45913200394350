import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';

import GeneralPageHolder from '../../Common/components/GeneralPageHolder';

const AddApiCalls = ({
  addRequests,
  apiKeyId,
  planId,
  usage,
  limit,
  setDialogClose,
}) => {
  const [requests, setRequests] = useState('');

  const handleChange = (e) => {
    const { target } = e;
    const { value } = target;
    setRequests(value);
  };

  const handleAddApiCalls = () => {
    const update = (requests - usage).toString();
    if (limit >= requests) {
      // TODO: Replace with snackbar
      // alert(`Cannot request anything lower than the current limit of
      // ${limit}`);
    } else {
      addRequests(planId, apiKeyId, update);
    }
    setDialogClose();
  };

  return (
    <GeneralPageHolder>
      <form>
        <TextField
          name="requests"
          label="Requests"
          onChange={handleChange}
          value={requests}
        />
      </form>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddApiCalls}
      >
        Submit
      </Button>
    </GeneralPageHolder>
  );
};

AddApiCalls.propTypes = {
  addRequests: PropTypes.func.isRequired,
  apiKeyId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  usage: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default AddApiCalls;
