import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { withTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as PropTypes from 'prop-types';
import React from 'react';

const DarkModeToggle = ({ paletteType, changePaletteType }) => {
  const [state, setState] = React.useState({
    isDarkMode: paletteType === 'dark',
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
    changePaletteType(event.target.value);
  };

  return (
    <FormControl component="fieldset">
      <FormGroup>
        <FormControlLabel
          control={<Switch
            checked={state.isDarkMode}
            onChange={handleChange('isDarkMode')}
            value={state.isDarkMode ? 'light' : 'dark'}
            inputProps={{ 'aria-label': 'Dark Mode on/off switch' }}
          />}
          labelPlacement="start"
          label={`Dark Mode ${state.isDarkMode ? 'ON' : 'OFF'}`}
        />
      </FormGroup>
    </FormControl>
  );
};

DarkModeToggle.propTypes = {
  changePaletteType: PropTypes.func.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default withTheme(DarkModeToggle);
