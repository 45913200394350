import MaterialLink from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import environment from '../../configs/environment';
import RemoveUserFromGroupController
  from '../controllers/RemoveUserFromGroupController';
import CommonTable from './common/CommonTable';

const UserGroupsList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { groups, nextToken, getUsersGroups, username } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (groups.length !== 0) {
      setRows(buildRows(groups));
    } else {
      setRows(buildRows([]));
    }
  }, [groups]);

  // eslint-disable-next-line no-unused-vars
  const paginationCallback = (limit, page) => {
    getUsersGroups(username);
  };

  const tableHeaders = [
    'Group Name',
    'Remove From Group?',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const { name } = rawData[key];
      newObject[name] = [
        <Tooltip
          title={name}
          aria-label={name}
          placement="top"
        >
          <MaterialLink
            component={Link}
            to={`/user-management/groups/${name}`}
            color="primary"
          >
            {name in environment.group_map
              ? environment.group_map[name]
              : name}
          </MaterialLink>
        </Tooltip>,
        <SimpleDialogController
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle="Remove"
          dialogHeader="Are you sure?"
          component={<RemoveUserFromGroupController
            username={username}
            groupName={name}
          />}
          id={`${name}-removeGroup`}
        />,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

UserGroupsList.propTypes = {
  groups: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getUsersGroups: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
};

UserGroupsList.defaultProps = {
  nextToken: '',
};

export default UserGroupsList;
