import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import withTheme from '@material-ui/core/styles/withTheme';
import Typography from '@material-ui/core/Typography';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import getAllRoutes from '../../shared/allRoutes';
import withAuth from '../hocs/withAuth';
import Breadcrumbs from './Breadcrumbs';

import GeneralPageHolder from './GeneralPageHolder';

const hexToRgb = (hex) => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const newHex = hex.replace(shorthandRegex,
    (m, r, g, b) => (r + r + g + g + b + b));

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(newHex);
  return result
    ? `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(
      result[3],
      16,
    )}`
    : '0,0,0';
};

const styles = (theme) => ({
  cluvioDashboardIframe: {
    height: 'calc(100vh - 65px)',
    width: '100%',
  },
  box: {
    backgroundColor: theme.palette.background.paper,
    flexGrow: 1,
    minHeight: 'calc(100vh - 65px)',
  },
  card: {
    textAlign: 'center',
  },
  cardContent: {
    height: '100%',
    display: 'block',
    alignItems: 'center',
    textAlign: 'center',
    textTransform: 'none',
    color: theme.palette.text.primary,
    backgroundColor: `rgba(${hexToRgb(theme.palette.grey[100])},0.5)`,
    '&:hover': {
      backgroundColor: `rgba(${hexToRgb(theme.palette.grey[300])},0.5)`,
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: `rgba(${hexToRgb(theme.palette.grey[50])},0.5)`,
    },
    '&:focus': {
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
    border: `solid 1px ${theme.palette.secondary.light}`,
    '& svg': {
      height: 100,
      width: 100,
    },
    '& div': {
      display: 'inline-block',
      textDecoration: 'none',
    },
  },
  cardContentText: {
    textAlign: 'left',
    width: '100%',
  },
  headerDivider: {
    width: '100%',
  },
  headerName: {
    color: theme.palette.text.primary,
  },
});

const GetNavData = (classes, nestedRoutes, path, access) => {
  let navData = [];
  const BreakException = {};

  try {
    nestedRoutes.forEach((route) => {
      // Check to see if we are on the right route tree and
      // avoid the Home route tree.
      if (path.startsWith(route.path) && route.path !== '/') {
        if (path === route.path) {
          navData.push(
            <Grid item xs={12} key={`mainRoute-${route.pageTitle}`}>
              <Typography
                variant="h5"
                gutterBottom
                className={classes.headerName}
              >
                {route.pageTitle}
              </Typography>
              <Divider className={classes.headerDivider} />
            </Grid>,
          );
          if ('routes' in route) {
            route.routes.forEach((childRoute) => {
              const canAccess = access.some((accessGrant) =>
                childRoute.extras.access.includes(accessGrant));

              if (canAccess) {
                if (childRoute.type === 'header') {
                  navData.push(
                    <Grid item xs={12} key={`headerRoute-${childRoute.path}`}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.headerName}
                        id={childRoute.pageTitle.toLowerCase().replace(
                          ' ',
                          '-',
                        )}
                      >
                        {childRoute.pageTitle}
                      </Typography>
                      <Divider className={classes.headerDivider} />
                    </Grid>,
                  );
                } else if (childRoute.type === 'hidden') {
                  // Do nothing
                } else {
                  const extraData = [];
                  if ('data' in childRoute.extras) {
                    childRoute.extras.data.forEach((data) => {
                      extraData.push(
                        <li key={`childRoute-${data}`}>
                          <Typography variant="body1">
                            {data}
                          </Typography>
                        </li>,
                      );
                    });
                  } else if ('routes' in childRoute) {
                    childRoute.routes.forEach((grandchildRoute) => {
                      if ('type' in grandchildRoute && grandchildRoute.type
                        === 'header') {
                        const canAccessChildren = access.some((accessGrant) =>
                          grandchildRoute.extras.access.includes(accessGrant));
                        if (canAccessChildren) {
                          extraData.push(
                            <li key={`childRoute-${grandchildRoute.pageTitle}`}>
                              <Typography variant="body1">
                                {grandchildRoute.pageTitle}
                              </Typography>
                            </li>,
                          );
                        }
                      }
                    });
                  }

                  navData.push(
                    <Grid
                      item
                      lg={4}
                      md={4}
                      sm={6}
                      xs={12}
                      key={`button-${childRoute.path}`}
                    >
                      <Button
                        focusRipple
                        variant="contained"
                        className={classes.cardContent}
                        component={Link}
                        to={childRoute.path}
                      >
                        <div>
                          {childRoute.icon}
                          <Typography variant="h6">
                            {childRoute.pageTitle}
                          </Typography>
                        </div>
                        <div className={classes.cardContentText}>
                          <ul>
                            {extraData}
                          </ul>
                        </div>
                      </Button>
                    </Grid>,
                  );
                }
              }
            });
            throw BreakException;
          }
        } else {
          navData = GetNavData(classes, route.routes, path, access);
        }
      }
    });
  } catch (e) {
    if (e !== BreakException) {
      throw e;
    }
  }

  return navData;
};

function hashLinkScroll(hash) {
  if (hash !== '') {
    // Push onto callback queue so it runs after the DOM is updated,
    // this is required when navigating from a different page so that
    // the element is rendered on the page before trying to getElementById.
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }
}

class NavOptionsPage extends Component {
  componentDidMount() {
    const { location } = this.props;
    hashLinkScroll(location.hash);
  }

  render() {
    const { location, classes, access } = this.props;
    const allRoutes = getAllRoutes();

    return (
      <GeneralPageHolder>
        <Breadcrumbs path={location.pathname} />
        <Grid container spacing={3}>
          {GetNavData(classes, allRoutes, location.pathname, access)}
        </Grid>
      </GeneralPageHolder>
    );
  }
}

NavOptionsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  access: PropTypes.array.isRequired,
};

export default withAuth(
  withRouter(withTheme(withStyles(styles)(NavOptionsPage))),
);
