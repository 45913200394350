import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { ForgotPassword } from 'aws-amplify-react';
import React from 'react';

import AuthHolder from './AuthHolder';

const styles = (theme) => ({
  card: {
    width: 275,
  },
  textField: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  authButton: {
    fontWeight: 'bold',
  },
  buttonHolder: {
    width: '100%',
  },
});

/**
 * @see https://aws-amplify.github.io/media/ui_library
 */
class CustomForgotPassword extends ForgotPassword {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['forgotPassword'];
  }

  sendView() {
    const { classes } = this.props;
    return (
      <TextField
        className={classes.textField}
        id="username"
        key="username"
        name="username"
        label="Username"
        onChange={this.handleInputChange}
        type="text"
        placeholder="Username"
      />
    );
  }

  submitView() {
    const { classes } = this.props;
    return (
      <>
        <TextField
          className={classes.textField}
          id="code"
          key="code"
          name="code"
          label="Code"
          autoComplete="off"
          onChange={this.handleInputChange}
          type="text"
          placeholder="Code"
        />
        <TextField
          className={classes.textField}
          id="password"
          key="password"
          name="password"
          label="Password"
          onChange={this.handleInputChange}
          type="password"
          placeholder="Password"
        />
      </>
    );
  }

  showComponent() {
    const { classes, authData } = this.props;

    return (
      <AuthHolder>
        <Card className={classes.card}>
          <CardHeader
            title="Reset your password"
            className={classes.cardHeader}
          >
          </CardHeader>
          <CardContent>
            <form>
              {this.state.delivery || (authData && authData.username)
                ? this.submitView() : this.sendView()}
            </form>
          </CardContent>
          <CardActions>
            {
              this.state.delivery || (authData && authData.username) ?
                <Button
                  fullWidth
                  size="large"
                  onClick={() => super.submit()}
                  variant="contained"
                  color="secondary"
                  className={classes.authButton}
                >
                  Submit
                </Button> :
                <Button
                  fullWidth
                  size="large"
                  onClick={() => super.send()}
                  variant="contained"
                  color="secondary"
                  className={classes.authButton}
                >
                  Send Code
                </Button>
            }
          </CardActions>
          <CardActions>
            {
              this.state.delivery || (authData && authData.username) ?
                (
                  <div className={classes.buttonHolder}>
                    <Button
                      fullWidth
                      size="small"
                      color="secondary"
                      className={classes.margin}
                      onClick={() => super.send()}
                    >
                      Resend Code
                    </Button>
                    <Button
                      fullWidth
                      size="small"
                      color="secondary"
                      className={classes.margin}
                      onClick={() => super.changeState('signIn')}
                    >
                      Back to Sign In
                    </Button>
                  </div>
                ) :
                (
                  <Button
                    fullWidth
                    size="small"
                    color="secondary"
                    className={classes.margin}
                    onClick={() => super.changeState('signIn')}
                  >
                    Back to Sign In
                  </Button>
                )
            }
          </CardActions>
        </Card>
      </AuthHolder>
    );
  }
}

export default withStyles(styles)(CustomForgotPassword);
