import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import environment from '../../configs/environment';
import CommonTable from './common/CommonTable';

const useStyles = makeStyles(() => ({
  button: {
    textTransform: 'none',
  },
}));

const AddGroupToUser = ({
  groups,
  getGroups,
  nextToken,
  user,
  addUserToGroup,
  userGroups,
  setDialogClose,
}) => {
  const classes = useStyles();
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState({});
  const usersGroups = userGroups.map((x) => x.name);
  const allGroups = groups.map((x) => x.name);
  const difference = allGroups.filter((x) => !usersGroups.includes(x));

  const handleAdd = (groupName) => {
    addUserToGroup(user.username, groupName);
    setDialogClose();
  };

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (groups.length === 0) {
      getGroups();
    }
  }, [groups]);

  useEffect(() => {
    if (groups.length !== 0) {
      setRows(buildRows(difference));
    } else {
      setRows(buildRows([]));
    }
  }, [groups]);

  const paginationCallback = (limit, page) => {
    getGroups(limit, paginationTokens[page]);
  };

  const tableHeaders = [
    'Name',
    // 'Description',
    'Action',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const name = rawData[key];
      newObject[name] = [
        name in environment.group_map
          ? environment.group_map[name]
          : name,
        <Button
          startIcon={<AddIcon />}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => handleAdd(name)}
        >
          Add
        </Button>,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

AddGroupToUser.propTypes = {
  groups: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  addUserToGroup: PropTypes.func.isRequired,
  userGroups: PropTypes.array.isRequired,
  setDialogClose: PropTypes.func.isRequired,
  nextToken: PropTypes.string,
  getGroups: PropTypes.func.isRequired,
};

AddGroupToUser.defaultProps = {
  nextToken: '',
};

export default AddGroupToUser;
