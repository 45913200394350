import * as PropTypes from 'prop-types';
import React from 'react';
import { Redirect } from 'react-router-dom';

const RedirectRoute = ({ pathname, query, hash, location }) => {
  const search = `?${Object.keys(query).map((key) =>
    `${key}=${query[key]}`).join('&')}`;
  return (
    <Redirect to={{ pathname, search, hash, state: { from: location } }} />
  );
};

RedirectRoute.propTypes = {
  // A string representing the path to link to.
  pathname: PropTypes.string.isRequired,
  // An object of key:value pairs to be stringified.
  query: PropTypes.object,
  // A hash to put in the URL, e.g. #a-hash.
  hash: PropTypes.string,
  // State to persist to the location.
  location: PropTypes.string,
};

RedirectRoute.defaultProps = {
  query: {},
  hash: '',
  location: '',
};

export default RedirectRoute;
