import { connect } from 'react-redux';

import {
  getAdsFromStore,
  getPopMessageFromStore,
} from '../../shared/reducers';

import {
  clearAdRequest,
  clearMessageRequest,
  getAdsRequest,
} from '../apis/popManagementApi/actions';

import AdsList from '../components/AdsList';

const mapStateToProps = (state) => ({
  ads: getAdsFromStore(state),
  message: getPopMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearAd: () => dispatch(clearAdRequest()),
  clearMessage: () => dispatch(clearMessageRequest()),
  getAds: (
    customerId,
  ) =>
    dispatch(
      getAdsRequest(
        customerId,
      ),
    ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdsList);
