import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import * as PropTypes from 'prop-types';
import React, { useState } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
  },
  formField: {
    marginBottom: theme.spacing(1),
  },
}));

const UpdateUser = ({ user, updateUser, setDialogClose }) => {
  const classes = useStyles();
  const [state, setState] = useState({
    username: user.username,
    name: user.name,
    email: user.email,
    customerId: user.customer_id,
  });
  const {
    username,
    name,
    email,
    customerId,
  } = state;

  const handleChange = (event) => {
    const { target } = event;
    const { value, name: targetName } = target;
    setState({ ...state, [targetName]: value });
  };

  const handleSubmit = () => {
    updateUser(username, name, email, customerId);
    setDialogClose();
  };

  const formData = [
    {
      name: 'username',
      label: 'Username',
      value: username,
      disabled: true,
    },
    {
      name: 'name',
      label: 'Full Name',
      value: name,
      disabled: false,
    },
    {
      name: 'email',
      label: 'Email',
      value: email,
      disabled: false,
    },
    {
      name: 'customerId',
      label: 'Customer ID',
      value: customerId,
      disabled: false,
    },
  ];

  return (
    <div className={classes.root}>
      <form>
        {formData.map((formPiece) => (
          <TextField
            fullWidth
            key={formPiece.name}
            className={classes.formField}
            variant="outlined"
            name={formPiece.name}
            label={formPiece.label}
            onChange={handleChange}
            value={formPiece.value}
            disabled={formPiece.disabled}
          />
        ))}
      </form>
      <Button
        fullWidth
        size="large"
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        Submit
      </Button>
    </div>
  );
};

UpdateUser.propTypes = {
  updateUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default UpdateUser;
