import Button from '@material-ui/core/Button';
import MaterialLink from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';
import { formatDateTime } from '../../Common/utils';
import TablePaginationActions from './common/TablePaginationActions';

const styles = (theme) => ({
  table: {
    minWidth: 650,
  },
  dropDown: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderRight: 0,
  },
  searchButton: {
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    height: 56,
    boxShadow: 'none',
  },
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
  searchHolder: {
    marginBottom: theme.spacing(1),
  },
  searchTextField: {
    borderRadius: 0,
    '& label.Mui-focused': {
      borderRadius: 0,
    },
    '& .MuiInput-underline:after': {
      borderRadius: 0,
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
    },
  },
});

class UsersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      users: [],
      count: 0,
      page: 0,
      paginationTokens: [
        '',
      ],
      rowsPerPage: 50,
      search: '',
      searchField: 'username',
      submitSearch: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!(props.nextToken in state.paginationTokens) && props.nextToken
      !== '') {
      return {
        ...state,
        paginationTokens: [
          ...state.paginationTokens,
          props.nextToken,
        ],
      };
    }

    if (props.message !== state.message) {
      props.setSuccessMessage(props.message);
    }
    if (props.userStatus.error !== '') {
      props.setErrorMessage(props.userStatus.error);
    }

    // Return null to indicate no change to state.
    return null;
  }

  componentDidMount() {
    const { getUsers, getNumberOfUsers } = this.props;
    getUsers(this.state.rowsPerPage);
    getNumberOfUsers();
  }

  handleChangePage = (event, page) => {
    this.props.getUsers(this.state.rowsPerPage, null, null, null,
      this.state.paginationTokens[page]);
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    const rowsPerPage = event.target.value;
    this.props.getUsers(rowsPerPage, null, null, null, null);
    this.setState({ rowsPerPage });
    this.setState({ page: 0 });
  };

  handleSearch = (search) => {
    if (search !== '') {
      this.props.getUsers(this.state.rowsPerPage, this.state.searchField,
        'starts_with', search);
      this.setState({ submitSearch: true });
    } else {
      this.props.getUsers(this.state.rowsPerPage);
    }
  };

  handleChange = (e) => {
    const { target } = e;
    const { value, name } = target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { page, rowsPerPage } = this.state;
    const {
      classes,
      users,
      numberOfUsers,
    } = this.props;

    return (
      users.length === 0 && this.state.submitSearch === false ?
        <ProgressWheel />
        :
        <GeneralPageHolder>
          <Breadcrumbs path={window.location.pathname} />
          <br />
          <div className={classes.searchHolder}>
            <Select
              labelId="test"
              name="searchField"
              id="test"
              value={this.state.searchField}
              onChange={this.handleChange}
              variant="outlined"
              className={classes.dropDown}
            >
              <MenuItem value="username">Username</MenuItem>
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="email">Email</MenuItem>
            </Select>
            <TextField
              className={classes.searchTextField}
              name="search"
              label="Search"
              varian="outlined"
              onChange={this.handleChange}
              autoFocus
              variant="outlined"
              color="secondary"
            />
            <Button
              color="primary"
              variant="contained"
              startIcon={<SearchIcon />}
              className={classes.searchButton}
              onClick={() => this.handleSearch(this.state.search)}
            >
              Search
            </Button>
          </div>
          <br />
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.head}>Username</TableCell>
                <TableCell className={classes.head}>Customer ID</TableCell>
                <TableCell className={classes.head}>Enabled</TableCell>
                <TableCell className={classes.head}>Status</TableCell>
                <TableCell className={classes.head}>Last Modified</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => {
                const {
                  user_last_modified_date: userLastModifiedDate,
                  username,
                  customer_id: customerId,
                  enabled,
                  user_status: userStatus,
                } = user;
                return (
                  <TableRow key={username}>
                    <TableCell component="th" scope="row">
                      <MaterialLink
                        component={Link}
                        to={`/user-management/users/${username}`}
                        color="primary"
                      >
                        {username}
                      </MaterialLink>
                    </TableCell>
                    <TableCell>{customerId}</TableCell>
                    <TableCell>{enabled ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{userStatus}</TableCell>
                    <TableCell>
                      {formatDateTime(userLastModifiedDate)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  colSpan={5}
                  count={numberOfUsers.EstimatedNumberOfUsers !== undefined
                    ? numberOfUsers.EstimatedNumberOfUsers
                    : 10}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </GeneralPageHolder>
    );
  }
}

UsersList.propTypes = {
  classes: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  numberOfUsers: PropTypes.object.isRequired,
  getUsers: PropTypes.func.isRequired,
  getNumberOfUsers: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  userStatus: PropTypes.object.isRequired,
};

UsersList.defaultProps = {
  nextToken: '',
};

export default withView(null)(withStyles(styles)(UsersList));
