import MaterialLink from '@material-ui/core/Link';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import RemovePlanFromApiKeyController
  from '../controllers/RemovePlanFromApiKeyController';
import CommonTable from './common/CommonTable';

const UsagePlanApiKeysList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { apiKeys, nextToken, getUsagePlanApiKeys, usagePlanId } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    }
  }, [nextToken]);

  useEffect(() => {
    if (apiKeys.length !== 0) {
      setRows(buildRows(apiKeys));
    } else {
      setRows(buildRows([]));
    }
  }, [apiKeys]);

  // eslint-disable-next-line no-unused-vars
  const paginationCallback = (limit, page) => {
    getUsagePlanApiKeys(usagePlanId);
  };

  const tableHeaders = [
    'ID',
    'Name',
    'Description',
    'Remove?',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const { id, name, description } = rawData[key];
      newObject[id] = [
        <MaterialLink
          component={Link}
          to={`/user-management/api-keys/${id}`}
          color="primary"
        >
          {id}
        </MaterialLink>,
        name,
        description,
        <SimpleDialogController
          buttonIcon={<DeleteForeverIcon />}
          buttonTitle="Remove"
          dialogHeader="Are you sure?"
          component={
            <RemovePlanFromApiKeyController
              apiKeyId={id}
              planId={usagePlanId}
            />
          }
          // closeAction={() => getPlanKeys(usagePlanId, null, null,
          //   null, null)}
          id="removePlan"
        />,
      ];
    }

    return newObject;
  };

  return (
    <CommonTable
      columnHeaders={tableHeaders}
      rows={rows}
      paginationTokens={paginationTokens}
      paginationCallback={paginationCallback}
    />
  );
};

UsagePlanApiKeysList.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getUsagePlanApiKeys: PropTypes.func.isRequired,
  usagePlanId: PropTypes.string.isRequired,
};

UsagePlanApiKeysList.defaultProps = {
  nextToken: '',
};

export default UsagePlanApiKeysList;
