/**
 * Original from here: https://stackoverflow.com/a/4878800
 *
 * @param str
 * @returns {any}
 */
export const toTitleCase = (str) =>
  str.replaceAll('_', ' ').replace(/\w\S*/g, (txt) =>
    txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const formatDateTime = (datetime) => {
  const date = new Date(datetime);
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
};
