import { Typography } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';
import UsagePlanApiKeysListController
  from '../controllers/UsagePlanApiKeysListController';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
}));

const ListObject = ({ name, value }) => (
  <ListItem alignItems="flex-start">
    <ListItemText
      primary={
        <Typography
          component="span"
          variant="body2"
          color="textPrimary"
        >
          {name}
        </Typography>
      }
      secondary={value}
    />
  </ListItem>
);

ListObject.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const UsagePlan = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const {
    message: newMessage,
    match,
    usagePlan,
    setSuccessMessage,
    getUsagePlan,
  } = props;
  const { params } = match;
  const { planid: usagePlanId } = params;

  useEffect(() => {
    // TODO: Messages with the same content cause a weird issue here.
    if (newMessage !== message) {
      setSuccessMessage(newMessage);
      setMessage(newMessage);
    }

    if (newMessage.includes(
      'Successfully deleted the Usage Plan to Api Key relationship',
    )) {
      setMessage('');
      window.location.href = '#apiKeys';
    }
  }, [newMessage]);

  useEffect(() => {
    getUsagePlan(usagePlanId);
  }, [usagePlanId]);

  return (
    <GeneralPageHolder>
      <div id="main" />
      <Breadcrumbs path={window.location.pathname} />
      <br />
      {Object.keys(usagePlan).length === 0
        ? <ProgressWheel />
        : (
          <>
            <Paper className={classes.root}>
              <List>
                <ListObject
                  name="ID"
                  value={usagePlan.id}
                />
                <ListObject
                  name="Name"
                  value={usagePlan.name}
                />
                <ListObject
                  name="Description"
                  value={usagePlan.description}
                />
                <ListObject
                  name="Default Request Limit"
                  value={`${usagePlan.quota.limit} per ${usagePlan.quota.period}`}
                />
                <ListObject
                  name="Default Rate Limit"
                  value={usagePlan.throttle.rateLimit}
                />
                <ListObject
                  name="Default Burst Limit"
                  value={usagePlan.throttle.burstLimit}
                />
              </List>
            </Paper>
            <br />
            <div id="apiKeys" />
            <Typography variant="h4" color="textPrimary" gutterBottom>
              API Keys
            </Typography>
            <UsagePlanApiKeysListController usagePlanId={usagePlanId} />
          </>
        )}
    </GeneralPageHolder>
  );
};

UsagePlan.propTypes = {
  match: PropTypes.object.isRequired,
  getUsagePlan: PropTypes.func.isRequired,
  usagePlan: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
};

export default withView(null)(UsagePlan);
