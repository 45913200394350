import { Auth } from 'aws-amplify';
import axios from 'axios';

import environment from '../../../configs/environment';

const POP_API_DOMAIN = environment.pop_management_api_domain;

const api = {
  getAdUnitSpec: (adType) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${POP_API_DOMAIN}/ad/spec/${adType}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getAds: (customerId) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${POP_API_DOMAIN}/ad`, {
          headers: {
            'Content-Type': 'application/json',
            'x-talroo-pop-cid': customerId,
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getAdUnit: (adId, customerId) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${POP_API_DOMAIN}/ad/${adId}`, {
          headers: {
            'Content-Type': 'application/json',
            'x-talroo-pop-cid': customerId,
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getAdSnippet: (adId, cid) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${POP_API_DOMAIN}/ad/${adId}/snippet?cid=${cid}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  createAdUnit: (formData, adType) =>
    Auth.currentSession().then((data) =>
      axios.post(
        `${POP_API_DOMAIN}/ad`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-talroo-pop-cid': formData.general.customer_id,
            Authorization: data.idToken.jwtToken,
          },
          params: {
            ad_type: adType,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  updateAdUnit: (adId, formData) =>
    Auth.currentSession().then((data) =>
      axios.put(
        `${POP_API_DOMAIN}/ad/${adId}`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            'x-talroo-pop-cid': formData.general.customer_id,
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  publishAdUnit: (adId, customerId) =>
    Auth.currentSession().then((data) =>
      axios.post(
        `${POP_API_DOMAIN}/ad/publish/${adId}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            'x-talroo-pop-cid': customerId,
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),
};

export default api;
