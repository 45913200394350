import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Form from '@rjsf/material-ui';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';

const UpdateAdUnit = (props) => {
  const {
    ad,
    adSpec,
    getAdUnit,
    getAdUnitSpec,
    updateAdUnit,
    match,
    message,
    setSuccessMessage,
    clearMessage,
  } = props;

  const { params } = match;
  const { adId, customerId } = params;

  useEffect(() => {
    getAdUnit(adId, customerId);
  }, [adId, customerId]);

  useEffect(() => {
    if (ad.ad_type) {
      getAdUnitSpec(ad.ad_type);
    }
  }, [ad]);

  const updateAd = ({ formData }) => {
    updateAdUnit(adId, formData);
  };

  const cancelUpdate = () => {
    window.location.replace(`/pop-management/ad/${customerId}/${adId}`);
  };

  useEffect(() => {
    setSuccessMessage(message);
    if (message !== undefined) {
      if (message.includes('Successfully Updated')) {
        window.location.replace(`/pop-management/ad/${customerId}/${adId}`);
        clearMessage();
      }
    }
  }, [message, ad]);

  return (
    Object.keys(adSpec).length === 0 ?
      <ProgressWheel />
      :
      <GeneralPageHolder>
        <Breadcrumbs path={window.location.pathname} />
        <br />
        <Form schema={adSpec} formData={ad} onSubmit={updateAd}>
          <div>
            <Button variant="contained" color="primary" type="submit">Update</Button>
            <Button variant="outlined" color="secondary" onClick={cancelUpdate}>Cancel</Button>
          </div>
        </Form>
        <br />
      </GeneralPageHolder>
  );
};

UpdateAdUnit.propTypes = {
  match: PropTypes.object.isRequired,
  ad: PropTypes.object.isRequired,
  adSpec: PropTypes.object.isRequired,
  getAdUnit: PropTypes.func.isRequired,
  getAdUnitSpec: PropTypes.func.isRequired,
  updateAdUnit: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default withView(null)(UpdateAdUnit);
