import { connect } from 'react-redux';

import {
  getAdFromStore,
  getAdSpecFromStore,
  getUserFromStore,
  getPopMessageFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  createAdUnitRequest,
  getAdUnitSpecRequest,
} from '../apis/popManagementApi/actions';

import CreateAdUnit from '../components/CreateAdUnit';

const mapStateToProps = (state, ownProps) => {
  const { adType } = ownProps;
  return {
    adType,
    ad: getAdFromStore(state),
    adSpec: getAdSpecFromStore(state),
    user: getUserFromStore(state),
    message: getPopMessageFromStore(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  createAdUnit: (
    formData,
    adType,
  ) => dispatch(
    createAdUnitRequest(
      formData,
      adType
    ),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
  getAdUnitSpec: (
    adType
  ) => dispatch(
    getAdUnitSpecRequest(
      adType
    )
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateAdUnit);
