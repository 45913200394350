import { Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LockIcon from '@material-ui/icons/Lock';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import SimpleDialogController
  from '../../Common/controllers/SimpleDialogController';
import withView from '../../Common/hocs/withView';
import { formatDateTime, toTitleCase } from '../../Common/utils';
import AddGroupToUserController from '../controllers/AddGroupToUserController';
import AdminSetPasswordController
  from '../controllers/AdminSetPasswordController';
import CreateApiKeyController from '../controllers/CreateApiKeyController';
import EnablerController from '../controllers/EnablerController';
import ResetPasswordController from '../controllers/ResetPasswordController';
import UpdateUserController from '../controllers/UpdateUserController';
import UserApiKeysListController
  from '../controllers/UserApiKeysListController';
import UserGroupsListController from '../controllers/UserGroupsListController';
import Placeholder from './common/Placeholder';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  buttonHolder: {
    textAlign: 'center',
  },
}));

const User = (props) => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const {
    user,
    userStatus,
    getUser,
    match,
    message: newMessage,
    setSuccessMessage,
    setErrorMessage,
    getUsersGroups,
    getUserApiKeys,
    apiKeys,
  } = props;

  const { params } = match;
  const { username } = params;

  const userAlreadyHasApiKey = (apiKeyName) => {
    const result = apiKeys.filter((apiKey) => apiKey.name === apiKeyName);
    return result.length > 0;
  };

  useEffect(() => {
    // TODO: Messages with the same content cause a weird issue here.
    if (newMessage !== message) {
      setSuccessMessage(newMessage);
      setMessage(newMessage);
    }

    if (newMessage) {
      if (userStatus.error !== '') {
        setErrorMessage(userStatus.error);
      } else if (newMessage.includes('Successfully enabled the user')
        || newMessage.includes('Successfully disabled the user')
        || newMessage.includes('Successfully Reset Password')
      ) {
        getUser(username);
        window.location.href = '#main';
      } else if (newMessage.includes('Successfully created key')) {
        getUserApiKeys(`${user.customer_id}-api-key`);
        window.location.href = '#keys';
      } else if (newMessage.includes('Successfully added the user')
        || newMessage.includes('Successfully removed the user')
      ) {
        getUsersGroups(username);
        window.location.href = '#groups';
      }
    }
  }, [newMessage]);

  useEffect(() => {
    getUser(username);
  }, [username]);

  return (
    <GeneralPageHolder>
      <div id="main" />
      <Breadcrumbs path={window.location.pathname} />
      <br />
      {Object.keys(user).length === 0
        ? <ProgressWheel />
        : (
          <>
            <div className={classes.buttonHolder}>
              <SimpleDialogController
                buttonIcon={user.enabled !== true
                  ? <CheckCircleOutlineIcon />
                  : <HighlightOffIcon />}
                buttonTitle={user.enabled !== true
                  ? 'Enable User'
                  : 'Disable User'}
                disabled={userStatus.fetching}
                dialogHeader="Are you sure?"
                component={<EnablerController />}
                closeAction={() => getUser(username)}
                id="enabler"
              />
              {userAlreadyHasApiKey(`${user.customer_id}-api-key`)
                ? null
                : (
                  <SimpleDialogController
                    buttonIcon={<VpnKeyIcon />}
                    buttonTitle="Create API Key"
                    disabled={userStatus.fetching}
                    dialogHeader="Are you sure?"
                    component={<CreateApiKeyController
                      keyName={`${user.customer_id}-api-key`}
                      description={`API Key for ${username}`}
                      username={username}
                    />}
                    id="createKey"
                  />
                )}
              <SimpleDialogController
                buttonIcon={<GroupWorkIcon />}
                buttonTitle="Add to Group"
                disabled={userStatus.fetching}
                dialogHeader="Choose a Group"
                component={<AddGroupToUserController username={username} />}
                id="addGroup"
              />
              <SimpleDialogController
                buttonIcon={<PermIdentityIcon />}
                buttonTitle="Update User"
                disabled={userStatus.fetching}
                dialogHeader="Update User"
                component={<UpdateUserController />}
                closeAction={() => getUser(username)}
                id="updateUser"
              />
              <SimpleDialogController
                buttonIcon={<LockIcon />}
                buttonTitle="Reset Password"
                disabled={userStatus.fetching}
                dialogHeader="Are you sure?"
                component={<ResetPasswordController />}
                closeAction={() => getUser(username)}
                id="resetPassword"
              />
              <SimpleDialogController
                buttonIcon={<SupervisorAccountIcon />}
                buttonTitle="Admin Set Password"
                disabled={userStatus.fetching}
                dialogHeader="Are you sure?"
                component={<AdminSetPasswordController />}
                closeAction={() => getUser(username)}
                id="adminSetPassword"
              />
            </div>
            <Paper className={classes.root}>
              <List>
                {Object.keys(user).map((key) => {
                  const listKey = `user${key}`;
                  let value = user[key];

                  if (typeof value === 'boolean') {
                    value = value ? 'Yes' : 'No';
                  }

                  if (key === 'user_last_modified_date' || key
                    === 'user_create_date') {
                    value = formatDateTime(value);
                  }

                  return (
                    <div key={`${listKey}div`}>
                      <ListItem alignItems="flex-start">
                        <ListItemText
                          primary={
                            <Typography
                              component="span"
                              variant="body2"
                              className={classes.inline}
                              color="textPrimary"
                            >
                              {toTitleCase(key)}
                            </Typography>
                          }
                          secondary={userStatus.fetching
                            ? <Placeholder />
                            : value}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </div>);
                })}
              </List>
            </Paper>
            <br />
            <div id="groups" />
            <Typography variant="h4" color="textPrimary" gutterBottom>
              Groups
            </Typography>
            <UserGroupsListController username={username} />
            <br />
            <div id="keys" />
            <Typography variant="h4" color="textPrimary" gutterBottom>
              API Keys
            </Typography>
            <UserApiKeysListController customerId={user.customer_id} />
          </>
        )}
    </GeneralPageHolder>
  );
};

User.propTypes = {
  match: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  userStatus: PropTypes.object.isRequired,
  getUsersGroups: PropTypes.func.isRequired,
  getUserApiKeys: PropTypes.func.isRequired,
  apiKeys: PropTypes.array.isRequired,
};

User.defaultPropTypes = {};

export default withView(null)(User);
