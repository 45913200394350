import * as actionTypes from './actionTypes';

export const getSharingSecretRequest = (sharingToken) => ({
  type: actionTypes.GET_SHARING_SECRET_REQUEST,
  payload: {
    sharingToken,
  },
});

export const getSharingSecretSuccess = (sharingToken, sharingSecret) => ({
  type: actionTypes.GET_SHARING_SECRET_SUCCESS,
  payload: {
    sharingToken,
    sharingSecret,
    receivedAt: Date.now(),
  },
});

export const getSharingSecretFailure = (error) => ({
  type: actionTypes.GET_SHARING_SECRET_FAILURE,
  payload: {
    error,
  },
});
