import { connect } from 'react-redux';
import {
  getUserApiKeysFromStore,
  getUserApiKeysStatusesFromStore,
} from '../../shared/reducers';

import { getUserApiKeysRequest } from '../apis/internalAuthApi/actions';
import UserApiKeysList from '../components/UserApiKeysList';

const mapStateToProps = (state) => ({
  apiKeys: getUserApiKeysFromStore(state),
  apiKeysStatuses: getUserApiKeysStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUserApiKeys: (username) => dispatch(getUserApiKeysRequest(username)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserApiKeysList);
