import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const ResetPassword = ({ user, resetUsersPassword, setDialogClose }) => {
  const handleReset = () => {
    resetUsersPassword(user.username);
    setDialogClose();
  };

  return <YesNoButtons yesAction={handleReset} noAction={setDialogClose} />;
};

ResetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  resetUsersPassword: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default ResetPassword;
