import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const Enabler = ({ user, disableUser, enableUser, setDialogClose }) => {
  const handleDisable = () => {
    disableUser(user.username);
    setDialogClose();
  };

  const handleEnable = () => {
    enableUser(user.username);
    setDialogClose();
  };

  return (
    user.enabled === true
      ? <YesNoButtons yesAction={handleDisable} noAction={setDialogClose} />
      : <YesNoButtons yesAction={handleEnable} noAction={setDialogClose} />
  );
};

Enabler.propTypes = {
  user: PropTypes.object.isRequired,
  enableUser: PropTypes.func.isRequired,
  disableUser: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default Enabler;
