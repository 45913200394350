export const COMMON_AGENCY_ACCESS_GROUP = 'common_agency';
export const COMMON_JOBBOARD_ACCESS_GROUP = 'common_jobBoard';

const getAccessGroups = (userData) => {
  const accessGroups = [];

  if ('talroo:customer:isJobboard' in userData
    && userData['talroo:customer:isJobboard'] === 'true'
  ) {
    accessGroups.push(COMMON_JOBBOARD_ACCESS_GROUP);
  }

  if ('talroo:customer:isAgency' in userData
    && userData['talroo:customer:isAgency'] === 'true'
  ) {
    accessGroups.push(COMMON_AGENCY_ACCESS_GROUP);
  }

  return accessGroups;
};

export default getAccessGroups;
