import { connect } from 'react-redux';

import {
  getApiKeyUsagePlansFromStore,
  getUsagePlansFromStore,
  getUsagePlansNextTokenFromStore,
} from '../../shared/reducers';

import {
  addUsagePlanToApiKeyRequest,
  getApiKeyUsagePlansRequest,
  getUsagePlansRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import AddUsagePlanToApiKey from '../components/AddUsagePlanToApiKey';

const mapStateToProps = (state) => ({
  usagePlans: getUsagePlansFromStore(state),
  apiKeyUsagePlans: getApiKeyUsagePlansFromStore(state),
  nextToken: getUsagePlansNextTokenFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsagePlans: (usagePlanId, limit, position, apiKeyId) => dispatch(
    getUsagePlansRequest(usagePlanId, limit, position, apiKeyId),
  ),
  getApiKeyUsagePlans: (apiKeyId) => dispatch(
    getApiKeyUsagePlansRequest(apiKeyId),
  ),
  addUsagePlanToApiKey: (usagePlanId, apiKeyId) => dispatch(
    addUsagePlanToApiKeyRequest(usagePlanId, apiKeyId),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddUsagePlanToApiKey);
