/**
 * Created by jlu on 11/3/2017.
 */
/**
 * Create the store with dynamic reducers
 */

import { applyMiddleware, compose, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
// noinspection ES6CheckImport
import createSagaMiddleware from 'redux-saga';

export default function configureStore(initialReducers, sagas) {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
  ];

  // noinspection JSCheckFunctionSignatures
  const store = createStore(
    initialReducers,
    compose(applyMiddleware(...middlewares), devToolsEnhancer({})),
  );
  sagaMiddleware.run(sagas);
  store.reducers = initialReducers;

  return store;
}
