import { connect } from 'react-redux';

import {
  getAdFromStore,
  getAdSnippetFromStore,
  getAdSpecFromStore,
  getPopMessageFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  getAdUnitRequest,
  getAdSnippetRequest,
  getAdUnitSpecRequest,
  publishAdUnitRequest,
} from '../apis/popManagementApi/actions';

import AdUnit from '../components/AdUnit';

const mapStateToProps = (state) => ({
  ad: getAdFromStore(state),
  adSnippet: getAdSnippetFromStore(state),
  adSpec: getAdSpecFromStore(state),
  message: getPopMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  clearMessage: () => dispatch(clearMessageRequest()),
  getAdUnit: (
    adId,
    customerId,
  ) => dispatch(
    getAdUnitRequest(
      adId,
      customerId,
    ),
  ),
  getAdSnippet: (
    adId,
    cid,
  ) => dispatch(
    getAdSnippetRequest(
      adId,
      cid,
    )
  ),
  getAdUnitSpec: (
    adType
  ) => dispatch(
    getAdUnitSpecRequest(
      adType
    )
  ),
  publishAdUnit: (
    adId,
    customerId,
  ) => dispatch(
    publishAdUnitRequest(
      adId,
      customerId,
    )
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdUnit);
