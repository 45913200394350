import { createMuiTheme } from '@material-ui/core/styles';
import talrooPurpleHeart from './colors/talrooPurpleHeart';
import talrooWildWatermelon from './colors/talrooWildWatermelon';

/**
 * @see https://material-ui.com/customization/palette/#user-preference
 * @type {Theme}
 */
const DefaultDarkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: talrooPurpleHeart,
    secondary: talrooWildWatermelon,
  },
});

export default DefaultDarkTheme;
