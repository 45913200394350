/**
 * Specific values for the PROD environment.
 */
const environment = {
  env: 'prod',
  cluvio_domain: 'https://dashboards.cluvio.com',
  insights_api_domain: 'https://0tphaqutzi.execute-api.us-east-1.amazonaws.com/prod',
  internal_auth_api_domain: 'https://1n3czmeyz3.execute-api.us-east-1.amazonaws.com/prod',
  pop_management_api_domain: 'https://b1ifd7ec3m.execute-api.us-east-1.amazonaws.com/prod',
  aws_project_region: 'us-east-1',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_9ncTJr8ei',
  aws_user_pools_web_client_id: '4lah4s3pp5e0c27plho5dsr5a4',
  removed_groups: [],
  group_map: {
    'insights-prod-api-admin-dashboard': 'Admin',
    'insights-prod-api-base-dashboard': 'Customer Analytics',
    'insights-prod-api-analyst-non-ppc-dashboard': 'Insights Analyst',
    'insights-prod-api-customer-analytics-talroo-apply-dashboard': 'Customer Analytics - Talroo Apply',
    'insights-prod-api-retail-dashboard': 'Insights - Paid - Wholesale',
    'insights-prod-api-silver-dashboard': 'Insights - Paid - Direct',
    'insights-prod-api-premium-dashboard': 'Insights-All',
    'insights-prod-api-analyst-tier-2-dashboard': 'Analyst - Tier 2',
  },
  admin_password: 'Welcome#123',
};

export default environment;
