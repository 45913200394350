import React from 'react';

import getAllRoutes from '../../shared/allRoutes';
import ProtectedRoute from './ProtectedRoute';

const getRoutes = (routes) => (routes.map((route) => (
  <div key={`routes-${route.path}-parent`}>
    <ProtectedRoute route={route} />
    {'routes' in route ? getRoutes(route.routes) : null}
  </div>
)));

const GeneratedRoutes = () => {
  const allRoutes = getAllRoutes();

  return <div key="all-routes">{getRoutes(allRoutes)}</div>;
};

export default GeneratedRoutes;
