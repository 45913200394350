import { all, fork } from 'redux-saga/effects';
import insightsApiSagas from '../../Insights/apis/insightsApi/sagas';
import internalAuthApiSagas
  from '../../UserManagement/apis/internalAuthApi/sagas';
import popApiSagas from '../../PopManagement/apis/popManagementApi/sagas';

function* rootSagas() {
  yield all([
    fork(insightsApiSagas),
    fork(internalAuthApiSagas),
    fork(popApiSagas),
  ]);
}

export default rootSagas;
