import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { RequireNewPassword } from 'aws-amplify-react';
import React from 'react';

import AuthHolder from './AuthHolder';

const styles = (theme) => ({
  card: {
    width: 275,
  },
  textField: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
  authButton: {
    fontWeight: 'bold',
  },
});

/**
 * @see https://aws-amplify.github.io/media/ui_library
 */
class CustomRequireNewPassword extends RequireNewPassword {
  constructor(props) {
    super(props);
    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['requireNewPassword'];
  }

  showComponent() {
    const { classes } = this.props;

    return (
      <AuthHolder>
        <Card className={classes.card}>
          <CardHeader title="Change Password" className={classes.cardHeader}>
          </CardHeader>
          <CardContent>
            <form>
              <TextField
                className={classes.textField}
                id="password"
                key="password"
                name="password"
                label="New Password"
                onChange={this.handleInputChange}
                type="password"
                placeholder="New Password"
              />
            </form>
          </CardContent>
          <CardActions>
            <Button
              fullWidth
              size="large"
              onClick={this.change}
              variant="contained"
              color="secondary"
              className={classes.authButton}
            >
              Change
            </Button>
          </CardActions>
          <CardActions>
            <Typography
              variant="caption"
              align="center"
              display="block"
              gutterBottom
            >
              <Button
                fullWidth
                size="small"
                color="secondary"
                className={classes.margin}
                onClick={() => super.changeState('signIn')}
              >
                Back to Sign In
              </Button>
            </Typography>
          </CardActions>
        </Card>
      </AuthHolder>
    );
  }
}

export default withStyles(styles)(CustomRequireNewPassword);
