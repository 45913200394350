import { Auth } from 'aws-amplify';
import axios from 'axios';

import environment from '../../../configs/environment';

const API_DOMAIN = environment.internal_auth_api_domain;

const api = {

  addRequests: (planId, apiKeyId, remaining) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/usagePlans/${planId}/usage`,
        {
          api_key_id: apiKeyId,
          remaining,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getPlan: (planId, limit, position, apiKeyId) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans/${planId}`, {
          params: {
            limit,
            position,
            api_key_id: apiKeyId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getKeyPlan: (planId, apiKeyId, limit, position, nameQuery) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans/${planId}/keys/${apiKeyId}`, {
          params: {
            limit,
            position,
            name_query: nameQuery,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getPlanKeys: (planId, apiKeyId, limit, position, nameQuery) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans/${planId}/keys`, {
          params: {
            api_key_id: apiKeyId,
            limit,
            position,
            name_query: nameQuery,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getKeyUsagePlan: (planId, apiKeyId, limit, position, startDate, endDate) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans/${planId}/usage`, {
          params: {
            api_key_id: apiKeyId,
            limit,
            position,
            start_date: startDate,
            end_date: endDate,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  updateApiKey: (apiKeyId, name, description, enabled) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/apiKeys/${apiKeyId}`,
        {
          name,
          description,
          enabled,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  removeUsagePlanFromApiKey: (planId, apiKeyId) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.delete(
        `${API_DOMAIN}/usagePlans/${planId}/keys/${apiKeyId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  addUsagePlanToApiKey: (planId, apiKeyId) =>
    Auth.currentSession().then((data) =>
      axios.post(
        `${API_DOMAIN}/usagePlans/${planId}/keys`,
        {
          api_key_id: apiKeyId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getUsagePlans: (planId, limit, position, apiKeyId) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans`, {
          params: {
            plan_id: planId,
            limit,
            position,
            api_key_id: apiKeyId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getUsagePlan: (planId, limit, position, apiKeyId) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/usagePlans/${planId}`, {
          params: {
            limit,
            position,
            api_key_id: apiKeyId,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getUserApiKeys: (nameQuery) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/apiKeys`, {
          params: {
            name_query: nameQuery,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getApiKeys: (apiKeyId, limit, position, nameQuery, includeValue) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/apiKeys`, {
          params: {
            api_key_id: apiKeyId,
            limit,
            position,
            name_query: nameQuery,
            include_value: includeValue,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getApiKey: (apiKeyId) =>
    Auth.currentSession().then((data) =>
      axios.get(
        `${API_DOMAIN}/apiKeys/${apiKeyId}`, {
          params: {
            include_value: true,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  createApiKey: (name, description, enabled, tags) =>
    Auth.currentSession().then((data) =>
      axios.post(
        `${API_DOMAIN}/apiKeys`,
        {
          name,
          description,
          enabled,
          tags,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: data.idToken.jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      })).catch((error) => {
      throw error.response.data;
    }),

  getUser: (username) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/users/${username}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getUsers: (limit, filterAttribute, filterType, filterValue, nextToken) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/users`, {
          params: {
            limit,
            filter_attribute: filterAttribute,
            filter_type: filterType,
            filter_value: filterValue,
            next_token: nextToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  createUser: (
    username,
    name,
    email,
    customerId,
    phoneNumber,
    temporaryPassword,
  ) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.post(
        `${API_DOMAIN}/users`,
        {
          username,
          temporary_password: temporaryPassword,
          attributes: [
            {
              Name: 'name',
              Value: name,
            },
            {
              Name: 'custom:customer_id',
              Value: customerId,
            },
            {
              Name: 'phone_number',
              Value: phoneNumber,
            },
            {
              Name: 'email',
              Value: email,
            },
            {
              Name: 'email_verified',
              Value: 'true',
            },
            {
              Name: 'phone_number_verified',
              Value: 'true',
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  deleteUser: (username) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.delete(
        `${API_DOMAIN}/users/${username}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  // Since this is an Admin Update call we can actually update multiple
  // attributes at a time. This logic can be enhanced to support that.
  updateUser: (username, name, email, customerId) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/users/${username}`,
        {
          attributes: [
            {
              Name: 'name',
              Value: name,
            },
            {
              Name: 'custom:customer_id',
              Value: customerId,
            },
            {
              Name: 'email',
              Value: email,
            },
            {
              Name: 'email_verified',
              Value: 'true',
            },
            {
              Name: 'phone_number_verified',
              Value: 'true',
            },
          ],
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  disableUser: (username) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/users/${username}/disable`, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  enableUser: (username) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/users/${username}/enable`, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getUsersGroups: (username, limit, nextToken) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/users/${username}/groups`, {
          params: {
            limit,
            next_token: nextToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  addUserToGroup: (username, groupName) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.post(
        `${API_DOMAIN}/users/${username}/groups`,
        {
          group: groupName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  removeUserFromGroup: (username, groupName) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.delete(
        `${API_DOMAIN}/users/${username}/groups/${groupName}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getGroup: (groupName) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/groups/${groupName}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getGroups: (limit, nextToken) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/groups`, {
          params: {
            limit,
            next_token: nextToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getGroupUsers: (groupName, limit, nextToken) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/groups/${groupName}/users`, {
          params: {
            limit,
            next_token: nextToken,
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  resetUsersPassword: (username) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.post(
        `${API_DOMAIN}/passwords/${username}/reset`, {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  adminSetUsersPassword: (username, password) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.put(
        `${API_DOMAIN}/adminPasswordReset`,
        {
          username,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => console.log(response)).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

  getUserPoolData: (keyName) =>
    Auth.currentSession().then((data) => {
      const { idToken } = data;
      const { jwtToken } = idToken;
      return axios.get(
        `${API_DOMAIN}/userPools/${keyName}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwtToken,
          },
        },
      ).then((response) => response.data).catch((error) => {
        throw error;
      });
    }).catch((error) => {
      throw error.response.data;
    }),

};

export default api;
