import { connect } from 'react-redux';

import {
  getApiKeyFromStore,
  getApiKeyStatusFromStore,
  getMessageFromStore,
  getMessageStatusesFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  getApiKeyRequest,
  getApiKeyUsagePlansRequest,
} from '../apis/internalAuthApi/actions';

import ApiKey from '../components/ApiKey';

const mapStateToProps = (state) => ({
  apiKey: getApiKeyFromStore(state),
  apiKeyStatus: getApiKeyStatusFromStore(state),
  message: getMessageFromStore(state),
  messageStatus: getMessageStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getApiKey: (apiKeyId) => dispatch(getApiKeyRequest(apiKeyId)),
  getApiKeyUsagePlans: (apiKeyId) => dispatch(
    getApiKeyUsagePlansRequest(apiKeyId),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApiKey);
