import * as PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Form from '@rjsf/material-ui';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import ProgressWheel from '../../Common/components/ProgressWheel';
import withView from '../../Common/hocs/withView';

const CreateAdUnit = (props) => {
  const {
    ad,
    adType,
    adSpec,
    createAdUnit,
    getAdUnitSpec,
    message,
    setSuccessMessage,
    clearMessage,
  } = props;

  useEffect(() => {
    getAdUnitSpec(adType);
  }, [adType]);

  const createAd = ({ formData }) => {
    createAdUnit(formData, adType);
  };

  const cancelCreate = () => {
    window.location.replace('/pop-management/create');
  };

  useEffect(() => {
    setSuccessMessage(message);
    if (message !== undefined) {
      if (message.includes('Successfully Created')) {
        const adId = ad.general.ad_id;
        const customerId = ad.general.customer_id;
        window.location.replace(`/pop-management/ad/${customerId}/${adId}`);
        clearMessage();
      }
    }
  }, [message, ad]);

  return (
    Object.keys(adSpec).length === 0 ?
      <ProgressWheel />
      :
      <GeneralPageHolder>
        <Breadcrumbs path={window.location.pathname} />
        <br />
        <Form schema={adSpec} onSubmit={createAd}>
          <div>
            <Button variant="contained" color="primary" type="submit">Create</Button>
            <Button variant="outlined" color="secondary" onClick={cancelCreate}>Cancel</Button>
          </div>
        </Form>
        <br />
      </GeneralPageHolder>
  );
};

CreateAdUnit.propTypes = {
  ad: PropTypes.object.isRequired,
  adSpec: PropTypes.object.isRequired,
  adType: PropTypes.string.isRequired,
  createAdUnit: PropTypes.func.isRequired,
  getAdUnitSpec: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  clearMessage: PropTypes.func.isRequired,
};

export default withView(null)(CreateAdUnit);
