import * as PropTypes from 'prop-types';
import React from 'react';

import environment from '../../configs/environment';
import YesNoButtons from './common/YesNoButtons';

const AdminSetPassword = ({
  user,
  adminSetUsersPassword,
  setDialogClose,
}) => {
  const handleReset = () => {
    adminSetUsersPassword(user.username, environment.admin_password);
    setDialogClose();
  };

  return <YesNoButtons yesAction={handleReset} noAction={setDialogClose} />;
};

AdminSetPassword.propTypes = {
  user: PropTypes.object.isRequired,
  adminSetUsersPassword: PropTypes.func.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default AdminSetPassword;
