import { connect } from 'react-redux';

import { getMessageFromStore, getUserFromStore } from '../../shared/reducers';

import {
  clearMessageRequest,
  createUserRequest,
} from '../apis/internalAuthApi/actions';

import AddUser from '../components/AddUser';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
  message: getMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  createUser: (
    username,
    name,
    email,
    customerId,
    phoneNumber,
    temporaryPassword,
  ) => dispatch(
    createUserRequest(
      username,
      name,
      email,
      customerId,
      phoneNumber,
      temporaryPassword,
    ),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddUser);
