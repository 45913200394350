import * as actionTypes from './actionTypes';

export const clearMessageRequest = () => ({
  type: actionTypes.POP_CLEAR_MESSAGE_REQUEST,
  payload: {},
});

export const clearAdRequest = () => ({
  type: actionTypes.POP_CLEAR_AD_REQUEST,
  payload: {},
});

export const getAdsRequest = (customerId) => ({
  type: actionTypes.POP_GET_ADS_REQUEST,
  payload: {
    customerId,
  },
});

export const getAdsSuccess = (ads, message) => ({
  type: actionTypes.POP_GET_ADS_SUCCESS,
  payload: {
    ads,
    message,
  },
});

export const getAdsFailure = (error) => ({
  type: actionTypes.POP_GET_ADS_FAILURE,
  payload: {
    error,
  },
});

export const getAdUnitSpecRequest = (adType) => ({
  type: actionTypes.POP_GET_AD_UNIT_SPEC_REQUEST,
  payload: {
    adType,
  },
});

export const getAdUnitSpecSuccess = (spec, message) => ({
  type: actionTypes.POP_GET_AD_UNIT_SPEC_SUCCESS,
  payload: {
    spec,
    message,
  },
});

export const getAdUnitSpecFailure = (error) => ({
  type: actionTypes.POP_GET_AD_UNIT_SPEC_FAILURE,
  payload: {
    error,
  },
});

export const createAdUnitRequest = (formData, adType) => ({
  type: actionTypes.POP_CREATE_AD_UNIT_REQUEST,
  payload: {
    formData,
    adType,
  },
});

export const createAdUnitSuccess = (ad, message) => ({
  type: actionTypes.POP_CREATE_AD_UNIT_SUCCESS,
  payload: {
    ad,
    message,
  },
});

export const createAdUnitFailure = (error) => ({
  type: actionTypes.POP_CREATE_AD_UNIT_FAILURE,
  payload: {
    error,
  },
});

export const getAdUnitRequest = (adId, customerId) => ({
  type: actionTypes.POP_GET_AD_UNIT_REQUEST,
  payload: {
    adId,
    customerId,
  },
});

export const getAdUnitSuccess = (ad, message) => ({
  type: actionTypes.POP_GET_AD_UNIT_SUCCESS,
  payload: {
    ad,
    message,
  },
});

export const getAdUnitFailure = (error) => ({
  type: actionTypes.POP_GET_AD_UNIT_FAILURE,
  payload: {
    error,
  },
});

export const updateAdUnitRequest = (adId, formData) => ({
  type: actionTypes.POP_UPDATE_AD_UNIT_REQUEST,
  payload: {
    adId,
    formData,
  },
});

export const updateAdUnitSuccess = (ad, message) => ({
  type: actionTypes.POP_UPDATE_AD_UNIT_SUCCESS,
  payload: {
    ad,
    message,
  },
});

export const updateAdUnitFailure = (error) => ({
  type: actionTypes.POP_UPDATE_AD_UNIT_FAILURE,
  payload: {
    error,
  },
});

export const publishAdUnitRequest = (adId, customerId) => ({
  type: actionTypes.POP_PUBLISH_AD_UNIT_REQUEST,
  payload: {
    adId,
    customerId,
  },
});

export const publishAdUnitSuccess = (message) => ({
  type: actionTypes.POP_PUBLISH_AD_UNIT_SUCCESS,
  payload: {
    message,
  },
});

export const publishAdUnitFailure = (error) => ({
  type: actionTypes.POP_PUBLISH_AD_UNIT_FAILURE,
  payload: {
    error,
  },
});

export const getAdSnippetRequest = (adId, cid) => ({
  type: actionTypes.POP_GET_AD_SNIPPET_REQUEST,
  payload: {
    adId,
    cid,
  },
});

export const getAdSnippetSuccess = (adSnippet, message) => ({
  type: actionTypes.POP_GET_AD_SNIPPET_SUCCESS,
  payload: {
    adSnippet,
    message,
  },
});

export const getAdSnippetFailure = (error) => ({
  type: actionTypes.POP_GET_AD_SNIPPET_FAILURE,
  payload: {
    error,
  },
});
