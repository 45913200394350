import * as insightsRoutes from '../Insights/routes';
import * as userManagementRoutes from '../UserManagement/routes';
import * as popManagementRoutes from '../PopManagement/routes';

const getAllSideMenuRoutes = () => [].concat(
  insightsRoutes.default,
  userManagementRoutes.default,
  popManagementRoutes.default,
);

export default getAllSideMenuRoutes;
