import MaterialLink from '@material-ui/core/Link';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import { formatDateTime } from '../../Common/utils';
import CommonTable from './common/CommonTable';

const ApiKeysList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { apiKeys, apiKeysPosition, getApiKeys } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(apiKeysPosition in paginationTokens) && apiKeysPosition) {
      setPaginationTokens(paginationTokens.concat(apiKeysPosition));
    }
  }, [apiKeysPosition]);

  useEffect(() => {
    if (apiKeys.length !== 0) {
      setRows(buildRows(apiKeys));
    } else {
      setRows(buildRows([]));
    }
  }, [apiKeys]);

  const paginationCallback = (limit, page) => {
    getApiKeys(null, limit, paginationTokens[page], null, null);
  };

  const tableHeaders = [
    'ID',
    'Name',
    'Description',
    'Enabled',
    'Created',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        name,
        description,
        created_date: creationDate,
        enabled,
        id,
      } = rawData[key];
      newObject[id] = [
        <MaterialLink
          component={Link}
          to={`/user-management/api-keys/${id}`}
          color="primary"
        >
          {id}
        </MaterialLink>,
        name,
        description,
        enabled ? 'Yes' : 'No',
        formatDateTime(creationDate),
      ];
    }

    return newObject;
  };

  return (
    <GeneralPageHolder>
      <Breadcrumbs path={window.location.pathname} />
      <br />
      <CommonTable
        columnHeaders={tableHeaders}
        rows={rows}
        paginationTokens={paginationTokens}
        paginationCallback={paginationCallback}
      />
    </GeneralPageHolder>
  );
};

ApiKeysList.propTypes = {
  apiKeys: PropTypes.array.isRequired,
  getApiKeys: PropTypes.func.isRequired,
  apiKeysPosition: PropTypes.string,
};

ApiKeysList.defaultProps = {
  apiKeysPosition: '',
};

export default ApiKeysList;
