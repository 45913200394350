import { combineReducers } from 'redux';

import view from '../../Common/hocs/withView/reducer';
import settings, * as fromSettings from '../../Common/reducers/settings';
import sideMenu, * as fromSideMenu from '../../Common/reducers/sideMenu';
import insightsApi, * as fromInsightsApi
  from '../../Insights/apis/insightsApi/reducer';
import userManagement, * as fromUserManagement
  from '../../UserManagement/apis/internalAuthApi/reducer';
import popManagement, * as fromPopManagement
  from '../../PopManagement/apis/popManagementApi/reducer';

export default combineReducers({
  settings,
  sideMenu,
  insightsApi,
  userManagement,
  popManagement,
  view,
});

export const getPaletteType = (state) => (
  fromSettings.getPaletteType(state.settings)
);

export const getSideMenuState = (state) => (
  fromSideMenu.getSideMenuState(state.sideMenu)
);

export const getSideMenuPanels = (state) => (
  fromSideMenu.getSideMenuPanels(state.sideMenu)
);

export const getUserFromStore = (state) => (
  fromUserManagement.getUserFromStore(state.userManagement)
);

export const getUserStatusesFromStore = (state) => (
  fromUserManagement.getUserStatusesFromStore(state.userManagement)
);

export const getGroupsFromStore = (state) => (
  fromUserManagement.getGroupsFromStore(state.userManagement)
);

export const getGroupsNextTokenFromStore = (state) => (
  fromUserManagement.getGroupsNextTokenFromStore(state.userManagement)
);

export const getGroupsStatusesFromStore = (state) => (
  fromUserManagement.getGroupsStatusesFromStore(state.userManagement)
);

export const getUserGroupsFromStore = (state) => (
  fromUserManagement.getUserGroupsFromStore(state.userManagement)
);

export const getUserGroupsNextTokenFromStore = (state) => (
  fromUserManagement.getUserGroupsNextTokenFromStore(state.userManagement)
);

export const getUserGroupsStatusesFromStore = (state) => (
  fromUserManagement.getUserGroupsStatusesFromStore(state.userManagement)
);

export const getGroupFromStore = (state) => (
  fromUserManagement.getGroupFromStore(state.userManagement)
);

export const getGroupStatusesFromStore = (state) => (
  fromUserManagement.getGroupStatusesFromStore(state.userManagement)
);

export const getUsersFromStore = (state) => (
  fromUserManagement.getUsersFromStore(state.userManagement)
);

export const getUsersNextTokenFromStore = (state) => (
  fromUserManagement.getUsersNextTokenFromStore(state.userManagement)
);

export const getUsersStatusesFromStore = (state) => (
  fromUserManagement.getUsersStatusesFromStore(state.userManagement)
);

export const getUserPoolFromStore = (state) => (
  fromUserManagement.getUserPoolFromStore(state.userManagement)
);

export const getUserPoolStatusesFromStore = (state) => (
  fromUserManagement.getUserPoolStatusesFromStore(state.userManagement)
);

export const getMessageFromStore = (state) => (
  fromUserManagement.getMessageFromStore(state.userManagement)
);

export const getMessageStatusesFromStore = (state) => (
  fromUserManagement.getMessageStatusesFromStore(state.userManagement)
);

export const getSharingSecretFromStore = (state, sharingToken) => (
  fromInsightsApi.getSharingSecretFromStore(state.insightsApi, sharingToken)
);

export const getSharingSecretStatusesFromStore = (state, sharingToken) => (
  fromInsightsApi.getSharingSecretStatusesFromStore(state.insightsApi,
    sharingToken)
);

export const getApiKeysFromStore = (state) => (
  fromUserManagement.getApiKeysFromStore(state.userManagement)
);

export const getApiKeysStatusesFromStore = (state) => (
  fromUserManagement.getApiKeysStatusesFromStore(state.userManagement)
);

export const getApiKeysNextTokenFromStore = (state) => (
  fromUserManagement.getApiKeysNextTokenFromStore(state.userManagement)
);

export const getApiKeyFromStore = (state) => (
  fromUserManagement.getApiKeyFromStore(state.userManagement)
);

export const getApiKeyStatusFromStore = (state) => (
  fromUserManagement.getApiKeyStatusFromStore(state.userManagement)
);

export const getNewApiKeyFromStore = (state) => (
  fromUserManagement.getNewApiKeyFromStore(state.userManagement)
);

export const getNewApiKeyStatusFromStore = (state) => (
  fromUserManagement.getNewApiKeyStatusFromStore(state.userManagement)
);

export const getUserApiKeysFromStore = (state) => (
  fromUserManagement.getUserApiKeysFromStore(state.userManagement)
);

export const getUserApiKeysStatusesFromStore = (state) => (
  fromUserManagement.getUserApiKeysStatusesFromStore(state.userManagement)
);

export const getUsagePlansFromStore = (state) => (
  fromUserManagement.getUsagePlansFromStore(state.userManagement)
);

export const getUsagePlansNextTokenFromStore = (state) => (
  fromUserManagement.getUsagePlansNextTokenFromStore(state.userManagement)
);

export const getUsagePlansStatusFromStore = (state) => (
  fromUserManagement.getUsagePlansStatusFromStore(state.userManagement)
);

export const getApiKeyUsagePlansFromStore = (state) => (
  fromUserManagement.getApiKeyUsagePlansFromStore(state.userManagement)
);

export const getApiKeyUsagePlansStatusesFromStore = (state) => (
  fromUserManagement.getApiKeyUsagePlansStatusesFromStore(state.userManagement)
);

export const getApiKeysPositionFromStore = (state) => (
  fromUserManagement.getApiKeysPositionFromStore(state.userManagement)
);

export const getUsageDataFromStore = (state) => (
  fromUserManagement.getUsageDataFromStore(state.userManagement)
);

export const getMonthlyUsageDataFromStore = (state) => (
  fromUserManagement.getMonthlyUsageDataFromStore(state.userManagement)
);

export const getUsagePlanFromStore = (state) => (
  fromUserManagement.getUsagePlanFromStore(state.userManagement)
);

export const getUsagePlanApiKeysFromStore = (state) => (
  fromUserManagement.getUsagePlanApiKeysFromStore(state.userManagement)
);

export const getUsagePlanApiKeysStatusesFromStore = (state) => (
  fromUserManagement.getUsagePlanApiKeysStatusesFromStore(state.userManagement)
);

export const getDialogStatusFromStore = (state) => (
  fromUserManagement.getDialogStatusFromStore(state.userManagement)
);

export const getAdsFromStore = (state) => (
  fromPopManagement.getAdsFromStore(state.popManagement)
);

export const getAdSpecFromStore = (state) => (
  fromPopManagement.getAdSpecFromStore(state.popManagement)
);

export const getAdFromStore = (state) => (
  fromPopManagement.getAdFromStore(state.popManagement)
);

export const getPopMessageFromStore = (state) => (
  fromPopManagement.getPopMessageFromStore(state.popManagement)
);

export const getAdSnippetFromStore = (state) => (
  fromPopManagement.getAdSnippetFromStore(state.popManagement)
);
