import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import * as PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    margin: theme.spacing(1),
  },
}));

const YesNoButtons = ({ yesAction, noAction }) => {
  const classes = useStyles();
  return (
    <div>
      <Button
        startIcon={<CheckIcon />}
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => yesAction()}
      >
        Yes
      </Button>
      <Button
        startIcon={<CloseIcon />}
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={() => noAction()}
      >
        No
      </Button>
    </div>
  );
};

YesNoButtons.propTypes = {
  yesAction: PropTypes.func.isRequired,
  noAction: PropTypes.func.isRequired,
};

export default YesNoButtons;
