import { connect } from 'react-redux';

import {
  getNewApiKeyFromStore,
  getNewApiKeyStatusFromStore,
} from '../../shared/reducers';

import {
  createApiKeyRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import CreateApiKey from '../components/CreateApiKey';

const mapStateToProps = (state) => ({
  newKeyID: getNewApiKeyFromStore(state),
  newKeyStatus: getNewApiKeyStatusFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  createApiKey: (keyName, description, enabled, tags) => dispatch(
    createApiKeyRequest(keyName, description, enabled, tags),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateApiKey);
