import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import NavLink from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import RemoveIcon from '@material-ui/icons/Remove';
import * as PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import getAllSideMenuRoutes from '../../shared/allSideMenuRoutes';
import DarkModeToggle from '../containers/DarkModeToggle';
import Copyright from './Copyright';

const styles = (theme) => ({
  list: {
    width: 250,
  },
  icons: {
    marginRight: theme.spacing(2),
  },
  expandedPanelList: {
    width: '100%',
    padding: 0,
  },
  gutters: {
    paddingLeft: theme.spacing(3),
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: 'center',
    position: 'relative',
    bottom: 0,
    width: '100%',
    height: 50,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  linkSubHeader: {
    fontSize: theme.typography.caption.fontSize,
    textDecoration: 'none',
    color: theme.palette.text.secondary,
  },
});

const ExpansionPanel = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

const ExpansionPanelSummary = withStyles((theme) => ({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  expanded: {},
}))(AccordionSummary);

const ExpansionPanelDetails = withStyles(() => ({
  root: {
    padding: 0,
  },
}))(AccordionDetails);

const GetExpansionPanel = (props) => {
  const {
    route,
    access,
    classes,
    panelLevel,
    handleChange,
    sideMenuPanels,
    onClick,
    onKeyDown,
  } = props;

  let component = null;

  if (access.some((r) => route.extras.access.includes(r))) {
    if ('routes' in route) {
      // Build the nested Expansion Panels.
      const nestedComponents = [];

      route.routes.forEach((nestedRoute, index) => {
        const newPanelLevel = `${panelLevel}-sub${index}`;
        nestedComponents.push(<GetExpansionPanel
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          key={newPanelLevel}
          panelLevel={newPanelLevel}
          route={nestedRoute}
        />);
      });

      const isOpen = (panelLevel in sideMenuPanels
        && sideMenuPanels[panelLevel]);

      component = (
        <ExpansionPanel
          square
          expanded={isOpen}
          onChange={() => handleChange(panelLevel, !isOpen)}
        >
          <ExpansionPanelSummary
            aria-controls={`${panelLevel}-content`}
            id={`${panelLevel}-header`}
          >
            {isOpen
              ? <RemoveIcon className={classes.icons} />
              : <AddIcon className={classes.icons} />}
            <NavLink component={Link} to={route.path} className={classes.link}>
              <Typography>{route.pageTitle}</Typography>
            </NavLink>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <List className={classes.expandedPanelList}>
              {nestedComponents}
            </List>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      );
    } else if (route.type === 'header') {
      component = (
        <ListSubheader component="div" disableSticky>
          <NavLink
            className={classes.linkSubHeader}
            component={Link}
            to={route.path}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            {route.pageTitle}
          </NavLink>
        </ListSubheader>
      );
    } else if (route.type === 'hidden') {
      component = null;
    } else {
      // eslint-disable-next-line react/jsx-props-no-spreading
      component = <NavListItem {...props} route={route} />;
    }
  }

  return component;
};

GetExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  access: PropTypes.array.isRequired,
  route: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  panelLevel: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  sideMenuPanels: PropTypes.object.isRequired,
};

const NavListItem = ({
  route,
  classes,
  onClick,
  onKeyDown,
  panelLevel,
}) => (
  <ListItem
    button
    key={`${panelLevel}-${route.pageTitle}`}
    component={Link}
    to={route.path}
    className={classes.gutters}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    <ListItemIcon>{route.icon}</ListItemIcon>
    <ListItemText primary={route.pageTitle} />
  </ListItem>
);

NavListItem.propTypes = {
  classes: PropTypes.object.isRequired,
  route: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  panelLevel: PropTypes.string.isRequired,
};

const SideMenu = (props) => {
  const {
    classes,
    updateSideMenuState,
    updateSideMenuPanels,
    sideMenuState,
    access,
  } = props;

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    updateSideMenuState(open);
  };

  const NavTest = [];

  const allRoutes = getAllSideMenuRoutes();

  allRoutes.forEach((route, index) => {
    NavTest.push(
      <GetExpansionPanel
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        access={access}
        urlPrefix=""
        panelLevel={`panel${index}`}
        key={`panel${route.pageTitle}`}
        route={route}
        handleChange={updateSideMenuPanels}
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      />,
    );
  });

  return (
    <>
      <IconButton
        onClick={toggleDrawer(true)}
        edge="start"
        color="inherit"
        aria-label="Menu"
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        open={sideMenuState}
        onClose={toggleDrawer(false)}
      >
        <div className={classes.list}>
          {NavTest}
        </div>
        <div className={classes.footer}>
          <div>
            <DarkModeToggle />
          </div>
          <NavLink
            className={classes.linkSubHeader}
            href="https://www.talroo.com/terms/"
            target="_blank"
            color="primary"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            Terms
          </NavLink>
          &nbsp;·&nbsp;
          <NavLink
            className={classes.linkSubHeader}
            href="https://www.talroo.com/privacy/"
            target="_blank"
            color="primary"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            Privacy
          </NavLink>
          <br />
          <Copyright />
        </div>
      </Drawer>
    </>
  );
};

SideMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  updateSideMenuState: PropTypes.func.isRequired,
  updateSideMenuPanels: PropTypes.func.isRequired,
  sideMenuState: PropTypes.bool.isRequired,
  sideMenuPanels: PropTypes.object.isRequired,
  access: PropTypes.array.isRequired,
};

export default withTheme(withStyles(styles)(SideMenu));
