import * as actionTypes from '../../apis/views/actionTypes';

const initialState = {
  successMessage: '',
  errorMessage: '',
};

const viewReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case actionTypes.SET_SUCCESS_MESSAGE:
      return { ...state, successMessage: action.message };
    case actionTypes.CLEAR_SUCCESS_MESSAGE:
      return { ...state, successMessage: '' };
    case actionTypes.SET_ERROR_MESSAGE:
      return { ...state, errorMessage: action.message };
    case actionTypes.CLEAR_ERROR_MESSAGE:
      return { ...state, errorMessage: '' };
    default:
      return state;
  }
};

export default viewReducer;
