import { connect } from 'react-redux';
import { getSideMenuPanels, getSideMenuState } from '../../shared/reducers';

import { updateSideMenuPanels, updateSideMenuState } from '../actions';
import SideMenu from '../components/SideMenu';
import withAuth from '../hocs/withAuth';

const mapStateToProps = (state) => ({
  sideMenuState: getSideMenuState(state),
  sideMenuPanels: getSideMenuPanels(state),
});

const mapDispatchToProps = (dispatch) => ({
  updateSideMenuState: (inputData) => dispatch(updateSideMenuState(inputData)),
  updateSideMenuPanels: (panelName, panelOpen) => dispatch(
    updateSideMenuPanels(panelName, panelOpen),
  ),
});

export default withAuth(connect(
  mapStateToProps,
  mapDispatchToProps,
)(SideMenu));
