import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import BarChartIcon from '@material-ui/icons/BarChart';
import CompareIcon from '@material-ui/icons/Compare';
import EventIcon from '@material-ui/icons/Event';
import LanguageIcon from '@material-ui/icons/Language';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import PeopleIcon from '@material-ui/icons/People';
import PieChartIcon from '@material-ui/icons/PieChart';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import WcIcon from '@material-ui/icons/Wc';
import WorkIcon from '@material-ui/icons/Work';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import React from 'react';

import NavOptionsPage from '../Common/components/NavOptionsPage';
import RedirectRoute from '../Common/components/RedirectRoute';
import variant from '../configs/variant';
import {
  INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
  INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
  INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
  INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
  INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY,
  INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
  INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
  INSIGHTS_RETAIL_ACCESS_GROUP,
  INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
} from './accessGroups';
import CluvioDashboardController from './controllers/CluvioDashboardController';

const routes = [
  {
    pageTitle: 'Insights',
    path: '/insights',
    icon: null,
    exact: true,
    main: NavOptionsPage,
    extras: {
      access: [
        INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
        INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
        INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
        INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
        INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
        INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
        INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
        INSIGHTS_RETAIL_ACCESS_GROUP,
        INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY,
      ],
    },
    routes: [
      {
        pageTitle: 'Customer Analytics',
        path: '/insights/customer-insights',
        icon: <MultilineChartIcon />,
        exact: true,
        main: NavOptionsPage,
        extras: {
          access: [
            INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_RETAIL_ACCESS_GROUP,
            INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
            INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY,
          ],
          data: [
            'Account Summary',
            'Campaign Summary',
            'Job Summary',
            'Event Summary',
          ],
        },
        routes: [
          {
            pageTitle: 'Account Summary',
            path: '/insights/customer-insights/account-summary',
            icon: <MultilineChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/account-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
              data: [
                'Click Metrics',
                'Spend Metrics',
                'Conversion Metrics',
                'Date Breakdown',
                'Campaign Breakdown',
                'Group Breakdown',
              ],
            },
          },
          {
            pageTitle: 'Campaign Summary',
            path: '/insights/customer-insights/campaign-summary',
            icon: <BarChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/campaign-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
              data: [
                'Click Metrics',
                'Spend Metrics',
                'Conversion Metrics',
                'Date Breakdown',
                'Audience Breakdown',
                'Day/Time Breakdown',
                'Channel Breakdown',
                'Location Breakdown',
              ],
            },
          },
          {
            type: 'hidden',
            pageTitle: 'Campaign Details',
            path: '/insights/customer-insights/campaign-summary/campaign-details',
            icon: <BarChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/campaign-summary/campaign-details']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Job Summary',
            path: '/insights/customer-insights/job-summary',
            icon: <PieChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/job-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
              data: [
                'Click Metrics',
                'Position Metrics',
                'Spend Metrics',
                'Conversion Metrics',
              ],
            },
          },
          {
            type: 'hidden',
            pageTitle: 'Job Details',
            path: '/insights/customer-insights/job-summary/job-details',
            icon: <PieChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/job-summary/job-details']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Event Summary',
            path: '/insights/customer-insights/event-summary',
            icon: <EventIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/event-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
              data: [
                'Click Metrics',
                'Spend Metrics',
                'RSVP Metrics',
                'Lead List',
                'Audience Breakdown',
                'Day/Time Breakdown',
                'Channel Breakdown',
                'Location Breakdown',
              ],
            },
          },
          {
            type: 'hidden',
            pageTitle: 'Event Details',
            path: '/insights/customer-insights/event-summary/event-details',
            icon: <EventIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/event-summary/event-details']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Applicant Summary',
            path: '/insights/customer-insights/applicant-summary',
            icon: <AssignmentIndIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/applicant-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP,
              ],
              data: [
                'Name',
                'Email',
                'Phone',
                'Resume Download',
              ],
            },
          },
          {
            pageTitle: 'Ad Unit Summary',
            path: '/insights/customer-insights/ad-unit-summary',
            icon: <BarChartIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['customer-insights/ad-unit-summary']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY,
              ],
              data: [],
            },
          },
        ],
      },
      {
        pageTitle: 'Talent Supply Insights',
        path: '/insights/talent-supply-insights',
        icon: <MultilineChartIcon />,
        exact: true,
        main: NavOptionsPage,
        extras: {
          access: [
            INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_RETAIL_ACCESS_GROUP,
            INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
          ],
        },
        routes: [
          {
            pageTitle: 'Market Breakdown',
            type: 'header',
            path: '/insights/talent-supply-insights/market-breakdown',
            icon: <LocationCityIcon />,
            exact: true,
            main: () => (<RedirectRoute
              pathname="/insights/talent-supply-insights"
              hash="#market-breakdown"
            />),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Audience Analysis',
                'Market Expansion',
                'Demographic Overview',
                'Supply Trends',
                'Data Explorer',
              ],
            },
          },
          {
            pageTitle: 'Audience Analysis',
            path: '/insights/talent-supply-insights/market-breakdown/audience-analysis',
            icon: <PeopleIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/market-breakdown/audience-analysis']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Top Searches',
                'Top Companies',
                'Top Job Titles',
                'Top Industries',
              ],
            },
          },
          {
            pageTitle: 'Market Expansion',
            path: '/insights/talent-supply-insights/market-breakdown/market-expansion',
            icon: <ZoomOutMapIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/market-breakdown/market-expansion']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Job Seeker Location',
                'City Rankings',
                'Market Population',
                'Potential Markets',
              ],
            },
          },
          {
            pageTitle: 'Demographic Overview',
            path: '/insights/talent-supply-insights/market-breakdown/demographic-overview',
            icon: <WcIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/market-breakdown/demographic-overview']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Demographics',
                'Education',
                'Gender',
                'Age',
              ],
            },
          },
          {
            pageTitle: 'Supply Trends',
            path: '/insights/talent-supply-insights/market-breakdown/supply-trends',
            icon: <TrendingUpIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/market-breakdown/supply-trends']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Searches by Day',
                'Clicks by Day',
                'Searches by Industries',
                'Clicks by Industries',
              ],
            },
          },
          {
            pageTitle: 'Data Explorer',
            path: '/insights/talent-supply-insights/market-breakdown/data-explorer',
            icon: <LanguageIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/market-breakdown/data-explorer']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
              ],
              data: [
                'Audience Analysis',
                'Market Expansion',
                'Demographic Overview',
                'Supply Trends',
              ],
            },
          },
          {
            pageTitle: 'Industry Breakdown',
            type: 'header',
            path: '/insights/talent-supply-insights/industry-breakdown',
            icon: <WorkIcon />,
            exact: true,
            main: () => (<RedirectRoute
              pathname="/insights/talent-supply-insights"
              hash="#industry-breakdown"
            />),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
            },
          },
          {
            pageTitle: 'Audience Analysis',
            path: '/insights/talent-supply-insights/industry-breakdown/audience-analysis',
            icon: <PeopleIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/industry-breakdown/audience-analysis']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Job Seeker Activity',
                'Audience Breakdown',
                'Device Breakdown',
                'Job Seeker Location',
              ],
            },
          },
          {
            pageTitle: 'Market Expansion',
            path: '/insights/talent-supply-insights/industry-breakdown/market-expansion',
            icon: <ZoomOutMapIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/industry-breakdown/market-expansion']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Job Seeker Location',
                'City Rankings',
                'Market Population',
                'Potential Markets',
              ],
            },
          },
          {
            pageTitle: 'Supply Trends',
            path: '/insights/talent-supply-insights/industry-breakdown/supply-trends',
            icon: <TrendingUpIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/industry-breakdown/supply-trends']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Clicks by Day',
                'Clicks by Category',
                'Searches by Day',
                'Searches by Category',
              ],
            },
          },
          {
            pageTitle: 'Data Explorer',
            path: '/insights/talent-supply-insights/industry-breakdown/data-explorer',
            icon: <LanguageIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['talent-supply-insights/industry-breakdown/data-explorer']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_RETAIL_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Audience Analysis',
                'Market Expansion',
                'Supply Trends',
              ],
            },
          },
        ],
      },
      {
        pageTitle: 'Competitive Insights',
        path: '/insights/competitive-insights',
        icon: <MultilineChartIcon />,
        exact: true,
        main: NavOptionsPage,
        extras: {
          access: [
            INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
            INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
          ],
          data: [
            'Bidding Intelligence',
            'Competitive Landscape',
          ],
        },
        routes: [
          {
            pageTitle: 'Competitive Landscape',
            path: '/insights/competitive-insights/competitive-landscape',
            icon: <CompareIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['competitive-insights/competitive-landscape']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Conversion Metrics (Systemwide)',
                'Conversion Metrics (Customer)',
                'Job Title Analysis',
                'Top Companies',
              ],
            },
          },
          {
            pageTitle: 'Bidding Intelligence',
            path: '/insights/competitive-insights/bidding-intelligence',
            icon: <MonetizationOnIcon />,
            exact: true,
            main: () => (
              <CluvioDashboardController
                dashboardDetails={variant['cluvio-dashboards']['competitive-insights/bidding-intelligence']}
              />
            ),
            extras: {
              access: [
                INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP,
                INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP,
              ],
              data: [
                'Bid Metrics (Systemwide)',
                'Conversion Metrics (Customer)',
                'Price Suggestions',
                'Ad Position Click Distribution',
              ],
            },
          },
        ],
      },
    ],
  },
];

export default routes;
