import { connect } from 'react-redux';

import {
  addRequestsRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import AddApiCalls from '../components/AddApiCalls';

const mapDispatchToProps = (dispatch) => ({
  addRequests: (planId, apiKeyId, remaining) => dispatch(
    addRequestsRequest(planId, apiKeyId, remaining),
  ),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  null,
  mapDispatchToProps,
)(AddApiCalls);
