import AppBar from '@material-ui/core/AppBar';
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import AccountMenu from './Common/components/AccountMenu';
import GeneratedRoutes from './Common/components/GeneratedRoutes';
import SideMenu from './Common/containers/SideMenu';
import talrooWhiteLogo from './img/talroo-logo-white-registered.svg';
import { getPaletteType } from './shared/reducers';
import DefaultDarkTheme from './themes/DefaultDarkTheme';
import DefaultLightTheme from './themes/DefaultLightTheme';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    flexGrow: 1,
    backgroundImage: `url(${talrooWhiteLogo})`,
    backgroundSize: '160px 30px',
    height: 30,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  sectionDesktop: {
    display: 'flex',
  },
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theme: (props.paletteType === 'light'
        ? DefaultLightTheme
        : DefaultDarkTheme),
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.paletteType !== state.theme.palette.type) {
      return {
        theme: (props.paletteType === 'light'
          ? DefaultLightTheme
          : DefaultDarkTheme),
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  render() {
    const { authState, classes } = this.props;

    if (authState === 'signedIn') {
      const { theme } = this.state;

      return (
        <MuiThemeProvider theme={theme}>
          <Helmet>
            <body className={theme.palette.type} />
          </Helmet>
          <div className="App">
            <BrowserRouter>
              <div className={classes.root}>
                <AppBar
                  position="static"
                  style={{
                    background: `linear-gradient(to bottom right, ${theme.palette.primary.main}, #8b75ff)`,
                  }}
                >
                  <Toolbar>
                    <SideMenu />
                    <span className={classes.title} />
                    <div className={classes.sectionDesktop}>
                      <AccountMenu />
                    </div>
                  </Toolbar>
                </AppBar>
              </div>
              <GeneratedRoutes key="all-routes" />
            </BrowserRouter>
          </div>
        </MuiThemeProvider>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  paletteType: getPaletteType(state),
});

App.propTypes = {
  classes: PropTypes.object.isRequired,
  authState: PropTypes.string.isRequired,
  paletteType: PropTypes.string.isRequired,
};

export default withStyles(styles)(
  connect(mapStateToProps)(App),
);
