import environment from '../configs/environment';

export const INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP = 'insights_base';
export const INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP = 'insights_silver';
export const INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP = 'insights_analyst_non_ppc';
export const INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP = 'insights_customer_analytics_talroo_apply';
export const INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP = 'insights_premier';
export const INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP = 'insights_admin';
export const INSIGHTS_RETAIL_ACCESS_GROUP = 'insights_retail';
export const INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP = 'insights_analyst_tier_2';
export const INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY = 'insights_customer_analytics_ad_unit_summary';

const getAccessGroups = (userData) => {
  const { env } = environment;
  const accessGroups = [];

  if ('cognito:groups' in userData) {
    const groups = userData['cognito:groups'];

    if (groups.includes(`insights-${env}-api-base-dashboard`)) {
      accessGroups.push(INSIGHTS_BASE_DASHBOARD_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-silver-dashboard`)) {
      accessGroups.push(INSIGHTS_SILVER_DASHBOARD_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-analyst-non-ppc-dashboard`)) {
      accessGroups.push(INSIGHTS_ANALYST_NON_PPC_DASHBOARD_ACCESS_GROUP);
    }

    if (groups.includes(
      `insights-${env}-api-customer-analytics-talroo-apply-dashboard`,
    )) {
      accessGroups.push(INSIGHTS_CUSTOMER_ANALYTICS_TALROO_APPLY_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-premium-dashboard`)) {
      accessGroups.push(INSIGHTS_PREMIER_DASHBOARD_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-admin-dashboard`)) {
      accessGroups.push(INSIGHTS_ADMIN_DASHBOARD_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-retail-dashboard`)) {
      accessGroups.push(INSIGHTS_RETAIL_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-analyst-tier-2-dashboard`)) {
      accessGroups.push(INSIGHTS_ANALYST_TIER_2_ACCESS_GROUP);
    }

    if (groups.includes(`insights-${env}-api-pop-analytics-dashboard`)) {
      accessGroups.push(INSIGHTS_CUSTOMER_ANALYTICS_AD_UNIT_SUMMARY);
    }
  }

  return accessGroups;
};

export default getAccessGroups;
