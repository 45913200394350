import { Box } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import * as PropTypes from 'prop-types';
import React from 'react';

import authBackground from '../../img/login-background.svg';
import talrooLogo from '../../img/talroo-registered-tm.svg';

const styles = (theme) => ({
  mainBox: {
    backgroundImage: `url(${authBackground})`,
    minHeight: '100vh',

    flexDirection: 'row',
    boxSizing: 'border-box',

    display: 'grid',
    placeContent: 'center',
    alignItems: 'center',
  },
  innerGrid: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    flexDirection: 'row',
    boxSizing: 'border-box',
    display: 'flex',
    placeContent: 'center',
    alignItems: 'center',
  },
  talrooLogoTop: {
    width: 160,
    height: 65,
  },
  talrooLogoBottom: {
    width: 135,
    height: 50,
    margin: '0 10px',
  },
  companyLogoContainer: {
    marginBottom: 25,
  },
  parentCompanyLogoContainer: {
    marginTop: 25,
  },
});

const AuthHolder = (props) => {
  const { classes, children } = props;

  return (
    <Box className={classes.mainBox}>
      <Container maxWidth="xs" className={classes.companyLogoContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.innerGrid}>
            <img
              className={classes.talrooLogoTop}
              alt="Talroo Company Logo"
              src={talrooLogo}
            />
          </Grid>
        </Grid>
      </Container>
      <Container maxWidth="xs">
        {children}
      </Container>
      <Container maxWidth="xs" className={classes.parentCompanyLogoContainer}>
        <Grid container spacing={3}>
          <Grid item xs={12} className={classes.innerGrid}>
            A&nbsp;
            <img
              className={classes.talrooLogoBottom}
              alt="Talroo Company Logo"
              src={talrooLogo}
            />
            &nbsp;product
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

AuthHolder.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default withStyles(styles)(AuthHolder);
