import { connect } from 'react-redux';
import {
  getMessageFromStore,
  getUserPoolFromStore,
  getUserPoolStatusesFromStore,
  getUsersFromStore,
  getUsersNextTokenFromStore,
  getUsersStatusesFromStore,
  getUserStatusesFromStore,
} from '../../shared/reducers';

import {
  getUserPoolDataRequest,
  getUsersRequest,
} from '../apis/internalAuthApi/actions';

import UsersList from '../components/UsersList';

const mapStateToProps = (state) => ({
  users: getUsersFromStore(state),
  nextToken: getUsersNextTokenFromStore(state),
  usersStatuses: getUsersStatusesFromStore(state),
  numberOfUsers: getUserPoolFromStore(state),
  userPoolStatuses: getUserPoolStatusesFromStore(state),
  userStatus: getUserStatusesFromStore(state),
  message: getMessageFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsers: (
    limit,
    filterAttribute,
    filterType,
    filterValue,
    nextToken,
  ) =>
    dispatch(
      getUsersRequest(
        limit,
        filterAttribute,
        filterType,
        filterValue,
        nextToken,
      ),
    ),
  getNumberOfUsers: () =>
    dispatch(getUserPoolDataRequest('EstimatedNumberOfUsers')),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UsersList);
