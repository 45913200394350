import * as PropTypes from 'prop-types';
import React from 'react';
import YesNoButtons from './common/YesNoButtons';

const RemovePlanFromApiKey = ({
  removeUsagePlanFromApiKey,
  apiKeyId,
  planId,
  setDialogClose,
}) => {
  const handleRemove = () => {
    removeUsagePlanFromApiKey(planId, apiKeyId);
    setDialogClose();
  };

  return <YesNoButtons yesAction={handleRemove} noAction={setDialogClose} />;
};

RemovePlanFromApiKey.propTypes = {
  removeUsagePlanFromApiKey: PropTypes.func.isRequired,
  apiKeyId: PropTypes.string.isRequired,
  planId: PropTypes.string.isRequired,
  setDialogClose: PropTypes.func.isRequired,
};

export default RemovePlanFromApiKey;
