import { connect } from 'react-redux';
import {
  getGroupsStatusesFromStore,
  getUserGroupsFromStore,
  getUserGroupsNextTokenFromStore,
} from '../../shared/reducers';

import {
  getUsersGroupsRequest,
  removeUserFromGroupRequest,
} from '../apis/internalAuthApi/actions';
import UserGroupsList from '../components/UserGroupsList';

const mapStateToProps = (state) => ({
  groups: getUserGroupsFromStore(state),
  nextToken: getUserGroupsNextTokenFromStore(state),
  GroupsStatuses: getGroupsStatusesFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUsersGroups: (username) => dispatch(getUsersGroupsRequest(username)),
  removeUserFromGroup: (username, groupName) => dispatch(
    removeUserFromGroupRequest(username, groupName),
  ),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserGroupsList);
