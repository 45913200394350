import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { SignIn } from 'aws-amplify-react';
import React from 'react';

import AuthHolder from './AuthHolder';

const styles = (theme) => ({
  card: {
    width: 275,
  },
  textField: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
  },
});

/**
 * @see https://aws-amplify.github.io/media/ui_library
 */
class CustomSignUp extends SignIn {
  constructor(props) {
    super(props);

    // eslint-disable-next-line no-underscore-dangle
    this._validAuthStates = ['signUp'];
    this.gotoSignIn = this.gotoSignIn.bind(this);
  }

  gotoSignIn() {
    // to switch the authState to 'signIn'
    this.props.onStateChange('signIn', {});
  }

  showComponent() {
    const { classes } = this.props;

    return (
      <AuthHolder>
        <Card className={classes.card}>
          <CardHeader title="Login" className={classes.cardHeader}>
          </CardHeader>
          <CardActions>
            <Typography
              variant="caption"
              align="center"
              display="block"
              gutterBottom
            >
              <Button
                fullWidth
                size="small"
                color="secondary"
                className={classes.margin}
                onClick={this.gotoSignIn}
              >
                Go to Sign In
              </Button>
            </Typography>
          </CardActions>
          {this.state.loading ? <LinearProgress color="primary" /> : null}
        </Card>
      </AuthHolder>
    );
  }
}

export default withStyles(styles)(CustomSignUp);
