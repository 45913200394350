import { connect } from 'react-redux';
import {
  getGroupsFromStore,
  getMessageFromStore,
  getNewApiKeyFromStore,
  getNewApiKeyStatusFromStore,
  getUserApiKeysFromStore,
  getUserFromStore,
  getUserStatusesFromStore,
} from '../../shared/reducers';

import {
  clearMessageRequest,
  disableUserRequest,
  enableUserRequest,
  getGroupsRequest,
  getUserApiKeysRequest,
  getUserRequest,
  getUsersGroupsRequest,
  removeUserFromGroupRequest,
  resetUsersPasswordRequest,
} from '../apis/internalAuthApi/actions';

import User from '../components/User';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
  user_statuses: getUserStatusesFromStore(state),
  groups: getGroupsFromStore(state),
  message: getMessageFromStore(state),
  userStatus: getUserStatusesFromStore(state),
  newKeyStatus: getNewApiKeyStatusFromStore(state),
  apiKeys: getUserApiKeysFromStore(state),
  newKeyID: getNewApiKeyFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (username) => dispatch(getUserRequest(username)),
  getGroups: (limit, nextToken) => dispatch(getGroupsRequest(limit, nextToken)),
  disableUser: (username) => dispatch(disableUserRequest(username)),
  enableUser: (username) => dispatch(enableUserRequest(username)),
  removeUserFromGroup: (username, groupName) => dispatch(
    removeUserFromGroupRequest(username, groupName),
  ),
  resetUsersPassword: (username) => dispatch(
    resetUsersPasswordRequest(username),
  ),
  clearMessage: () => dispatch(clearMessageRequest()),
  getUserApiKeys: (username) => dispatch(getUserApiKeysRequest(username)),
  getUsersGroups: (username) => dispatch(getUsersGroupsRequest(username)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(User);
