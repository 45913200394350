import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createAdUnitSuccess,
  createAdUnitFailure,
  getAdsSuccess,
  getAdsFailure,
  getAdUnitSuccess,
  getAdUnitFailure,
  getAdUnitSpecSuccess,
  getAdUnitSpecFailure,
  publishAdUnitSuccess,
  publishAdUnitFailure,
  updateAdUnitSuccess,
  updateAdUnitFailure,
  getAdSnippetSuccess,
  getAdSnippetFailure,
} from './actions';

import {
  POP_CREATE_AD_UNIT_REQUEST,
  POP_GET_ADS_REQUEST,
  POP_GET_AD_SNIPPET_REQUEST,
  POP_GET_AD_UNIT_REQUEST,
  POP_GET_AD_UNIT_SPEC_REQUEST,
  POP_PUBLISH_AD_UNIT_REQUEST,
  POP_UPDATE_AD_UNIT_REQUEST,
} from './actionTypes';

import api from './api';

function* popApiSaga() {
  yield takeLatest(POP_CREATE_AD_UNIT_REQUEST, createAdUnitRequest);
  yield takeLatest(POP_GET_ADS_REQUEST, getAdsRequest);
  yield takeLatest(POP_GET_AD_UNIT_REQUEST, getAdUnitRequest);
  yield takeLatest(POP_GET_AD_UNIT_SPEC_REQUEST, getAdUnitSpecRequest);
  yield takeLatest(POP_PUBLISH_AD_UNIT_REQUEST, publishAdUnitRequest);
  yield takeLatest(POP_UPDATE_AD_UNIT_REQUEST, updateAdUnitRequest);
  yield takeLatest(POP_GET_AD_SNIPPET_REQUEST, getAdSnippetRequest);
}

function* getAdsRequest(action) {
  try {
    const response = yield call(
      api.getAds,
      action.payload.customerId,
    );
    const message = `Successfully got ads for customer ${action.payload.customerId}`;
    yield put(getAdsSuccess(response.ads, message));
  } catch (error) {
    const errorMsg = `Failed to get ads: ${error.message}`;
    yield put(getAdsFailure(errorMsg));
  }
}

function* getAdUnitSpecRequest(action) {
  try {
    const response = yield call(
      api.getAdUnitSpec,
      action.payload.adType,
    );
    const message = `Successfully got ad config for ${action.payload.adType}`;
    yield put(getAdUnitSpecSuccess(response, message));
  } catch (error) {
    yield put(getAdUnitSpecFailure(error.message));
  }
}

function* createAdUnitRequest(action) {
  try {
    const response = yield call(
      api.createAdUnit,
      action.payload.formData,
      action.payload.adType,
    );
    const adId = response.general.ad_id;
    const message = `Successfully Created Ad ${adId}`;
    yield put(createAdUnitSuccess(response, message));
  } catch (error) {
    yield put(createAdUnitFailure(error.message));
  }
}

function* getAdUnitRequest(action) {
  try {
    const response = yield call(
      api.getAdUnit,
      action.payload.adId,
      action.payload.customerId,
    );
    const message = `Successfully retrieved ad ${action.payload.adId}`;
    yield put(getAdUnitSuccess(response, message));
  } catch (error) {
    const errMsg = `Failed to retrieve ad: ${JSON.stringify(error.message)}`;
    yield put(getAdUnitFailure(errMsg));
  }
}

function* updateAdUnitRequest(action) {
  try {
    const response = yield call(
      api.updateAdUnit,
      action.payload.adId,
      action.payload.formData,
    );
    const adId = response.general.ad_id;
    const message = `Successfully Updated Ad ${adId}`;
    yield put(updateAdUnitSuccess(response, message));
  } catch (error) {
    yield put(updateAdUnitFailure(error.message));
  }
}

function* publishAdUnitRequest(action) {
  try {
    yield call(
      api.publishAdUnit,
      action.payload.adId,
      action.payload.customerId,
    );
    const message = `Successfully published ad ${action.payload.adId}`;
    yield put(publishAdUnitSuccess(message));
  } catch (error) {
    yield put(publishAdUnitFailure(error.message));
  }
}

function* getAdSnippetRequest(action) {
  try {
    const response = yield call(
      api.getAdSnippet,
      action.payload.adId,
      action.payload.cid,
    );
    const message = `Successfully retrieved ad snippet ${action.payload.adId}`;
    yield put(getAdSnippetSuccess(response, message));
  } catch (error) {
    const errMsg = `Failed to retrieve ad snippet: ${JSON.stringify(error.message)}`;
    yield put(getAdSnippetFailure(errMsg));
  }
}

export default popApiSaga;
