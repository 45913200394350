import { withStyles } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import * as PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  container: {
    flexGrow: 1,
    minHeight: '100vh',
    textAlign: 'left',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(6),
  },
});

const GeneralPageHolder = ({ classes, children }) => (
  <Container maxWidth="md" className={classes.container}>
    {children}
  </Container>
);

GeneralPageHolder.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GeneralPageHolder);
