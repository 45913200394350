import MaterialLink from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import * as PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../Common/components/Breadcrumbs';
import GeneralPageHolder from '../../Common/components/GeneralPageHolder';
import { formatDateTime } from '../../Common/utils';
import environment from '../../configs/environment';
import CommonTable from './common/CommonTable';

const GroupsList = (props) => {
  // NOTE: The empty string in the array is there on purpose to act as the
  // `0` page pagination token.
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rows, setRows] = useState({});
  const { groups, nextToken, getGroups } = props;

  /**
   * Used to update the paginationTokens array.
   */
  useEffect(() => {
    if (!(nextToken in paginationTokens) && nextToken) {
      setPaginationTokens(paginationTokens.concat(nextToken));
    } else {
      setRows(buildRows([]));
    }
  }, [nextToken]);

  useEffect(() => {
    if (groups.length !== 0) {
      setRows(buildRows(groups));
    }
  }, [groups]);

  const paginationCallback = (limit, page) => {
    getGroups(limit, paginationTokens[page]);
  };

  const tableHeaders = [
    'Group Name',
    'Description',
    'Created',
  ];

  const buildRows = (rawData) => {
    const newObject = {};
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in rawData) {
      const {
        name,
        description,
        creation_date: creationDate,
      } = rawData[key];
      newObject[name] = [
        <Tooltip
          title={name}
          aria-label={name}
          placement="top"
        >
          <MaterialLink
            component={Link}
            to={`/user-management/groups/${name}`}
            color="primary"
          >
            {name in environment.group_map
              ? environment.group_map[name]
              : name}
          </MaterialLink>
        </Tooltip>,
        description,
        formatDateTime(creationDate),
      ];
    }

    return newObject;
  };

  return (
    <GeneralPageHolder>
      <Breadcrumbs path={window.location.pathname} />
      <br />
      <CommonTable
        columnHeaders={tableHeaders}
        rows={rows}
        paginationTokens={paginationTokens}
        paginationCallback={paginationCallback}
      />
    </GeneralPageHolder>
  );
};

GroupsList.propTypes = {
  groups: PropTypes.array.isRequired,
  nextToken: PropTypes.string,
  getGroups: PropTypes.func.isRequired,
};

GroupsList.defaultProps = {
  nextToken: '',
};

export default GroupsList;
