import { connect } from 'react-redux';

import { getUserFromStore } from '../../shared/reducers';

import {
  disableUserRequest,
  enableUserRequest,
  setDialogCloseRequest,
} from '../apis/internalAuthApi/actions';

import Enabler from '../components/Enabler';

const mapStateToProps = (state) => ({
  user: getUserFromStore(state),
});

const mapDispatchToProps = (dispatch) => ({
  enableUser: (username) => dispatch(enableUserRequest(username)),
  disableUser: (username) => dispatch(disableUserRequest(username)),
  setDialogClose: () => dispatch(setDialogCloseRequest()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Enabler);
